<div *ngIf="(isLoggedIn$ | async) === false">
  <h1>{{componentTitle | translate}}</h1>
  <p>{{'login.header' | translate}}</p>

  <div class="row">
    <div class="col-md-4">
      <ta-login-form></ta-login-form>
    </div>

    <div class="col-md-8">
      <ta-connect-card></ta-connect-card>
    </div>
  </div>

</div>
