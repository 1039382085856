import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TaprofileUtilsService } from '@core/services';
import { Constants } from 'app/app.constants';

@Pipe({
  name: 'userDate',
})
export class UserDatePipe implements PipeTransform {

  result: any;

  constructor(
    private profileService: TaprofileUtilsService,
    private datePipe: DatePipe
  ) { }

  transform(value: any): any {
    this.profileService.userInfo.subscribe(userInfo => {
      const formatGeco = userInfo ? userInfo.config.dateformat : 'DD/MM/YYYY';
      const taProfileFormats = Constants.DATE_FORMAT;
      const formatTaProfile = taProfileFormats.find(item => item.geco === formatGeco).taProfile.valueOf();
      this.result = this.datePipe.transform(value, formatTaProfile);
    });
    return this.result;
  }

}
