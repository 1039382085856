import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '@app/app.constants';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GecoErrorMessage, GecoErrorMessageLogin, GecoResponse } from '@core/models';
import { MessagesUtilsService } from '@core/services';
import { SpinnerUtilsService } from '@core/services/spinner-utils.service';
import { TaprofileUtilsService } from '@core/services/taprofile-utils.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private loggedIn = new BehaviorSubject<boolean>(false);
  private pwdExpired = new BehaviorSubject<boolean>(false);
  private expirationMonths = new BehaviorSubject<number>(null);
  private registrationPage = new BehaviorSubject<boolean>(false);

  constructor(
    private httpClient: HttpClient,
    private profileService: TaprofileUtilsService,
    private jwtHelper: JwtHelperService,
    private spinnerService: SpinnerUtilsService,
    private messageService: MessagesUtilsService,
    private modalService: BsModalService
  ) {
    this.isUserAuthenticated();
  }

  isUserAuthenticated(): boolean {

    const isAuthenticated = !this.jwtHelper.isTokenExpired();

    if (isAuthenticated) {
      this.loggedIn.next(true);
    } else {
      this.logout();
    }
    return isAuthenticated;
  }


  login(username: string, password: string): Observable<GecoResponse> {
    const formData = new FormData();
    formData.append('pUsername_in', username);
    formData.append('pPassword_in', password);

    this.spinnerService.setMessage('spinner.authenticating');

    return this.httpClient.post(Constants.URL_LOGIN, formData).pipe(tap((response: GecoResponse) => {
      if (response.successful) {
        localStorage.setItem(Constants.TOKEN_KEY, response.token);
        this.loggedIn.next(true);
        if (response.error && (<GecoErrorMessageLogin>(response.error)).isPasswordExpired) {
          this.setPwdExpired(true);
          this.setExpirationMonths((<GecoErrorMessageLogin>(response.error)).expirationMonths);
        }
      } else if (!response.successful && response.token) {
        this.spinnerService.setMessage('spinner.paymentsPortalRedirect');
        this.spinnerService.setStayOnTop();
        const redirectUrl = Constants.URL_CHANGE_PROFILE_GECO_PROFILE
          .replace('@token@', response.token)
          .replace('@loginId@', response.output['id'])
          .replace('@session@', response.output['sessionId'])
          .replace('@newProfile@', response.output['currentProfileId']);
        window.location.href = redirectUrl;
      }
      return response;
    }));
  }

  logout() {
    localStorage.removeItem(Constants.TOKEN_KEY);
    this.loggedIn.next(false);
    this.profileService.clearUserInfo();
    this.messageService.clearMessages();
    this.modalService.hide(1);

  }

  get isLoggedIn(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }

  get isPwdExpired(): Observable<boolean> {
    return this.pwdExpired.asObservable();
  }

  get expireMonths(): Observable<number> {
    return this.expirationMonths.asObservable();
  }

  get isRegistration(): Observable<boolean> {
    return this.registrationPage.asObservable();
  }

  verifySso(token: string): Observable<GecoResponse> {
    const formData = new FormData();
    formData.append('pToken_in', token);
    this.spinnerService.setMessage('spinner.authenticating');
    return this.httpClient.post(Constants.URL_VALIDATE_TOKEN, formData).pipe(tap((response: GecoResponse) => {
      if (response.successful) {
        localStorage.setItem(Constants.TOKEN_KEY, response.token);
        this.loggedIn.next(true);
      }
      return response;
    }));
  }

  sendLoginToken(email: string, captchaKey: string) {
    const formData = new FormData();

    const language = Constants.LANGUAGE_OPTIONS.find(lang => lang.code === localStorage.getItem(Constants.LANG_KEY)) ?
      Constants.LANGUAGE_OPTIONS.find(lang => lang.code === localStorage.getItem(Constants.LANG_KEY)).gecoCode : 'EN';

    formData.append('pLanguage_in', language);
    formData.append('pEmail_in', email);
    formData.append('pDomain_in', Constants.DOMAIN);
    formData.append('pCaptchaKey_in', captchaKey);

    return this.httpClient.post(Constants.URL_SEND_LOGIN_TOKEN, formData).subscribe((response: GecoResponse) => {
      if (!response.successful && (response.error as GecoErrorMessage).message) {  // Type validation.
        const error: GecoErrorMessage = response.error as GecoErrorMessage;
        this.messageService.addMessage({
          type: 'error',
          icon: '',
          code: error.message,
          errorObject: response,
        });
      }
      return response;
    });

  }

  setLoggedIn(logged: boolean) {
    this.loggedIn.next(logged);
  }

  setPwdExpired(logged: boolean) {
    this.pwdExpired.next(logged);
  }

  setExpirationMonths(months: number) {
    this.expirationMonths.next(months);
  }

  setRegistrationPage(reg: boolean) {
    this.registrationPage.next(reg);
  }


}
