import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { ProfileHistoryRoutingModule } from './profile-history-routing.module';
import { ProfileHistoryComponent } from './profile-history.component';

@NgModule({
  imports: [
    SharedModule,
    ProfileHistoryRoutingModule,
  ],
  declarations: [
    ProfileHistoryComponent,
  ],
})
export class ProfileHistoryModule { }
