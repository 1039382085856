<div class="card">
  <div class="card-body text-center">
    <h2 [innerHTML]="'contact.contactHeader' | translate"></h2>
    <p class="mb-5" [innerHTML]="'contact.contactDtl' | translate"></p>

    <div class="row">
      <div class="col-md">
        <i class="far fa-comment fa-3x text-primary mb-4"></i>
        <h3 [innerHTML]="'contact.support.title' | translate"></h3>
        <p class="mb-4" [innerHtml]="'contact.support.summary' | translate"></p>
        <a href="{{customerSupportUrl}}" class="btn btn-primary mb-5" target="_blank">{{'contact.support.button' | translate}}</a>
      </div>

      <div class="col-md">
        <i class="far fa-user fa-3x text-primary mb-4"></i>
        <h3 [innerHTML]="'contact.sales.title' | translate"></h3>
        <p class="mb-4" [innerHtml]="'contact.sales.summary' | translate"></p>
        <a href="{{contactSalesURl}}" class="btn btn-primary mb-5" target="_blank">{{'contact.sales.button' | translate}}</a>
      </div>
    </div>
  </div>
</div>