<form [formGroup]="taxForm">


  <div class="form-group row" [class.form-group-invalid]="!isValidVat">
    <label class="{{labelColumnCssClasses}} col-form-label">{{tax1Label.value | translate}}</label>
    <div class="{{fieldColumnCssClasses}}">
      <div class="row form-control-segments">
        <div class="col-3">
          <input type="text" class="form-control" [class.is-invalid]="!isValidVat" formControlName="tax1Prefix"
            name="tax1Prefix" placeholder="{{'legalDefaultTax.TaxLabel1PrefPlaceholder' | translate}}" />
        </div>
        <div class="col-6">
          <input *ngIf="isTax2Registered" type="text" class="form-control" [class.is-invalid]="isInvalid(tax1)"
            [class.is-invalid]="!isValidVat" formControlName="tax1" name="tax1"
            placeholder="{{'legalDefaultTax.TaxLabel1AllPlaceholder' | translate}}" taValidateNoNull="tax2" />
          <input *ngIf="!isTax2Registered" type="text" class="form-control" [class.is-invalid]="isInvalid(tax1)"
            [class.is-invalid]="!isValidVat" formControlName="tax1" name="tax1"
            placeholder="{{'legalDefaultTax.TaxLabel1AllPlaceholder' | translate}}" required/>
          <div *ngIf="tax1.invalid" class="invalid-feedback">
            <span>{{isInvalidMessage(tax1) }}</span>
          </div>
          <div *ngIf="!isValidVat" class="invalid-feedback d-block">
            <span>{{'legalDefaultTax.invalidVat' | translate}}</span>
          </div>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="pendingReviewHelpTex;context:{pendingField: 'tax1'}"></ng-container>
      <ng-container *ngTemplateOutlet="tax2HelpTex"></ng-container>
    </div>
  </div>

  <div class="form-group row" [hidden]="isTax2BlockHide" [class.form-group-invalid]="!isValidTax">
    <label class="{{labelColumnCssClasses}} col-form-label">{{tax2Label.value | translate}}</label>
    <div class="{{fieldColumnCssClasses}}">
      <div class="row form-control-segments">
        <div class="col-6">
          <input *ngIf="isTax2Registered" type="text" class="form-control" [class.is-invalid]="isInvalid(tax2)"
            [class.is-invalid]="!isValidTax" formControlName="tax2" name="tax2"
            placeholder="{{'legalDefaultTax.TaxLabel2Placeholder' | translate}}" taValidateNoNull="tax1" />
          <div *ngIf="tax2.invalid" class="invalid-feedback">
            <span>{{isInvalidMessage(tax2) }}</span>
          </div>
          <div *ngIf="!isValidTax" class="invalid-feedback d-block">
            <span>{{'legalDefaultTax.invalidTax' | translate}}</span>
          </div>
        </div>
      </div>
      <div>
        <ng-container *ngTemplateOutlet="pendingReviewHelpTex;context:{pendingField: 'tax2'}"></ng-container>
      </div>
    </div>
  </div>

</form>

<ng-template #pendingReviewHelpTex let-pendingField=pendingField>
  <small *ngIf="isPendingReview(pendingField)" class="form-text text-muted">
    {{'pendingChanges.profileTxt1' | translate}}
    <a href="#/profile-history" class="alert-link"
      (click)="close()">{{'pendingChanges.profileTxt' | translate}}</a>
    {{'pendingChanges.profileTxt2' | translate}}
  </small>
</ng-template>


<ng-template #tax2HelpTex>
  <small *ngIf="isTax2LinkShow" class="form-text text-muted">
    {{'legalDefaultTax.tax2BlockLinkText1' | translate}}
    <a href="#" class="alert-link"
      (click)="showTax2Block()">{{'legalDefaultTax.tax2BlockLinkText2' | translate}}</a>
    {{'legalDefaultTax.tax2BlockLinkText3' | translate}}
  </small>
</ng-template>