import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDesignSystemComponentsModule } from '@onyx/ngx-design-system-components';
import { TinCardComponent } from '@shared/components/legal-details/tin-card/tin-card.component';
import { EqualValidatorDirective } from '@shared/directives/equal-validator.directive';
import { OneNoNullDirective } from '@shared/directives/one-nonull-validator.directive';
import { UppercaseDirective } from '@shared/directives/upper-case.directive';
import { environment } from 'environments/environment';
import { RecaptchaFormsModule, RecaptchaModule, RECAPTCHA_SETTINGS, RECAPTCHA_BASE_URL } from 'ng-recaptcha';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AddressBlockComponent, AddressCardComponent, AddressFormComponent, AddressModalComponent } from './components/address';
import { ContactCardComponent } from './components/contact/contact-card/contact-card.component';
import { ContactModalComponent } from './components/contact/contact-modal/contact-modal.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { EmptyCardComponent } from './components/empty-card/empty-card.component';
import { CaTaxFormComponent } from './components/legal-details/ca-tax-form/ca-tax-form.component';
import { DefaultTaxFormComponent } from './components/legal-details/default-tax-form/default-tax-form.component';
import { LegalDetailsCardComponent } from './components/legal-details/legal-details-card/legal-details-card.component';
import { LegalDetailsModalComponent } from './components/legal-details/legal-details-modal/legal-details-modal.component';
import { UsTaxFormComponent } from './components/legal-details/us-tax-form/us-tax-form.component';
import { VatDefaultCardComponent } from './components/legal-details/vat-default-card/vat-default-card.component';
import { PageMessagesComponent } from './components/page-messages/page-messages.component';
import { PagePwdMessagesComponent } from './components/page-pwd-messages/page-pwd-messages.component';
import { PageSpinnerComponent } from './components/page-spinner/page-spinner.component';
import { UserSecurityComponent } from './components/user-security/user-security.component';
import { UserSettingsComponent } from './components/user-settings/user-settings.component';
import { LoginCovidComponent } from './components/login-covid/login-covid.component';
import { PipesModule } from './pipes/pipes.module';
import { ExpirationModalComponent } from './components/expiration-modal/expiration-modal.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NoWhitespaceDirective } from './directives/no-white-space-validator.directive';
import { SortingDropdownComponent } from './components/sorting-dropdown/sorting-dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxDesignSystemComponentsModule,
    BsDatepickerModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule,
    PipesModule,
    PopoverModule.forRoot(),
  ],
  declarations: [
    AddressBlockComponent,
    AddressCardComponent,
    AddressFormComponent,
    AddressModalComponent,
    LegalDetailsCardComponent,
    ContactCardComponent,
    ContactModalComponent,
    EmptyCardComponent,
    TinCardComponent,
    VatDefaultCardComponent,
    LegalDetailsModalComponent,
    UserSettingsComponent,
    UserSecurityComponent,
    PageMessagesComponent,
    PageSpinnerComponent,
    LegalDetailsModalComponent,
    CaTaxFormComponent,
    DefaultTaxFormComponent,
    UsTaxFormComponent,
    DatePickerComponent,
    PagePwdMessagesComponent,
    UppercaseDirective,
    EqualValidatorDirective,
    OneNoNullDirective,
    LoginCovidComponent,
    ExpirationModalComponent,
    NoWhitespaceDirective,
    SortingDropdownComponent,
  ],
  providers: [
    DatePipe,
    { provide: RECAPTCHA_SETTINGS, useValue: { siteKey: environment.captchaSiteKey } },
    { provide: RECAPTCHA_BASE_URL, useValue: environment.captchaBaseUrl },
  ],
  entryComponents: [
    AddressModalComponent,
    ContactModalComponent,
    LegalDetailsModalComponent,
    ExpirationModalComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxDesignSystemComponentsModule,
    BsDatepickerModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    PipesModule,
    PopoverModule,
    AddressBlockComponent,
    AddressCardComponent,
    AddressFormComponent,
    AddressModalComponent,
    LegalDetailsCardComponent,
    ContactCardComponent,
    ContactModalComponent,
    EmptyCardComponent,
    TinCardComponent,
    VatDefaultCardComponent,
    LegalDetailsModalComponent,
    UserSettingsComponent,
    UserSecurityComponent,
    PageMessagesComponent,
    PageSpinnerComponent,
    LegalDetailsModalComponent,
    CaTaxFormComponent,
    DatePickerComponent,
    PagePwdMessagesComponent,
    UppercaseDirective,
    EqualValidatorDirective,
    OneNoNullDirective,
    LoginCovidComponent,
    ExpirationModalComponent,
    NoWhitespaceDirective,
    SortingDropdownComponent,
  ],
})
export class SharedModule { }
