import { Component, OnInit } from '@angular/core';
import { Message } from '@core/models';
import { MessagesUtilsService } from '@core/services/messages-utils.service';
import { Observable } from 'rxjs';
import { AuthService } from '@app/auth/services/auth.service';


@Component({
  selector: 'ta-page-messages',
  templateUrl: './page-messages.component.html',
})
export class PageMessagesComponent implements OnInit {

  hasMessages = false;
  messages: Observable<Message[]>;
  isUserLoggedIn: boolean;

  constructor(
    private messageService: MessagesUtilsService,
    private authService: AuthService
  ) {

  }

  ngOnInit(): void {

    this.authService.isLoggedIn.subscribe(value => {
      this.isUserLoggedIn = value;
    });

    this.messages = this.messageService.messageList;

    this.messages.subscribe(list => {
      if (list.length > 0) {
        this.hasMessages = true;

        for (const message of list) {
          if (message.type === 'warning') {
            setTimeout(() => {
              this.onMessageCloseClicked(message);
            }, 5000);
          }
        }
      }
    });
  }

  public onMessageCloseClicked(message: Message): void {
    this.messageService.removeMessage(message);
  }
}
