import { Component, Input, OnInit } from '@angular/core';
import { SpinnerUtilsService } from '@core/services/spinner-utils.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'ta-page-spinner',
  templateUrl: './page-spinner.component.html',
})
export class PageSpinnerComponent implements OnInit {

  @Input() url;
  show: boolean;
  message: Observable<string>;

  constructor(
    private spinnerService: SpinnerUtilsService
  ) { }

  ngOnInit() {
    this.spinnerService.isActive.subscribe(result => {
      this.show = result;
    });

    this.message = this.spinnerService.message;
  }

}
