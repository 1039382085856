<div class="card" [class.card-table]="content?.length > 0">
  <div class="card-header">
    <div class="row">
      <div class="col-lg-8">
        <h2 class="card-title">{{'taContactCard.title' | translate}}</h2>
      </div>
      <div class="col-lg-4 text-lg-right">
        <button class="btn btn-sm btn-secondary text-nowrap" data-toggle="modal"
          (click)="openModal('contactAddUpdate')">
          <i class="far fa-plus"></i>
          {{'common.addBtn' | translate}}
        </button>
      </div>
    </div>
    <ng-container *ngIf="!hasPaymentCorrespondaceContact && content?.length > 0">
      <div class="row">&nbsp;</div>
      <div class="row">
        <ods-alert-inline type="warning" class="col-lg">
          {{'taContactCard.missingPCContactText1' | translate}} <strong style="cursor: pointer;"
            (click)="openModal('contactAddUpdate')">{{'taContactCard.missingPCContactText2' | translate}}</strong>
          {{'taContactCard.missingPCContactText3' | translate}}
        </ods-alert-inline>
      </div>
    </ng-container>
  </div>

  <div class="card-body">

    <ng-template [ngIf]="content?.length > 0" [ngIfElse]="emptyCard">
      <table class="table">
        <thead>
          <tr>
            <th>{{'taContactCard.name' | translate}}
              <span class="text-caption">{{'taContactCard.email' | translate}}</span>
            </th>
            <th>{{'taContactCard.phone' | translate}}
              <span class="text-caption">{{'taContactCard.fax' | translate}}</span>
            </th>
            <th>{{'taContactCard.type' | translate}}</th>
            <th class="cell-menu"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let person of content | slice: contactListFrom : contactListTo ">
            <td>{{person.name}}
              <span class="text-caption">
                <a href="mailto:{{person.email}}">
                  {{person.email}}
                </a></span>
            </td>
            <td>{{person.phoneNr}}
              <span class="text-caption">
                {{person.faxNr}}
              </span>
            </td>
            <td>
              <span class="text-caption"
                *ngIf="person.paymentCorrespondence">{{'contactType.paymentCorrespondence' | translate}}</span>
              <span class="text-caption"
                *ngIf="person.paymentCorrespondenceCopiedRecipient">{{'contactType.paymentCorrespondenceCopiedRecipient' | translate}}</span>
              <span class="text-caption"
                *ngIf="person.salesManager">{{'contactType.salesManager' | translate}}</span>
              <span class="text-caption"
                *ngIf="person.invoiceRecipient">{{'contactType.invoiceRecipient' | translate}}</span>
              <span class="text-caption"
                *ngIf="isVccAllowed && person.vccPaymentRecipient">{{'contactType.vccPaymentRecipient' | translate}}</span>  
            </td>
            <td class="cell-menu">
              <div class="dropdown" *ngIf="!person.hasPendingChanges">
                <button aria-expanded="false" aria-haspopup="true" data-toggle="dropdown" type="button" class="btn"
                  id="dropdownMenuButton">
                  <i class="far fa-ellipsis-h"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" href="#contactUpdateModal" data-toggle="modal"
                    (click)="openModal('contactAddUpdate', person)">{{'common.updateBtn' | translate}}</a>
                  <a *ngIf="!person.paymentCorrespondence" class="dropdown-item" data-toggle="modal"
                    (click)="openModal('contactDelete', person)" data-myvalue="trouducul"
                    data-myvar="bisbis">{{'common.deleteBtn' | translate}}</a>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="card-footer">
        <span class="d-none d-md-inline mr-3"
          class="text-caption">{{'taContactCard.footerInfo' | translate}}</span>
      </div>
    </ng-template>

    <ng-template #emptyCard>
      <ta-empty-card [card]="'contacts'" [taCode]="taCode" [pendingFields]="pendingFields"
        [hasPaymentCorrespondaceContactPending]=hasPaymentCorrespondaceContactPending></ta-empty-card>
    </ng-template>

    <ng-template #emptyCard>
      <ta-empty-card [card]="'contacts'" [taCode]="taCode" [pendingFields]="pendingFields"
        [hasVccPaymentRecipientContactPending]=hasVccPaymentRecipientContactPending></ta-empty-card>
    </ng-template>    

  </div>

  <ng-container *ngIf="showPagination">
    <div class="card-footer">
      <div class="form-inline float-right">
        <span class="d-none d-md-inline mr-3"
          [innerHTML]="'common.pagination' | translate | replace: {from: contactListFrom+1, to: contactListTo, total: totalContacts }"></span>
        <div aria-label="Data Row Page" class="btn-group" role="group">
          <button aria-label="Previous" class="btn btn-secondary" type="button" (click)="onChangePage(-1)"
            [disabled]="isFirstPage">
            <i class="fas fa-caret-left"></i>
            <span class="d-none d-md-inline ml-2">{{'common.previousBtn' | translate}}</span>
          </button>
          <button aria-label="Next" class="btn btn-secondary" type="button" (click)="onChangePage(1)"
            [disabled]="isLastPage">
            <span class="d-none d-md-inline mr-2">{{'common.nextBtn' | translate}}</span>
            <i class="fas fa-caret-right"></i>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>