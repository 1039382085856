export class Contact {
  id: number;
  contactKey?: string;
  type?: string;
  name: string;
  email: string;
  phoneNr: string;
  faxNr: string;
  paymentCorrespondence: boolean;
  paymentCorrespondenceCopiedRecipient: boolean;
  invoiceRecipient: boolean;
  salesManager: boolean;
  vccPaymentRecipient: boolean;
  hasPendingChanges: boolean;
}
