import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { TaprofileUtilsService } from '@core/services';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { Contact } from '../../../../core/models/contact.model';
import { ContactModalComponent } from '../contact-modal/contact-modal.component';

@Component({
  selector: 'ta-contact-card',
  templateUrl: './contact-card.component.html',
})
export class ContactCardComponent implements OnInit, OnChanges, OnDestroy {

  @Input() content: Contact[] = [];
  @Input() taCode: string;
  @Input() pendingFields: string[] = [];

  hasPaymentCorrespondaceContact: boolean;
  hasPaymentCorrespondaceContactPending: boolean;
  hasVccPaymentRecipientContact: boolean;
  hasVccPaymentRecipientContactPending: boolean;

  subscription: Subscription = new Subscription();

  public activePage = 1;
  private pageSize = 5;
  public totalContacts: number;
  public contactListFrom: number;
  public contactListTo: number;
  public showPagination: boolean;

  public isLastPage: boolean;
  public isFirstPage: boolean;

  public isVccAllowed = false;

  bsModalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private profileService: TaprofileUtilsService
  ) { }

  ngOnInit() {
    this.contactList();

    this.subscription.add (
      this.profileService.getAgencyInfo().subscribe((data) => {
        this.isVccAllowed = this.profileService.isVccAllowed(data.newInfo.commercial.countryCode);
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnChanges() {
    this.contactList();
  }

  public contactList(): void {

    if (this.content) {
      this.totalContacts = this.content.length;
    }
    this.showPagination = this.totalContacts > this.pageSize;
    this.activePage = 1;
    this.onChangePage(0);

    this.existsPaymentCorrespondaceContact();
    this.existsPaymentCorrespondaceContactPending();
    this.existsVccPaymentRecipientContact();
    this.existsVccPaymentRecipientContactPending();
  }

  public onChangePage(increment: number): void {
    this.activePage = this.activePage + increment;

    this.isFirstPage = this.activePage === 1;
    this.isLastPage = this.activePage >= (this.totalContacts / this.pageSize);

    this.contactListFrom = (this.activePage - 1) * this.pageSize;
    this.contactListTo = this.activePage * this.pageSize;

    if (this.contactListTo > this.totalContacts) {
      this.contactListTo = this.totalContacts;
    }
  }

  openModal(templateOption: string, contact?: Contact) {
    const modalOptions: any = {
      class: 'modal-lg',
      initialState: {
        action: templateOption,
        taCode: this.taCode,
        pendingFields: this.pendingFields,
        contact: contact,
        hasPaymentCorrespondaceContact: this.hasPaymentCorrespondaceContact,
        hasPaymentCorrespondaceContactPending: this.hasPaymentCorrespondaceContactPending,
        hasVccPaymentRecipientContact: this.hasVccPaymentRecipientContact,
        hasVccPaymentRecipientContactPending: this.hasVccPaymentRecipientContactPending,
      },
    };
    this.bsModalRef = this.modalService.show(ContactModalComponent, modalOptions);
  }

  existsPaymentCorrespondaceContact() {
    this.hasPaymentCorrespondaceContact = false;
    if (this.content) {
      this.content.forEach(element => {
        if (element.paymentCorrespondence) {
          this.hasPaymentCorrespondaceContact = true;
        }
      });
    }
  }

  existsPaymentCorrespondaceContactPending() {
    this.hasPaymentCorrespondaceContactPending = false;
    if (this.pendingFields.indexOf('CONTACTPAYMENTCORRESPONDENCE') > -1) {
      this.hasPaymentCorrespondaceContactPending = true;
    }
  }

  existsVccPaymentRecipientContact() {
    this.hasVccPaymentRecipientContact = false;
    if (this.content) {
      this.content.forEach(element => {
        if (element.vccPaymentRecipient) {
          this.hasVccPaymentRecipientContact = true;
        }
      });
    }
  }

  existsVccPaymentRecipientContactPending() {
    this.hasVccPaymentRecipientContactPending = false;
    if (this.pendingFields.indexOf('CONTACTVCCPAYMENTRECIPIENT') > -1) {
      this.hasVccPaymentRecipientContactPending = true;
    }
  }
}
