<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title">{{ 'expirationTokenModal.title' | translate  }}</h5>
  </div>
  <div class="modal-body">
    <div *ngIf="countDown > 0" class="button-bar">
      {{ 'expirationTokenModal.willExpire' | translate   | replace: {countdown: timerFormatCountDown } }}
    </div>
    <div *ngIf="countDown === 0" class="button-bar">
      {{ 'expirationTokenModal.sessionExpired' | translate   }}
    </div>
  </div>
  <div class="modal-footer">
    <div *ngIf="countDown > 0" class="button-bar">
      <button class="btn btn-secondary" type="button" data-dismiss="modal" (click)="endSession()">
        {{ 'expirationTokenModal.logout' | translate}}
      </button>
      <button class="btn btn-primary" type="button" (click)="refreshAuthentication()">
        {{ 'expirationTokenModal.stayLogged' | translate}}
      </button>
    </div>
    <div *ngIf="countDown === 0" class="button-bar">
      <button class="btn btn-secondary" type="button" (click)="endSession()">
        {{ 'expirationTokenModal.logout' | translate}}
      </button>
    </div>
  </div>
</div>