import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessPwdComponent } from './components/access-pwd/access-pwd.component';
import { PasswordForgotComponent } from './components/access-pwd/password-forgot/password-forgot.component';
import { PasswordResetComponent } from './components/access-pwd/password-reset/password-reset.component';
import { AccessRequestComponent } from './components/access-request/access-request.component';
import { LoginSsoComponent } from './components/login-sso/login-sso.component';
import { LoginComponent } from './components/login/login.component';
import { UserInfoResolver } from '@core/resolvers';
import { MaintanceAppComponent } from './components/maintenance/maintance.component';
import { ConfirmRegistrationComponent } from './components/confirm-registration/confirm-registration.component';

const routes: Routes = [
  {
    path: 'auth',
    children: [
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent },
      { path: 'access-pwd', component: AccessPwdComponent },
      { path: 'access-request', component: AccessRequestComponent },
      { path: 'pwd-reset', component: PasswordResetComponent, resolve: { userInfo: UserInfoResolver } },
      { path: 'pwd-forgot', component: PasswordForgotComponent },
      { path: 'sso', component: LoginSsoComponent },
      { path: 'maintenance', component: MaintanceAppComponent },
      { path: 'confirm-email', component: ConfirmRegistrationComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule { }
