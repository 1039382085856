import { Component, OnInit } from '@angular/core';
import { Constants } from '@app/app.constants';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ta-maintenance',
  templateUrl: './maintance.component.html',
})
export class MaintanceAppComponent implements OnInit {

  customerSupportUrl: string = Constants.URL_CUSTOMER_SUPPORT;

  showMessage: boolean;

  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute
  ) {
    this.translate.setDefaultLang('en');
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.message === 'Y') {
        this.showMessage = true;
      } else {
        this.showMessage = false;
      }
    });
  }

}
