import { ColInfo, utils as XLSXUtils, writeFile } from 'xlsx/xlsx.js';
import { WorkBook, WorkSheet } from 'xlsx/types';

import { Injectable } from '@angular/core';

export interface IExportAsExcelProps {
  readonly data: any[];
  readonly fileName: string;
  readonly sheetName?: string;
  readonly header?: string[];
  readonly wscols?: ColInfo[];
  readonly table?: HTMLElement;
}

@Injectable({
  providedIn: 'root',
})
export class ExportExcelService {
  fileExtension = '.xlsx';

  public exportAsExcel({
    data,
    fileName,
    sheetName = 'Data',
    header = [],
    wscols = [],
    table,
  }: IExportAsExcelProps): void {
    let wb: WorkBook;

    if (table) {
      wb = XLSXUtils.table_to_book(table);
    } else {
      if (header) {
        wb = XLSXUtils.book_new();
        const ws: WorkSheet = XLSXUtils.json_to_sheet([]);

        if (wscols) {
          ws['!cols'] = wscols;
        }

        XLSXUtils.sheet_add_aoa(ws, [header]);

        // Starting in the second row to avoid overriding and skipping headers
        XLSXUtils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });

        XLSXUtils.book_append_sheet(wb, ws, sheetName);
      } else {
        const ws: WorkSheet = XLSXUtils.json_to_sheet(data, { header });
        wb = XLSXUtils.book_new();
        XLSXUtils.book_append_sheet(wb, ws, sheetName);
      }



    }

    writeFile(wb, `${fileName}${this.fileExtension}`);
  }
}
