import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '../../app.constants';
import { Contact } from '../models/contact.model';
import { SpinnerUtilsService } from './spinner-utils.service';
import { tap, catchError } from 'rxjs/operators';
import { Agency, AgencyInfo } from '@core/models';
import { EMPTY, BehaviorSubject } from 'rxjs';

const requestOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class ContactUtilsService {

  private isValidDomain: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isValidDomain$ = this.isValidDomain.asObservable();
  taCode: string;

  constructor(
    private httpClient: HttpClient,
    private spinnerService: SpinnerUtilsService
  ) { }

  addContactService(taCode: string, contact: Contact) {
    const newContact = {
      taCode: taCode,
      name: contact.name,
      email: contact.email,
      phone: contact.phoneNr,
      fax: contact.faxNr,
      paymentCorrespondence: contact.paymentCorrespondence,
      paymentCorrespondenceCopiedRecipient: contact.paymentCorrespondenceCopiedRecipient,
      invoiceRecipient: contact.invoiceRecipient,
      salesManager: contact.salesManager,
      vccPaymentRecipient: contact.vccPaymentRecipient,
    };

    this.spinnerService.setMessage('spinner.savingProfile');
    return this.httpClient.post(Constants.URL_CONTACT_ADD, newContact, requestOptions).pipe(
      tap((agencyInfo: AgencyInfo) => {
        return agencyInfo;
      }),
      catchError(error => {
        if ( error.code === 'ERROR_REQUEST_0015' ) {
          this.isValidDomain.next (false);
        }
        return EMPTY;
      })
    );

  }

  updateContactService(taCode: string, contact: Contact) {
    const updateContact = {
      taCode: taCode,
      contactId: contact.id,
      contactKey: contact.contactKey,
      name: contact.name,
      email: contact.email,
      phone: contact.phoneNr,
      fax: contact.faxNr,
      paymentCorrespondence: contact.paymentCorrespondence,
      paymentCorrespondenceCopiedRecipient: contact.paymentCorrespondenceCopiedRecipient,
      invoiceRecipient: contact.invoiceRecipient,
      salesManager: contact.salesManager,
      vccPaymentRecipient: contact.vccPaymentRecipient,
    };

    this.spinnerService.setMessage('spinner.savingProfile');
    return this.httpClient.post(Constants.URL_CONTACT_EDIT, updateContact, requestOptions).pipe(
      tap((agencyInfo: AgencyInfo) => {
        this.isValidDomain.next (true);
        return agencyInfo;
      }),
      catchError(error => {
        if ( error.code === 'ERROR_REQUEST_0015' ) {
          this.isValidDomain.next (false);
        }
        return EMPTY;
      })
    );
  }

  deleteContactService(taCode: string, contactKey: string) {
    const deleteContact = {
      taCode: taCode,
      contactKey: contactKey,
    };

    this.spinnerService.setMessage('spinner.savingProfile');
    return this.httpClient.post(Constants.URL_CONTACT_DELETE, deleteContact, requestOptions);
  }

  setIsValidDomain(value: boolean) {
    this.isValidDomain.next(value);
  }
}
