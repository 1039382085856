import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Agency } from '@core/models';
import { TaprofileUtilsService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'app/app.constants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ta-ca-tax-form',
  templateUrl: './ca-tax-form.component.html',
})
export class CaTaxFormComponent implements OnInit, OnDestroy {


  labelColumnCssClasses = 'col-lg-3';
  fieldColumnCssClasses = 'col-lg-9';
  @Input() newInfo: Agency;
  @Input() pendingFieldsCard: string[];
  @Output() closeModalFromChild = new EventEmitter<string>();

  taxForm: FormGroup;

  subscription: Subscription;
  isValidGst: boolean;
  isValidQst: boolean;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private profileService: TaprofileUtilsService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {

    this.subscription = new Subscription();

    this.taxForm = this.fb.group({
      gstSelect: [''],
      gst: [''],
      qstSelect: [''],
      qst1: [''],
      qstTq: [{ value: 'TQ', disabled: true }],
      qst2: [''],

    });

    if (this.newInfo && this.newInfo.legal) {
      if (this.newInfo.legal.primaryTax && this.newInfo.legal.primaryTax === Constants.TAX_NOT_REGISTERED) {
        this.setGstSelect('N');
      }

      if (this.newInfo.legal.secondaryTax && this.newInfo.legal.secondaryTax === Constants.TAX_NOT_REGISTERED) {
        this.setQstSelect('N');
      }
    }
    this.setGstValue();
    this.setQstValue();

    this.disablePendingFields();

    this.subscription.add(this.profileService.isValidVat$.subscribe(isValidVat => {
      this.isValidGst = isValidVat;
    }));

    this.subscription.add(this.profileService.isValidTax$.subscribe(isValidTax => {
      this.isValidQst = isValidTax;
    }));

  }

  ngOnDestroy(): void {
    this.profileService.setIsValidVat(true);
    this.profileService.setIsValidTax(true);
    this.subscription.unsubscribe();
  }

  get gstSelect() { return this.taxForm.get('gstSelect'); }
  get gst() { return this.taxForm.get('gst'); }

  get qstSelect() { return this.taxForm.get('qstSelect'); }
  get qst1() { return this.taxForm.get('qst1'); }
  get qstTq() { return this.taxForm.get('qstTq'); }
  get qst2() { return this.taxForm.get('qst2'); }

  setGstSelect(value: string) { this.gstSelect.patchValue(value); }
  setQstSelect(value: string) { this.qstSelect.patchValue(value); }

  /** GST */

  onGstRegisteredChange() {
    if (this.gstSelect.value === 'Y') {
      this.setGstValue();
    } else {
      this.resetGstValue();
    }
  }

  isGstRegistered() {
    return this.gstSelect.value === 'Y';
  }

  setGstValue() {
    if (this.newInfo && this.newInfo.legal) {
      let vat: string;
      if (this.newInfo.legal.primaryTaxLabel === 'GST' && this.newInfo.legal.primaryTax !== Constants.TAX_NOT_REGISTERED) {
        vat = this.newInfo.legal.primaryTax;
        this.setGstSelect('Y');
        this.gst.patchValue(vat);
      }
    }
  }

  resetGstValue() {
    this.gst.patchValue(null);
  }

  /** QST */
  onQstRegisteredChange() {
    if (this.qstSelect.value === 'Y') {
      this.setQstValue();
    } else {
      this.resetQstValue();
    }
  }

  isQstRegistered() {
    return this.qstSelect.value === 'Y';
  }

  setQstValue() {
    if (this.newInfo && this.newInfo.legal) {
      let vat: string;
      if (this.newInfo.legal.secondaryTaxLabel === 'QST' && this.newInfo.legal.secondaryTax !== Constants.TAX_NOT_REGISTERED) {
        vat = this.newInfo.legal.secondaryTax;
        this.setQstSelect('Y');
        this.qst1.patchValue(vat.substr(0, 10));
        this.qst2.patchValue(vat.substr(12));
      }
    }
  }

  resetQstValue() {
    this.qst1.patchValue(null);
    this.qst2.patchValue(null);
  }

  disablePendingFields() {
    if (this.pendingFieldsCard) {
      this.pendingFieldsCard.forEach(pendingField => {
        if (this.taxForm.get(pendingField)) {
          this.disableControl(this.taxForm.get(pendingField), this.taxForm.get(pendingField).value);
        }
      });
    }
  }

  private disableControl(control: AbstractControl, value: string) {
    control.patchValue(value);
    control.disable();
  }

  isPendingReview(field: string) {
    if (this.pendingFieldsCard) {
      return this.pendingFieldsCard.indexOf(field) !== -1;
    }
  }

  close() {
    this.closeModalFromChild.emit();
  }

  get qst(): string {
    return this.qst1.value + this.qstTq.value + this.qst2.value;
  }
}
