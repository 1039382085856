import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpinnerUtilsService } from '@core/services/spinner-utils.service';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpSpinnerInterceptor implements HttpInterceptor {

  count = 0;

  constructor(
    private spinner: SpinnerUtilsService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinner.show();
    this.count++;
    return next.handle(req)
      .pipe(
        finalize(() => {
          this.count--;
          if (this.count === 0) { this.spinner.hide(); }
        })
      );
  }
}
