import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { AgencyGroupRoutingModule } from './agency-group-routing.module';
import { AgencyGroupComponent } from './agency-group.component';

@NgModule({
  imports: [
    SharedModule,
    AgencyGroupRoutingModule,
  ],
  declarations: [
    AgencyGroupComponent,
  ],
})
export class AgencyGroupModule { }
