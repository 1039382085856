import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../auth/services/auth.guard';
import { AgencyGroupComponent } from './agency-group.component';
import { UserInfoResolver } from '@core/resolvers';
import { AgencyGroupResolver } from '@core/resolvers/agency-group.resolver';

const routes: Routes = [
  {
    path: 'agency-group',
    component: AgencyGroupComponent,
    canActivate: [AuthGuard],
    resolve: {
      userInfo: UserInfoResolver,
      agencyGroup: AgencyGroupResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AgencyGroupRoutingModule { }
