import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from '@app/app.constants';
import { AuthService } from '@app/auth/services/auth.service';


@Component({
  selector: 'ta-password-forgot',
  templateUrl: './password-forgot.component.html',
})
export class PasswordForgotComponent implements OnInit {

  pwdForgotForm: FormGroup;
  isValidCaptcha = false;
  public captchaKey: string;
  onyxCustomerSupportEmail: string = Constants.MAIL_ONYX_CUSTOMER_SUPPORT;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.pwdForgotForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.route.queryParams.subscribe(
      response => {
        if (response.email) {
          this.setEmail(response.email);
          this.email.disable();
        }
      }
    );
  }

  isInvalid(formControl: AbstractControl): boolean {
    return formControl.invalid && !formControl.untouched;
  }

  resetPwdSendEmail() {
    this.authService.sendLoginToken(this.email.value, this.captchaKey);
    this.router.navigate(['/pwd-messages'], { queryParams: { pwdMessageOrigin: 'PwdMessageReset', username: this.email.value } });
  }

  get email() { return this.pwdForgotForm.get('email'); }

  setEmail(email: string) { this.email.patchValue(email); }

  resolved(captchaResponse: string) {
    if (captchaResponse) {
      this.isValidCaptcha = true;
      this.captchaKey = captchaResponse;
    } else {
      this.isValidCaptcha = false;
      this.captchaKey = null;
    }
  }

}
