import { environment } from 'environments/environment';
import { app_name, build_number, build_date, branch_name, last_commit } from '../assets/version.json';

const URL_ONYX = 'https://www.onyxcentersource.com/';

export abstract class Constants {

  public static readonly VERSION_APP_NAME = app_name;
  public static readonly VERSION_BUILD_NUMBER = build_number;
  public static readonly VERSION_BUILD_DATE = build_date;
  public static readonly VERSION_BRANCH_NAME = branch_name;
  public static readonly VERSION_LAST_COMMIT = last_commit;

  public static readonly TOKEN_KEY = 'token';
  public static readonly TAPROFILE_APP = 'profile';

  public static readonly LANG_KEY = 'language';


  public static readonly DOMAIN = 'PROFILE';

  public static readonly URL_ONYX_PORTAL = 'https://portal.onyxcentersource.com/';
  public static readonly URL_PROFILE_PORTAL = 'https://profile.onyxcentersource.com';

  // GECO ONYX URL
  public static readonly URL_ONYX_PRIVACY_POLICY = URL_ONYX + 'privacy-policy/';
  public static readonly URL_CUSTOMER_SUPPORT = URL_ONYX + 'need-assistance/?utm_source=customer/portal&utm_medium=referral';
  public static readonly URL_CONTACT_SALES = URL_ONYX + 'contact-us/?utm_source=customer/portal&utm_medium=referral';

  public static readonly URL_MAINTENANCE = environment.apiUrl + 'maintenance';

  // TAPROFILE URL
  public static readonly URL_GENERATE_TOKEN = environment.apiUrl + '/taprofile/token';
  public static readonly URL_USER_PROFILE_LIST = environment.apiUrl + 'user/profiles';
  public static readonly URL_USER_INFO = environment.apiUrl + 'user/info';
  public static readonly URL_SAVE_USER_SETTINGS = environment.apiUrl + 'user/config';
  public static readonly URL_PROFILE_CHANGE = environment.apiUrl + 'user/profile/change';
  public static readonly URL_AGENCY_RETRIEVE = environment.apiUrl + 'agency/retrieve';
  public static readonly URL_SAVE_REQUEST = environment.apiUrl + 'agency/change/new';
  public static readonly URL_AUDIT_RETRIEVE = environment.apiUrl + 'agency/audit';
  public static readonly URL_CONTACT_DELETE = environment.apiUrl + 'agency/contact/delete';
  public static readonly URL_CONTACT_EDIT = environment.apiUrl + 'agency/contact/edit';
  public static readonly URL_CONTACT_ADD = environment.apiUrl + 'agency/contact/add';
  public static readonly URL_AGENCY_GROUP_RETRIEVE = environment.apiUrl + 'group/retrieve';
  public static readonly URL_AGENCY_GROUP_ADD = environment.apiUrl + 'group/add';
  public static readonly URL_AGENCY_GROUP_DELETE = environment.apiUrl + 'group/delete';
  public static readonly URL_EMAIL_CONFIRMATION = environment.apiUrl + 'request/confirmed';
  public static readonly URL_USER_FIRSTLOGIN = environment.apiUrl + 'user/fistlogin';
  public static readonly URL_USER_VCC_PENDING = environment.apiUrl + 'user/vccpending';

  // Common Services
  public static readonly URL_GET_COUNTRIES = environment.commonServiceApiUrl + 'v1/country';
  public static readonly URL_GET_STATES_BY_COUNTRY = environment.commonServiceApiUrl + 'v1/state/country/{country}';
  public static readonly URL_GET_STATES_BY_STATE = environment.commonServiceApiUrl + 'v1/cities/bystate/{country}/{state}';

  // AgencyId status
  public static readonly ACTIVE = 'active';
  public static readonly DEACTIVATED = 'deactivated';
  public static readonly PARTIAL = 'partial';

  // GECO URL
  public static readonly URL_LOGIN = environment.gecoPaymentUrl + 'plsql/sso_portal_login.PortalLogin';
  public static readonly URL_CHANGE_PROFILE_GECO_PROFILE = environment.gecoPaymentUrl +
    'plsql/sso_portal_login.changeProfile?pToken_in=@token@&pLoginId_in=@loginId@&pSession_in=@session@&pNewProfile_in=@newProfile@';
  public static readonly URL_VALIDATE_TOKEN = environment.gecoPaymentUrl + 'plsql/taprofile.validateSSOToken';
  public static readonly URL_VALIDATE_LEGAL_DATA = environment.gecoPaymentUrl + 'plsql/taprofile.validateLegalData';
  public static readonly URL_SEND_LOGIN_TOKEN = environment.gecoPaymentUrl + 'plsql/taprofile.resetAccountPassword';
  public static readonly URL_CHANGE_PASSWORD = environment.gecoPaymentUrl + 'plsql/taprofile.changeAccountPassword';
  public static readonly URL_VALIDATE_RECOVERY_PASSWORD_TOKEN = environment.gecoPaymentUrl +
    'plsql/taprofile.validateRecoveryPasswordToken';
  public static readonly URL_SEND_REQUEST_ACCESS = environment.gecoPaymentUrl + 'plsql/taprofile.sendRequestAccess';

  // GECO PROFILE URL
  public static readonly URL_LEGAL_NOTICE = environment.gecoPaymentUrl + 'home_texts/aviso_legal.htm';
  public static readonly URL_BANK_OF_SPAIN = environment.gecoPaymentUrl + 'pdf/Legal_Information_Spanish_Company_web.pdf';
  public static readonly URL_CLIENT_DEFENSE = environment.gecoPaymentUrl + 'home_texts/CLIENT_DEFENCE.htm';
  public static readonly URL_TERMS_OF_BUSINESS = environment.gecoPaymentUrl + 'home_texts/business_terms/BUSINESS_TERMS_@LANGUAGE@.htm';

  public static readonly MAIL_ONYX_COMMISSIONS = 'commissions@onyxcentersource.com';
  public static readonly MAIL_ONYX_CUSTOMER_SUPPORT = 'clientsupport@onyxcentersource.com';


  public static readonly SESSION_EXPIRE_WARNING_SECONDS = 120;

  /*
  * u00C0: Unicode Character 'LATIN CAPITAL LETTER A WITH GRAVE'
  * u00ff: Unicode Character 'LATIN SMALL LETTER Y WITH DIAERESIS'
  */
  // tslint:disable-next-line:max-line-length
  public static readonly LATIN_PATTERN = '^[A-z\ \u00C0-\u00ff\s\'\.,-\/#!$%\^&\*;:{}=\-_`~()ºª0123456789¡¢£¤¥¦§¨©ª«¬­®¯°±²³´µ¶·¸¹º»¼½¾¿]+$';


  public static readonly LANGUAGE_OPTIONS = [
    { 'code': 'en', 'display': 'English', 'gecoCode': 'EN' },
    { 'code': 'it', 'display': 'Italiano', 'gecoCode': 'IT' },
    { 'code': 'fr', 'display': 'Français', 'gecoCode': 'FR' },
    { 'code': 'de', 'display': 'Deutsch', 'gecoCode': 'DE' },
    { 'code': 'es', 'display': 'Español', 'gecoCode': 'SP' },
    { 'code': 'pt', 'display': 'Português', 'gecoCode': 'PT' },
    { 'code': 'ja', 'display': '日本語', 'gecoCode': 'JP' },
    { 'code': 'zh', 'display': '简体中文', 'gecoCode': 'CN' },
  ];

  public static readonly countriesWithStates: string[] = ['AU', 'CA', 'US'];
  public static readonly noStates = '99';
  public static readonly NO_STATES_NAME = 'NO DEFINIDO';

  public static readonly countriesWithTypeCanadianTax = 'CA';
  public static readonly countriesWithTypeTinTax = 'US';

  public static readonly countriesWithSecondaryTaxAlwaysShow: string[] = ['AU'];

  public static readonly MAPPING_PENDING_FIELDS = [
    { 'cardName': 'commercial', 'cardField': 'name', 'pendingField': 'NAME' },
    { 'cardName': 'commercial', 'cardField': 'address', 'pendingField': 'ADDRESS' },
    { 'cardName': 'commercial', 'cardField': 'countrySelect', 'pendingField': 'COUNTRYDSP' },
    { 'cardName': 'commercial', 'cardField': 'countrySelect', 'pendingField': 'STATEDSP' },
    { 'cardName': 'commercial', 'cardField': 'stateSelect', 'pendingField': 'STATEDSP' },
    { 'cardName': 'commercial', 'cardField': 'countrySelect', 'pendingField': 'CITYDSP' },
    { 'cardName': 'commercial', 'cardField': 'stateSelect', 'pendingField': 'CITYDSP' },
    { 'cardName': 'commercial', 'cardField': 'citySelect', 'pendingField': 'CITYDSP' },
    { 'cardName': 'commercial', 'cardField': 'zipCode', 'pendingField': 'ZIPCODE' },
    { 'cardName': 'postal', 'cardField': 'address', 'pendingField': 'POSTALADDRESS' },
    { 'cardName': 'postal', 'cardField': 'countrySelect', 'pendingField': 'POSTALCOUNTRYDSP' },
    { 'cardName': 'postal', 'cardField': 'countrySelect', 'pendingField': 'POSTALSTATEDSP' },
    { 'cardName': 'postal', 'cardField': 'stateSelect', 'pendingField': 'POSTALSTATEDSP' },
    { 'cardName': 'postal', 'cardField': 'countrySelect', 'pendingField': 'POSTALCITYDSP' },
    { 'cardName': 'postal', 'cardField': 'stateSelect', 'pendingField': 'POSTALCITYDSP' },
    { 'cardName': 'postal', 'cardField': 'citySelect', 'pendingField': 'POSTALCITYDSP' },
    { 'cardName': 'postal', 'cardField': 'zipCode', 'pendingField': 'POSTALZIPCODE' },
    { 'cardName': 'legal', 'cardField': 'name', 'pendingField': 'LEGALNAME' },
    { 'cardName': 'legal', 'cardField': 'address', 'pendingField': 'LEGALADDRESS' },
    { 'cardName': 'legal', 'cardField': 'countrySelect', 'pendingField': 'LEGALCOUNTRYDSP' },
    { 'cardName': 'legal', 'cardField': 'countrySelect', 'pendingField': 'LEGALSTATEDSP' },
    { 'cardName': 'legal', 'cardField': 'stateSelect', 'pendingField': 'LEGALSTATEDSP' },
    { 'cardName': 'legal', 'cardField': 'countrySelect', 'pendingField': 'LEGALCITYDSP' },
    { 'cardName': 'legal', 'cardField': 'stateSelect', 'pendingField': 'LEGALCITYDSP' },
    { 'cardName': 'legal', 'cardField': 'citySelect', 'pendingField': 'LEGALCITYDSP' },
    { 'cardName': 'legal', 'cardField': 'zipCode', 'pendingField': 'LEGALZIPCODE' },
    { 'cardName': 'legal', 'cardField': 'countrySelect', 'pendingField': 'PRIMARYTAX' },
    { 'cardName': 'legal', 'cardField': 'countrySelect', 'pendingField': 'SECONDARYTAX' },



    { 'cardName': 'caTax', 'cardField': 'gstSelect', 'pendingField': 'PRIMARYTAX' },
    { 'cardName': 'caTax', 'cardField': 'gst', 'pendingField': 'PRIMARYTAX' },
    { 'cardName': 'caTax', 'cardField': 'qstSelect', 'pendingField': 'SECONDARYTAX' },
    { 'cardName': 'caTax', 'cardField': 'qst1', 'pendingField': 'SECONDARYTAX' },
    { 'cardName': 'caTax', 'cardField': 'qstTq', 'pendingField': 'SECONDARYTAX' },
    { 'cardName': 'caTax', 'cardField': 'qst2', 'pendingField': 'SECONDARYTAX' },

    { 'cardName': 'defaultTax', 'cardField': 'tax1Prefix', 'pendingField': 'PRIMARYTAX' },
    { 'cardName': 'defaultTax', 'cardField': 'tax1', 'pendingField': 'PRIMARYTAX' },
    { 'cardName': 'defaultTax', 'cardField': 'tax2', 'pendingField': 'SECONDARYTAX' },


    { 'cardName': 'usTax', 'cardField': 'ssnOrEin', 'pendingField': 'PRIMARYTAX' },
    { 'cardName': 'usTax', 'cardField': 'ssnOrEinSelect', 'pendingField': 'PRIMARYTAX' },
    { 'cardName': 'usTax', 'cardField': 'ssnOrEin', 'pendingField': 'PRIMARYTAXLABEL' },
    { 'cardName': 'usTax', 'cardField': 'ssnOrEinSelect', 'pendingField': 'PRIMARYTAXLABEL' },
    { 'cardName': 'usTax', 'cardField': 'statusSelect', 'pendingField': 'BUSINESSTYPE' },
    { 'cardName': 'usTax', 'cardField': 'exemptBWSelect', 'pendingField': 'EXEMPTBW' },
    { 'cardName': 'usTax', 'cardField': 'startDate', 'pendingField': 'PRIMARYTAXFROM' },
  ];

  public static readonly SSNEIN = [
    { 'code': 'SSN', 'display': 'SSN' },
    { 'code': 'EIN', 'display': 'EIN' },
  ];

  public static readonly EXEMPBW = [
    { 'code': '1', 'display': 'Yes' },
    { 'code': '2', 'display': 'No' },
  ];

  public static readonly STATUSW9 = [
    { 'code': 'I', 'display': 'Individual/Sole proprietor' },
    { 'code': 'C', 'display': 'C Corporation' },
    { 'code': 'S', 'display': 'S Corporation' },
    { 'code': 'P', 'display': 'Partnership' },
    { 'code': 'T', 'display': 'Trust/estate' },
    { 'code': 'L', 'display': 'LLC' },
    { 'code': 'O', 'display': 'Other' },
  ];


  public static readonly AUDIT_LABEL_TAX_BUSINESSTYPE = 'TAPP_LABEL_TAX_BUSINESSTYPE';
  public static readonly AUDIT_LABEL_EXEMPTBW = 'TAPP_LABEL_EXEMPTBW';


  public static readonly WRONG_USER_PWD = 'login.wrongUserPwd';
  public static readonly NO_ACTIVE_PROFILE = 'login.noActiveProfile';
  public static readonly STD_LOGIN_COUNTDOWN_ACCESS = 'login.tryAgain';
  public static readonly STD_LOGIN_COUNTDOWN_ENDED = 'login.accountLocked';

  public static readonly TAX_QST_SEPARATOR = 'TQ';
  public static readonly TAX_NOT_REGISTERED = 'NOT REGISTERED';

  public static readonly DATE_FORMAT = [
    { 'geco': 'DD/MM/YYYY', 'taProfile': 'dd/MM/yyyy' },
    { 'geco': 'MM-DD-YYYY', 'taProfile': 'MM-dd-yyyy' },
    { 'geco': 'YYYY-MM-DD', 'taProfile': 'yyyy-MM-dd' },
    { 'geco': 'YYYY/MM/DD', 'taProfile': 'yyyy/MM/dd' },
    { 'geco': 'MM/DD/YYYY', 'taProfile': 'MM/dd/yyyy' }, // No incluided in prototype but exists in GECO
  ];

  public static readonly DATE_HOUR_FORMAT = [
    { 'geco': 'DD/MM/YYYY', 'taProfile': 'dd/MM/yyyy hh:mm' },
    { 'geco': 'MM-DD-YYYY', 'taProfile': 'MM-dd-yyyy hh:mm' },
    { 'geco': 'YYYY-MM-DD', 'taProfile': 'yyyy-MM-dd hh:mm' },
    { 'geco': 'YYYY/MM/DD', 'taProfile': 'yyyy/MM/dd hh:mm' },
    { 'geco': 'MM/DD/YYYY', 'taProfile': 'MM/dd/yyyy hh:mm' }, // No incluided in prototype but exists in GECO
  ];

  public static readonly ERROR_TOKENPWD_INVALID = 'ERROR_TOKENPWD_0001';
  public static readonly ERROR_TOKENPWD_EXPIRED = 'ERROR_TOKENPWD_0002';

  // Static country list
  public static STATIC_COUNTRIES = [
    { 'code': 'AF', 'display': 'AFGHANISTAN' },
    { 'code': 'AL', 'display': 'ALBANIA' },
    { 'code': 'DZ', 'display': 'ALGERIA' },
    { 'code': 'AS', 'display': 'AMERICAN SAMOA' },
    { 'code': 'AD', 'display': 'ANDORRA' },
    { 'code': 'AO', 'display': 'ANGOLA' },
    { 'code': 'AI', 'display': 'ANGUILLA' },
    { 'code': 'AG', 'display': 'ANTIGUA' },
    { 'code': 'AR', 'display': 'ARGENTINA' },
    { 'code': 'AM', 'display': 'ARMENIA' },
    { 'code': 'AW', 'display': 'ARUBA' },
    { 'code': 'AU', 'display': 'AUSTRALIA' },
    { 'code': 'AT', 'display': 'AUSTRIA' },
    { 'code': 'AZ', 'display': 'AZERBAIJAN' },
    { 'code': 'BS', 'display': 'BAHAMAS' },
    { 'code': 'BH', 'display': 'BAHRAIN' },
    { 'code': 'BD', 'display': 'BANGLADESH' },
    { 'code': 'BB', 'display': 'BARBADOS' },
    { 'code': 'BY', 'display': 'BELARUS' },
    { 'code': 'BE', 'display': 'BELGIUM' },
    { 'code': 'BZ', 'display': 'BELIZE' },
    { 'code': 'BJ', 'display': 'BENIN' },
    { 'code': 'BM', 'display': 'BERMUDA' },
    { 'code': 'BT', 'display': 'BHUTAN' },
    { 'code': 'BO', 'display': 'BOLIVIA' },
    { 'code': 'BA', 'display': 'BOSNIA HERZEGOVINA' },
    { 'code': 'BW', 'display': 'BOTSWANA' },
    { 'code': 'BR', 'display': 'BRAZIL' },
    { 'code': 'VG', 'display': 'BRITISH VIRGIN IS.' },
    { 'code': 'BN', 'display': 'BRUNEI' },
    { 'code': 'BG', 'display': 'BULGARIA' },
    { 'code': 'BF', 'display': 'BURKINA FASO' },
    { 'code': 'BI', 'display': 'BURUNDI' },
    { 'code': 'KH', 'display': 'CAMBODIA' },
    { 'code': 'CM', 'display': 'CAMEROON' },
    { 'code': 'CA', 'display': 'CANADA' },
    { 'code': 'CV', 'display': 'CAPE VERDE' },
    { 'code': 'KY', 'display': 'CAYMAN ISLANDS' },
    { 'code': 'CF', 'display': 'CENTRAL AFRICAN REPUBLIC' },
    { 'code': 'TD', 'display': 'CHAD' },
    { 'code': 'CL', 'display': 'CHILE' },
    { 'code': 'CN', 'display': 'CHINA' },
    { 'code': 'CO', 'display': 'COLOMBIA' },
    { 'code': 'MP', 'display': 'COM.OF NORTHERN MARIANA IS' },
    { 'code': 'KM', 'display': 'COMOROS ISLANDS' },
    { 'code': 'CG', 'display': 'CONGO' },
    { 'code': 'CD', 'display': 'CONGO, DEM. REP. OF' },
    { 'code': 'CK', 'display': 'COOK ISLANDS' },
    { 'code': 'CR', 'display': 'COSTA RICA' },
    { 'code': 'CI', 'display': 'COTE D\'IVOIRE' },
    { 'code': 'HR', 'display': 'CROATIA' },
    { 'code': 'CU', 'display': 'CUBA' },
    { 'code': 'CY', 'display': 'CYPRUS' },
    { 'code': 'CZ', 'display': 'CZECH REP.' },
    { 'code': 'DK', 'display': 'DENMARK' },
    { 'code': 'DJ', 'display': 'DJIBOUTI' },
    { 'code': 'DM', 'display': 'DOMINICA' },
    { 'code': 'DO', 'display': 'DOMINICAN REPUBLIC' },
    { 'code': 'DC', 'display': 'DUTCH CARIBBEAN' },
    { 'code': 'TP', 'display': 'EAST TIMOR' },
    { 'code': 'EC', 'display': 'ECUADOR' },
    { 'code': 'EG', 'display': 'EGYPT' },
    { 'code': 'SV', 'display': 'EL SALVADOR' },
    { 'code': 'GQ', 'display': 'EQUATORIAL GUINEA' },
    { 'code': 'ER', 'display': 'ERITREA' },
    { 'code': 'EE', 'display': 'ESTONIA' },
    { 'code': 'ET', 'display': 'ETHIOPIA' },
    { 'code': 'FK', 'display': 'FALKLAND ISLANDS/MALVINAS' },
    { 'code': 'FJ', 'display': 'FIJI' },
    { 'code': 'FI', 'display': 'FINLAND' },
    { 'code': 'FR', 'display': 'FRANCE' },
    { 'code': 'GF', 'display': 'FRENCH GUIANA' },
    { 'code': 'PF', 'display': 'FRENCH POLYNESIA' },
    { 'code': 'FW', 'display': 'FRENCH WEST INDIES' },
    { 'code': 'GA', 'display': 'GABON' },
    { 'code': 'GM', 'display': 'GAMBIA' },
    { 'code': 'XA', 'display': 'GAZA' },
    { 'code': 'GE', 'display': 'GEORGIA' },
    { 'code': 'DE', 'display': 'GERMANY' },
    { 'code': 'GH', 'display': 'GHANA' },
    { 'code': 'GI', 'display': 'GIBRALTAR' },
    { 'code': 'GB', 'display': 'GREAT BRITAIN' },
    { 'code': 'GR', 'display': 'GREECE' },
    { 'code': 'GL', 'display': 'GREENLAND' },
    { 'code': 'GD', 'display': 'GRENADA' },
    { 'code': 'GP', 'display': 'GUADELOUPE' },
    { 'code': 'GU', 'display': 'GUAM' },
    { 'code': 'GT', 'display': 'GUATEMALA' },
    { 'code': 'GN', 'display': 'GUINEA' },
    { 'code': 'GW', 'display': 'GUINEA BISSAU' },
    { 'code': 'GY', 'display': 'GUYANA' },
    { 'code': 'HT', 'display': 'HAITI' },
    { 'code': 'XH', 'display': 'HELD TERRITORIES' },
    { 'code': 'HN', 'display': 'HONDURAS' },
    { 'code': 'HK', 'display': 'HONG KONG' },
    { 'code': 'HU', 'display': 'HUNGARY' },
    { 'code': 'IS', 'display': 'ICELAND' },
    { 'code': 'IN', 'display': 'INDIA' },
    { 'code': 'ID', 'display': 'INDONESIA' },
    { 'code': 'IR', 'display': 'IRAN' },
    { 'code': 'IQ', 'display': 'IRAQ' },
    { 'code': 'IE', 'display': 'IRELAND' },
    { 'code': 'IL', 'display': 'ISRAEL' },
    { 'code': 'IT', 'display': 'ITALY' },
    { 'code': 'JM', 'display': 'JAMAICA' },
    { 'code': 'JP', 'display': 'JAPAN' },
    { 'code': 'JO', 'display': 'JORDAN' },
    { 'code': 'KZ', 'display': 'KAZAKHSTAN' },
    { 'code': 'KE', 'display': 'KENYA' },
    { 'code': 'KI', 'display': 'KIRIBATI' },
    { 'code': 'KP', 'display': 'KOREA DEM.PEOPLES REPUBLIC' },
    { 'code': 'KR', 'display': 'KOREA, REPUBLIC OF' },
    { 'code': 'KW', 'display': 'KUWAIT' },
    { 'code': 'KG', 'display': 'KYRGYZSTAN' },
    { 'code': 'LA', 'display': 'LAOS' },
    { 'code': 'LV', 'display': 'LATVIA' },
    { 'code': 'LB', 'display': 'LEBANON' },
    { 'code': 'LS', 'display': 'LESOTHO' },
    { 'code': 'LN', 'display': 'LIBANO' },
    { 'code': 'LR', 'display': 'LIBERIA' },
    { 'code': 'LY', 'display': 'LIBYAN ARAB JAMAHIRIYA' },
    { 'code': 'LI', 'display': 'LIECHTENSTEIN' },
    { 'code': 'LT', 'display': 'LITHUANIA' },
    { 'code': 'LU', 'display': 'LUXEMBOURG' },
    { 'code': 'MO', 'display': 'MACAU' },
    { 'code': 'MK', 'display': 'MACEDONIA (FYROM)' },
    { 'code': 'MG', 'display': 'MADAGASCAR' },
    { 'code': 'MW', 'display': 'MALAWI' },
    { 'code': 'MY', 'display': 'MALAYSIA' },
    { 'code': 'MV', 'display': 'MALDIVES, REP. OF' },
    { 'code': 'ML', 'display': 'MALI' },
    { 'code': 'MT', 'display': 'MALTA' },
    { 'code': 'MH', 'display': 'MARSHALL ISLANDS-REPUBLIC' },
    { 'code': 'MQ', 'display': 'MARTINIQUE' },
    { 'code': 'MR', 'display': 'MAURITANIA' },
    { 'code': 'MU', 'display': 'MAURITIUS' },
    { 'code': 'YT', 'display': 'MAYOTTE' },
    { 'code': 'MX', 'display': 'MEXICO' },
    { 'code': 'FM', 'display': 'MICRONESIA-FEDERATED STATE' },
    { 'code': 'MD', 'display': 'MOLDOVA' },
    { 'code': 'MC', 'display': 'MONACO' },
    { 'code': 'MN', 'display': 'MONGOLIA' },
    { 'code': 'ME', 'display': 'MONTENEGRO' },
    { 'code': 'MS', 'display': 'MONTSERRAT' },
    { 'code': 'MA', 'display': 'MOROCCO' },
    { 'code': 'MZ', 'display': 'MOZAMBIQUE' },
    { 'code': 'MM', 'display': 'MYANMAR' },
    { 'code': 'NA', 'display': 'NAMIBIA' },
    { 'code': 'NR', 'display': 'NAURU' },
    { 'code': 'NP', 'display': 'NEPAL' },
    { 'code': 'NL', 'display': 'NETHERLANDS' },
    { 'code': 'AN', 'display': 'NETHERLANDS ANTILLES' },
    { 'code': 'NC', 'display': 'NEW CALEDONIA' },
    { 'code': 'NZ', 'display': 'NEW ZEALAND' },
    { 'code': 'NI', 'display': 'NICARAGUA' },
    { 'code': 'NE', 'display': 'NIGER' },
    { 'code': 'NG', 'display': 'NIGERIA' },
    { 'code': '99', 'display': 'NO DEFINIDO' },
    { 'code': 'XB', 'display': 'NORTHERN IRELAND' },
    { 'code': 'NO', 'display': 'NORWAY' },
    { 'code': 'OM', 'display': 'OMAN' },
    { 'code': 'PK', 'display': 'PAKISTAN' },
    { 'code': 'PS', 'display': 'PALESTINIAN TERR. OCCUPIED' },
    { 'code': 'PA', 'display': 'PANAMA' },
    { 'code': 'PG', 'display': 'PAPUA NEW GUINEA' },
    { 'code': 'PY', 'display': 'PARAGUAY' },
    { 'code': 'PE', 'display': 'PERU' },
    { 'code': 'PH', 'display': 'PHILIPPINES' },
    { 'code': 'PL', 'display': 'POLAND' },
    { 'code': 'PT', 'display': 'PORTUGAL' },
    { 'code': 'PR', 'display': 'PUERTO RICO' },
    { 'code': 'QA', 'display': 'QATAR' },
    { 'code': 'PW', 'display': 'REPUBLIC OF PALAU' },
    { 'code': 'RE', 'display': 'REUNION ISL' },
    { 'code': 'RO', 'display': 'ROMANIA' },
    { 'code': 'RU', 'display': 'RUSSIA' },
    { 'code': 'RW', 'display': 'RWANDA' },
    { 'code': 'LC', 'display': 'SAINT LUCIA' },
    { 'code': 'SM', 'display': 'SAN MARINO' },
    { 'code': 'ST', 'display': 'SAO TOME AND PRINCIPE' },
    { 'code': 'SA', 'display': 'SAUDI ARABIA' },
    { 'code': 'SN', 'display': 'SENEGAL' },
    { 'code': 'RS', 'display': 'SERBIA' },
    { 'code': 'CS', 'display': 'SERBIA AND MONTENEGRO' },
    { 'code': 'SC', 'display': 'SEYCHELLES ISL' },
    { 'code': 'SL', 'display': 'SIERRA LEONE' },
    { 'code': 'SG', 'display': 'SINGAPORE' },
    { 'code': 'SK', 'display': 'SLOVAKIA' },
    { 'code': 'SI', 'display': 'SLOVENIA' },
    { 'code': 'SB', 'display': 'SOLOMON ISLANDS' },
    { 'code': 'SO', 'display': 'SOMALIA' },
    { 'code': 'ZA', 'display': 'SOUTH AFRICA' },
    { 'code': 'ES', 'display': 'SPAIN' },
    { 'code': 'LK', 'display': 'SRI LANKA' },
    { 'code': 'SH', 'display': 'ST. BARTHOLOMEU' },
    { 'code': 'AH', 'display': 'ST. KITTS AND NEVIS' },
    { 'code': 'KN', 'display': 'ST. KITTS AND NEVIS' },
    { 'code': 'PM', 'display': 'ST. PIERRE ET MIQUELON' },
    { 'code': 'VC', 'display': 'ST. VINCENT-THE GRENADINES' },
    { 'code': 'SD', 'display': 'SUDAN' },
    { 'code': 'SR', 'display': 'SURINAME' },
    { 'code': 'SZ', 'display': 'SWAZILAND' },
    { 'code': 'SE', 'display': 'SWEDEN' },
    { 'code': 'CH', 'display': 'SWITZERLAND' },
    { 'code': 'SY', 'display': 'SYRIA' },
    { 'code': 'TA', 'display': 'TAHITI' },
    { 'code': 'TW', 'display': 'TAIWAN' },
    { 'code': 'TJ', 'display': 'TAJIKISTAN' },
    { 'code': 'TZ', 'display': 'TANZANIA' },
    { 'code': 'TH', 'display': 'THAILAND' },
    { 'code': 'TL', 'display': 'TIMOR-LESTE' },
    { 'code': 'TG', 'display': 'TOGO' },
    { 'code': 'TO', 'display': 'TONGA' },
    { 'code': 'TT', 'display': 'TRINIDAD AND TOBAGO' },
    { 'code': 'TN', 'display': 'TUNISIA' },
    { 'code': 'TR', 'display': 'TURKEY' },
    { 'code': 'TM', 'display': 'TURKMENISTAN' },
    { 'code': 'TC', 'display': 'TURKS AND CAICOS ISLANDS' },
    { 'code': 'TV', 'display': 'TUVALU' },
    { 'code': 'UG', 'display': 'UGANDA' },
    { 'code': 'UA', 'display': 'UKRAINE' },
    { 'code': 'AE', 'display': 'UNITED ARAB EMIRATES' },
    { 'code': 'US', 'display': 'UNITED STATES OF AMERICA' },
    { 'code': 'UY', 'display': 'URUGUAY' },
    { 'code': 'UP', 'display': 'U.S.A. TERR. POSSESSION' },
    { 'code': 'UZ', 'display': 'UZBEKISTAN' },
    { 'code': 'VU', 'display': 'VANUATU' },
    { 'code': 'VE', 'display': 'VENEZUELA' },
    { 'code': 'VN', 'display': 'VIET NAM' },
    { 'code': 'VI', 'display': 'VIRGIN ISLAND' },
    { 'code': 'WF', 'display': 'WALLIS & FUTUNA ISLANDS' },
    { 'code': 'WS', 'display': 'WESTERN SAMOA' },
    { 'code': 'YE', 'display': 'YEMEN' },
    { 'code': 'YU', 'display': 'YUGOSLAVIA' },
    { 'code': 'ZM', 'display': 'ZAMBIA' },
    { 'code': 'ZW', 'display': 'ZIMBABWE' },
  ];

  public static readonly AGENCY_TYPE = [
    { 'code': 'ARC', 'display': 'ARC' },
    { 'code': 'ASTA', 'display': 'ASTA' },
    { 'code': 'CLIA', 'display': 'CLIA' },
    { 'code': 'IATA', 'display': 'IATA' },
    { 'code': 'TIDS', 'display': 'TIDS' },
    { 'code': 'TRUE', 'display': 'TRUE' },
    { 'code': 'WPS', 'display': 'ONYX' },
    { 'code': 'Other', 'display': 'Other codes' },
    { 'code': 'None', 'display': 'No code' },
  ];

  // Static country and states list
  public static STATIC_COUNTRIES_AND_STATES = [
    { 'code': 'AF', 'display': 'AFGHANISTAN', 'states': [], 'OfacBlocked': false },
    { 'code': 'AL', 'display': 'ALBANIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'DZ', 'display': 'ALGERIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'AS', 'display': 'AMERICAN SAMOA', 'states': [], 'OfacBlocked': false },
    { 'code': 'AD', 'display': 'ANDORRA', 'states': [], 'OfacBlocked': false },
    { 'code': 'AO', 'display': 'ANGOLA', 'states': [], 'OfacBlocked': false },
    { 'code': 'AI', 'display': 'ANGUILLA', 'states': [], 'OfacBlocked': false },
    { 'code': 'AG', 'display': 'ANTIGUA', 'states': [], 'OfacBlocked': false },
    { 'code': 'AR', 'display': 'ARGENTINA', 'states': [], 'OfacBlocked': false },
    { 'code': 'AM', 'display': 'ARMENIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'AW', 'display': 'ARUBA', 'states': [], 'OfacBlocked': false },
    {
      'code': 'AU', 'display': 'AUSTRALIA', 'states': [
        { 'stateId': 'AC', 'stateName': 'AUSTRALIAN CAPITAL TERRITORY' },
        { 'stateId': 'CR', 'stateName': 'CREMORNE' },
        { 'stateId': 'NS', 'stateName': 'NEW SOUTH WALES' },
        { 'stateId': 'NT', 'stateName': 'NORTHERN TERRITORY' },
        { 'stateId': 'QL', 'stateName': 'QUEENSLAND' },
        { 'stateId': 'SA', 'stateName': 'SOUTH AUSTRALIA' },
        { 'stateId': 'TS', 'stateName': 'TASMANIA' },
        { 'stateId': 'VI', 'stateName': 'VICTORIA' },
        { 'stateId': 'WA', 'stateName': 'WESTERN AUSTRALIA' },
      ], 'OfacBlocked': false,
    },
    { 'code': 'AT', 'display': 'AUSTRIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'AZ', 'display': 'AZERBAIJAN', 'states': [], 'OfacBlocked': false },
    { 'code': 'BS', 'display': 'BAHAMAS', 'states': [], 'OfacBlocked': false },
    { 'code': 'BH', 'display': 'BAHRAIN', 'states': [], 'OfacBlocked': false },
    { 'code': 'BD', 'display': 'BANGLADESH', 'states': [], 'OfacBlocked': false },
    { 'code': 'BB', 'display': 'BARBADOS', 'states': [], 'OfacBlocked': false },
    { 'code': 'BY', 'display': 'BELARUS', 'states': [], 'OfacBlocked': false },
    { 'code': 'BE', 'display': 'BELGIUM', 'states': [], 'OfacBlocked': false },
    { 'code': 'BZ', 'display': 'BELIZE', 'states': [], 'OfacBlocked': false },
    { 'code': 'BJ', 'display': 'BENIN', 'states': [], 'OfacBlocked': false },
    { 'code': 'BM', 'display': 'BERMUDA', 'states': [], 'OfacBlocked': false },
    { 'code': 'BT', 'display': 'BHUTAN', 'states': [], 'OfacBlocked': false },
    { 'code': 'BO', 'display': 'BOLIVIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'BA', 'display': 'BOSNIA HERZEGOVINA', 'states': [], 'OfacBlocked': false },
    { 'code': 'BW', 'display': 'BOTSWANA', 'states': [], 'OfacBlocked': false },
    { 'code': 'BR', 'display': 'BRAZIL', 'states': [], 'OfacBlocked': false },
    { 'code': 'VG', 'display': 'BRITISH VIRGIN IS.', 'states': [], 'OfacBlocked': false },
    { 'code': 'BN', 'display': 'BRUNEI', 'states': [], 'OfacBlocked': false },
    { 'code': 'BG', 'display': 'BULGARIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'BF', 'display': 'BURKINA FASO', 'states': [], 'OfacBlocked': false },
    { 'code': 'BI', 'display': 'BURUNDI', 'states': [], 'OfacBlocked': false },
    { 'code': 'KH', 'display': 'CAMBODIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'CM', 'display': 'CAMEROON', 'states': [], 'OfacBlocked': false },
    {
      'code': 'CA', 'display': 'CANADA', 'states': [
        { 'stateId': 'AB', 'stateName': 'ALBERTA' },
        { 'stateId': 'BC', 'stateName': 'BRITISH COLUMBIA' },
        { 'stateId': 'MB', 'stateName': 'MANITOBA' },
        { 'stateId': 'NB', 'stateName': 'NEW BRUNSWICK' },
        { 'stateId': 'NL', 'stateName': 'NEWFOUNDLAND & LABRADOR' },
        { 'stateId': 'NT', 'stateName': 'NORTHWEST TERRITORIES' },
        { 'stateId': 'NS', 'stateName': 'NOVA SCOTIA' },
        { 'stateId': 'NU', 'stateName': 'NUNAVUT' },
        { 'stateId': 'ON', 'stateName': 'ONTARIO' },
        { 'stateId': 'PE', 'stateName': 'PRINCE EDWARD ISLAND' },
        { 'stateId': 'QC', 'stateName': 'QUEBEC' },
        { 'stateId': 'SK', 'stateName': 'SASKATCHEWAN' },
        { 'stateId': 'YT', 'stateName': 'YUKON TERRITORY' },
      ], 'OfacBlocked': false,
    },
    { 'code': 'CV', 'display': 'CAPE VERDE', 'states': [], 'OfacBlocked': false },
    { 'code': 'KY', 'display': 'CAYMAN ISLANDS', 'states': [], 'OfacBlocked': false },
    { 'code': 'CF', 'display': 'CENTRAL AFRICAN REPUBLIC', 'states': [], 'OfacBlocked': false },
    { 'code': 'TD', 'display': 'CHAD', 'states': [], 'OfacBlocked': false },
    { 'code': 'CL', 'display': 'CHILE', 'states': [], 'OfacBlocked': false },
    { 'code': 'CN', 'display': 'CHINA', 'states': [], 'OfacBlocked': false },
    { 'code': 'CO', 'display': 'COLOMBIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'MP', 'display': 'COM.OF NORTHERN MARIANA IS', 'states': [], 'OfacBlocked': false },
    { 'code': 'KM', 'display': 'COMOROS ISLANDS', 'states': [], 'OfacBlocked': false },
    { 'code': 'CG', 'display': 'CONGO', 'states': [], 'OfacBlocked': false },
    { 'code': 'CD', 'display': 'CONGO, DEM. REP. OF', 'states': [], 'OfacBlocked': true },
    { 'code': 'CK', 'display': 'COOK ISLANDS', 'states': [], 'OfacBlocked': false },
    { 'code': 'CR', 'display': 'COSTA RICA', 'states': [], 'OfacBlocked': false },
    { 'code': 'CI', 'display': 'COTE D\'IVOIRE', 'states': [], 'OfacBlocked': false },
    { 'code': 'HR', 'display': 'CROATIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'CU', 'display': 'CUBA', 'states': [], 'OfacBlocked': true },
    { 'code': 'CY', 'display': 'CYPRUS', 'states': [], 'OfacBlocked': false },
    { 'code': 'CZ', 'display': 'CZECH REP.', 'states': [], 'OfacBlocked': false },
    { 'code': 'DK', 'display': 'DENMARK', 'states': [], 'OfacBlocked': false },
    { 'code': 'DJ', 'display': 'DJIBOUTI', 'states': [], 'OfacBlocked': false },
    { 'code': 'DM', 'display': 'DOMINICA', 'states': [], 'OfacBlocked': false },
    { 'code': 'DO', 'display': 'DOMINICAN REPUBLIC', 'states': [], 'OfacBlocked': false },
    { 'code': 'DC', 'display': 'DUTCH CARIBBEAN', 'states': [], 'OfacBlocked': false },
    { 'code': 'TP', 'display': 'EAST TIMOR', 'states': [], 'OfacBlocked': false },
    { 'code': 'EC', 'display': 'ECUADOR', 'states': [], 'OfacBlocked': false },
    { 'code': 'EG', 'display': 'EGYPT', 'states': [], 'OfacBlocked': false },
    { 'code': 'SV', 'display': 'EL SALVADOR', 'states': [], 'OfacBlocked': false },
    { 'code': 'GQ', 'display': 'EQUATORIAL GUINEA', 'states': [], 'OfacBlocked': false },
    { 'code': 'ER', 'display': 'ERITREA', 'states': [], 'OfacBlocked': false },
    { 'code': 'EE', 'display': 'ESTONIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'ET', 'display': 'ETHIOPIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'FK', 'display': 'FALKLAND ISLANDS/MALVINAS', 'states': [], 'OfacBlocked': false },
    { 'code': 'FJ', 'display': 'FIJI', 'states': [], 'OfacBlocked': false },
    { 'code': 'FI', 'display': 'FINLAND', 'states': [], 'OfacBlocked': false },
    { 'code': 'FR', 'display': 'FRANCE', 'states': [], 'OfacBlocked': false },
    { 'code': 'GF', 'display': 'FRENCH GUIANA', 'states': [], 'OfacBlocked': false },
    { 'code': 'PF', 'display': 'FRENCH POLYNESIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'FW', 'display': 'FRENCH WEST INDIES', 'states': [], 'OfacBlocked': false },
    { 'code': 'GA', 'display': 'GABON', 'states': [], 'OfacBlocked': false },
    { 'code': 'GM', 'display': 'GAMBIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'XA', 'display': 'GAZA', 'states': [], 'OfacBlocked': false },
    { 'code': 'GE', 'display': 'GEORGIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'DE', 'display': 'GERMANY', 'states': [], 'OfacBlocked': false },
    { 'code': 'GH', 'display': 'GHANA', 'states': [], 'OfacBlocked': false },
    { 'code': 'GI', 'display': 'GIBRALTAR', 'states': [], 'OfacBlocked': false },
    { 'code': 'GB', 'display': 'GREAT BRITAIN', 'states': [], 'OfacBlocked': false },
    { 'code': 'GR', 'display': 'GREECE', 'states': [], 'OfacBlocked': false },
    { 'code': 'GL', 'display': 'GREENLAND', 'states': [], 'OfacBlocked': false },
    { 'code': 'GD', 'display': 'GRENADA', 'states': [], 'OfacBlocked': false },
    { 'code': 'GP', 'display': 'GUADELOUPE', 'states': [], 'OfacBlocked': false },
    { 'code': 'GU', 'display': 'GUAM', 'states': [], 'OfacBlocked': false },
    { 'code': 'GT', 'display': 'GUATEMALA', 'states': [], 'OfacBlocked': false },
    { 'code': 'GN', 'display': 'GUINEA', 'states': [], 'OfacBlocked': false },
    { 'code': 'GW', 'display': 'GUINEA BISSAU', 'states': [], 'OfacBlocked': false },
    { 'code': 'GY', 'display': 'GUYANA', 'states': [], 'OfacBlocked': false },
    { 'code': 'HT', 'display': 'HAITI', 'states': [], 'OfacBlocked': false },
    { 'code': 'XH', 'display': 'HELD TERRITORIES', 'states': [], 'OfacBlocked': false },
    { 'code': 'HN', 'display': 'HONDURAS', 'states': [], 'OfacBlocked': false },
    { 'code': 'HK', 'display': 'HONG KONG', 'states': [], 'OfacBlocked': false },
    { 'code': 'HU', 'display': 'HUNGARY', 'states': [], 'OfacBlocked': false },
    { 'code': 'IS', 'display': 'ICELAND', 'states': [], 'OfacBlocked': false },
    { 'code': 'IN', 'display': 'INDIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'ID', 'display': 'INDONESIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'IR', 'display': 'IRAN', 'states': [], 'OfacBlocked': true },
    { 'code': 'IQ', 'display': 'IRAQ', 'states': [], 'OfacBlocked': false },
    { 'code': 'IE', 'display': 'IRELAND', 'states': [], 'OfacBlocked': false },
    { 'code': 'IL', 'display': 'ISRAEL', 'states': [], 'OfacBlocked': false },
    { 'code': 'IT', 'display': 'ITALY', 'states': [], 'OfacBlocked': false },
    { 'code': 'JM', 'display': 'JAMAICA', 'states': [], 'OfacBlocked': false },
    { 'code': 'JP', 'display': 'JAPAN', 'states': [], 'OfacBlocked': false },
    { 'code': 'JO', 'display': 'JORDAN', 'states': [], 'OfacBlocked': false },
    { 'code': 'KZ', 'display': 'KAZAKHSTAN', 'states': [], 'OfacBlocked': false },
    { 'code': 'KE', 'display': 'KENYA', 'states': [], 'OfacBlocked': false },
    { 'code': 'KI', 'display': 'KIRIBATI', 'states': [], 'OfacBlocked': false },
    { 'code': 'KP', 'display': 'KOREA DEM.PEOPLES REPUBLIC', 'states': [], 'OfacBlocked': true },
    { 'code': 'KR', 'display': 'KOREA, REPUBLIC OF', 'states': [], 'OfacBlocked': false },
    { 'code': 'KW', 'display': 'KUWAIT', 'states': [], 'OfacBlocked': false },
    { 'code': 'KG', 'display': 'KYRGYZSTAN', 'states': [], 'OfacBlocked': false },
    { 'code': 'LA', 'display': 'LAOS', 'states': [], 'OfacBlocked': false },
    { 'code': 'LV', 'display': 'LATVIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'LB', 'display': 'LEBANON', 'states': [], 'OfacBlocked': false },
    { 'code': 'LS', 'display': 'LESOTHO', 'states': [], 'OfacBlocked': false },
    { 'code': 'LN', 'display': 'LIBANO', 'states': [], 'OfacBlocked': false },
    { 'code': 'LR', 'display': 'LIBERIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'LY', 'display': 'LIBYAN ARAB JAMAHIRIYA', 'states': [], 'OfacBlocked': false },
    { 'code': 'LI', 'display': 'LIECHTENSTEIN', 'states': [], 'OfacBlocked': false },
    { 'code': 'LT', 'display': 'LITHUANIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'LU', 'display': 'LUXEMBOURG', 'states': [], 'OfacBlocked': false },
    { 'code': 'MO', 'display': 'MACAU', 'states': [], 'OfacBlocked': false },
    { 'code': 'MK', 'display': 'MACEDONIA (FYROM)', 'states': [], 'OfacBlocked': false },
    { 'code': 'MG', 'display': 'MADAGASCAR', 'states': [], 'OfacBlocked': false },
    { 'code': 'MW', 'display': 'MALAWI', 'states': [], 'OfacBlocked': false },
    { 'code': 'MY', 'display': 'MALAYSIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'MV', 'display': 'MALDIVES, REP. OF', 'states': [], 'OfacBlocked': false },
    { 'code': 'ML', 'display': 'MALI', 'states': [], 'OfacBlocked': false },
    { 'code': 'MT', 'display': 'MALTA', 'states': [], 'OfacBlocked': false },
    { 'code': 'MH', 'display': 'MARSHALL ISLANDS-REPUBLIC', 'states': [], 'OfacBlocked': false },
    { 'code': 'MQ', 'display': 'MARTINIQUE', 'states': [], 'OfacBlocked': false },
    { 'code': 'MR', 'display': 'MAURITANIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'MU', 'display': 'MAURITIUS', 'states': [], 'OfacBlocked': false },
    { 'code': 'YT', 'display': 'MAYOTTE', 'states': [], 'OfacBlocked': false },
    { 'code': 'MX', 'display': 'MEXICO', 'states': [], 'OfacBlocked': false },
    { 'code': 'FM', 'display': 'MICRONESIA-FEDERATED STATE', 'states': [], 'OfacBlocked': false },
    { 'code': 'MD', 'display': 'MOLDOVA', 'states': [], 'OfacBlocked': false },
    { 'code': 'MC', 'display': 'MONACO', 'states': [], 'OfacBlocked': false },
    { 'code': 'MN', 'display': 'MONGOLIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'ME', 'display': 'MONTENEGRO', 'states': [], 'OfacBlocked': false },
    { 'code': 'MS', 'display': 'MONTSERRAT', 'states': [], 'OfacBlocked': false },
    { 'code': 'MA', 'display': 'MOROCCO', 'states': [], 'OfacBlocked': false },
    { 'code': 'MZ', 'display': 'MOZAMBIQUE', 'states': [], 'OfacBlocked': false },
    { 'code': 'MM', 'display': 'MYANMAR', 'states': [], 'OfacBlocked': false },
    { 'code': 'NA', 'display': 'NAMIBIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'NR', 'display': 'NAURU', 'states': [], 'OfacBlocked': false },
    { 'code': 'NP', 'display': 'NEPAL', 'states': [], 'OfacBlocked': false },
    { 'code': 'NL', 'display': 'NETHERLANDS', 'states': [], 'OfacBlocked': false },
    { 'code': 'AN', 'display': 'NETHERLANDS ANTILLES', 'states': [], 'OfacBlocked': false },
    { 'code': 'NC', 'display': 'NEW CALEDONIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'NZ', 'display': 'NEW ZEALAND', 'states': [], 'OfacBlocked': false },
    { 'code': 'NI', 'display': 'NICARAGUA', 'states': [], 'OfacBlocked': false },
    { 'code': 'NE', 'display': 'NIGER', 'states': [], 'OfacBlocked': false },
    { 'code': 'NG', 'display': 'NIGERIA', 'states': [], 'OfacBlocked': false },
    { 'code': '99', 'display': 'NO DEFINIDO', 'states': [], 'OfacBlocked': false },
    { 'code': 'XB', 'display': 'NORTHERN IRELAND', 'states': [], 'OfacBlocked': false },
    { 'code': 'NO', 'display': 'NORWAY', 'states': [], 'OfacBlocked': false },
    { 'code': 'OM', 'display': 'OMAN', 'states': [], 'OfacBlocked': false },
    { 'code': 'PK', 'display': 'PAKISTAN', 'states': [], 'OfacBlocked': false },
    { 'code': 'PS', 'display': 'PALESTINIAN TERR. OCCUPIED', 'states': [], 'OfacBlocked': false },
    { 'code': 'PA', 'display': 'PANAMA', 'states': [], 'OfacBlocked': false },
    { 'code': 'PG', 'display': 'PAPUA NEW GUINEA', 'states': [], 'OfacBlocked': false },
    { 'code': 'PY', 'display': 'PARAGUAY', 'states': [], 'OfacBlocked': false },
    { 'code': 'PE', 'display': 'PERU', 'states': [], 'OfacBlocked': false },
    { 'code': 'PH', 'display': 'PHILIPPINES', 'states': [], 'OfacBlocked': false },
    { 'code': 'PL', 'display': 'POLAND', 'states': [], 'OfacBlocked': false },
    { 'code': 'PT', 'display': 'PORTUGAL', 'states': [], 'OfacBlocked': false },
    { 'code': 'PR', 'display': 'PUERTO RICO', 'states': [], 'OfacBlocked': false },
    { 'code': 'QA', 'display': 'QATAR', 'states': [], 'OfacBlocked': false },
    { 'code': 'PW', 'display': 'REPUBLIC OF PALAU', 'states': [], 'OfacBlocked': false },
    { 'code': 'RE', 'display': 'REUNION ISL', 'states': [], 'OfacBlocked': false },
    { 'code': 'RO', 'display': 'ROMANIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'RU', 'display': 'RUSSIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'RW', 'display': 'RWANDA', 'states': [], 'OfacBlocked': false },
    { 'code': 'LC', 'display': 'SAINT LUCIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'SM', 'display': 'SAN MARINO', 'states': [], 'OfacBlocked': false },
    { 'code': 'ST', 'display': 'SAO TOME AND PRINCIPE', 'states': [], 'OfacBlocked': false },
    { 'code': 'SA', 'display': 'SAUDI ARABIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'SN', 'display': 'SENEGAL', 'states': [], 'OfacBlocked': false },
    { 'code': 'RS', 'display': 'SERBIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'CS', 'display': 'SERBIA AND MONTENEGRO', 'states': [], 'OfacBlocked': false },
    { 'code': 'SC', 'display': 'SEYCHELLES ISL', 'states': [], 'OfacBlocked': false },
    { 'code': 'SL', 'display': 'SIERRA LEONE', 'states': [], 'OfacBlocked': false },
    { 'code': 'SG', 'display': 'SINGAPORE', 'states': [], 'OfacBlocked': false },
    { 'code': 'SK', 'display': 'SLOVAKIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'SI', 'display': 'SLOVENIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'SB', 'display': 'SOLOMON ISLANDS', 'states': [], 'OfacBlocked': false },
    { 'code': 'SO', 'display': 'SOMALIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'ZA', 'display': 'SOUTH AFRICA', 'states': [], 'OfacBlocked': false },
    { 'code': 'ES', 'display': 'SPAIN', 'states': [], 'OfacBlocked': false },
    { 'code': 'LK', 'display': 'SRI LANKA', 'states': [], 'OfacBlocked': false },
    { 'code': 'SH', 'display': 'ST. BARTHOLOMEU', 'states': [], 'OfacBlocked': false },
    { 'code': 'AH', 'display': 'ST. KITTS AND NEVIS', 'states': [], 'OfacBlocked': false },
    { 'code': 'KN', 'display': 'ST. KITTS AND NEVIS', 'states': [], 'OfacBlocked': false },
    { 'code': 'PM', 'display': 'ST. PIERRE ET MIQUELON', 'states': [], 'OfacBlocked': false },
    { 'code': 'VC', 'display': 'ST. VINCENT-THE GRENADINES', 'states': [], 'OfacBlocked': false },
    { 'code': 'SD', 'display': 'SUDAN', 'states': [], 'OfacBlocked': true },
    { 'code': 'SR', 'display': 'SURINAME', 'states': [], 'OfacBlocked': false },
    { 'code': 'SZ', 'display': 'SWAZILAND', 'states': [], 'OfacBlocked': false },
    { 'code': 'SE', 'display': 'SWEDEN', 'states': [], 'OfacBlocked': false },
    { 'code': 'CH', 'display': 'SWITZERLAND', 'states': [], 'OfacBlocked': false },
    { 'code': 'SY', 'display': 'SYRIA', 'states': [], 'OfacBlocked': true },
    { 'code': 'TA', 'display': 'TAHITI', 'states': [], 'OfacBlocked': false },
    { 'code': 'TW', 'display': 'TAIWAN', 'states': [], 'OfacBlocked': false },
    { 'code': 'TJ', 'display': 'TAJIKISTAN', 'states': [], 'OfacBlocked': false },
    { 'code': 'TZ', 'display': 'TANZANIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'TH', 'display': 'THAILAND', 'states': [], 'OfacBlocked': false },
    { 'code': 'TL', 'display': 'TIMOR-LESTE', 'states': [], 'OfacBlocked': false },
    { 'code': 'TG', 'display': 'TOGO', 'states': [], 'OfacBlocked': false },
    { 'code': 'TO', 'display': 'TONGA', 'states': [], 'OfacBlocked': false },
    { 'code': 'TT', 'display': 'TRINIDAD AND TOBAGO', 'states': [], 'OfacBlocked': false },
    { 'code': 'TN', 'display': 'TUNISIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'TR', 'display': 'TURKEY', 'states': [], 'OfacBlocked': false },
    { 'code': 'TM', 'display': 'TURKMENISTAN', 'states': [], 'OfacBlocked': false },
    { 'code': 'TC', 'display': 'TURKS AND CAICOS ISLANDS', 'states': [], 'OfacBlocked': false },
    { 'code': 'TV', 'display': 'TUVALU', 'states': [], 'OfacBlocked': false },
    { 'code': 'UG', 'display': 'UGANDA', 'states': [], 'OfacBlocked': false },
    { 'code': 'UA', 'display': 'UKRAINE', 'states': [], 'OfacBlocked': true },
    { 'code': 'AE', 'display': 'UNITED ARAB EMIRATES', 'states': [], 'OfacBlocked': false },
    {
      'code': 'US', 'display': 'UNITED STATES OF AMERICA', 'states': [
        { 'stateId': 'AL', 'stateName': 'ALABAMA' },
        { 'stateId': 'AK', 'stateName': 'ALASKA' },
        { 'stateId': 'AS', 'stateName': 'AMERICAN SAMOA' },
        { 'stateId': 'AZ', 'stateName': 'ARIZONA' },
        { 'stateId': 'AR', 'stateName': 'ARKANSAS' },
        { 'stateId': 'AE', 'stateName': 'ARMED FORCES (EUROPE, CANADA, MIDDLE' },
        { 'stateId': 'AP', 'stateName': 'ARMED FORCES PACIFIC' },
        { 'stateId': 'CA', 'stateName': 'CALIFORNIA' },
        { 'stateId': 'CO', 'stateName': 'COLORADO' },
        { 'stateId': 'CT', 'stateName': 'CONNECTICUT' },
        { 'stateId': 'DE', 'stateName': 'DELAWARE' },
        { 'stateId': 'DC', 'stateName': 'DISTRICT OF COLUMBIA' },
        { 'stateId': 'FM', 'stateName': 'FEDERATED STATES OF MICRONESIA' },
        { 'stateId': 'FL', 'stateName': 'FLORIDA' },
        { 'stateId': 'GA', 'stateName': 'GEORGIA' },
        { 'stateId': 'GU', 'stateName': 'GUAM' },
        { 'stateId': 'HI', 'stateName': 'HAWAII' },
        { 'stateId': 'ID', 'stateName': 'IDAHO' },
        { 'stateId': 'IL', 'stateName': 'ILLINOIS' },
        { 'stateId': 'IN', 'stateName': 'INDIANA' },
        { 'stateId': 'IA', 'stateName': 'IOWA' },
        { 'stateId': 'KS', 'stateName': 'KANSAS' },
        { 'stateId': 'KY', 'stateName': 'KENTUCKY' },
        { 'stateId': 'LA', 'stateName': 'LOUISIANA' },
        { 'stateId': 'ME', 'stateName': 'MAINE' },
        { 'stateId': 'MH', 'stateName': 'MARSHALL ISLANDS' },
        { 'stateId': 'MD', 'stateName': 'MARYLAND' },
        { 'stateId': 'MA', 'stateName': 'MASSACHUSETTS' },
        { 'stateId': 'MI', 'stateName': 'MICHIGAN' },
        { 'stateId': 'MN', 'stateName': 'MINNESOTA' },
        { 'stateId': 'MS', 'stateName': 'MISSISSIPPI' },
        { 'stateId': 'MO', 'stateName': 'MISSOURI' },
        { 'stateId': 'MT', 'stateName': 'MONTANA' },
        { 'stateId': 'NE', 'stateName': 'NEBRASKA' },
        { 'stateId': 'NV', 'stateName': 'NEVADA' },
        { 'stateId': 'NH', 'stateName': 'NEW HAMPSHIRE' },
        { 'stateId': 'NJ', 'stateName': 'NEW JERSEY' },
        { 'stateId': 'NM', 'stateName': 'NEW MEXICO' },
        { 'stateId': 'NY', 'stateName': 'NEW YORK' },
        { 'stateId': 'NC', 'stateName': 'NORTH CAROLINA' },
        { 'stateId': 'ND', 'stateName': 'NORTH DAKOTA' },
        { 'stateId': 'MP', 'stateName': 'NORTHERN MARIANA ISLANDS' },
        { 'stateId': 'OH', 'stateName': 'OHIO' },
        { 'stateId': 'OK', 'stateName': 'OKLAHOMA' },
        { 'stateId': 'OR', 'stateName': 'OREGON' },
        { 'stateId': 'PW', 'stateName': 'PALAU' },
        { 'stateId': 'PA', 'stateName': 'PENNSYLVANIA' },
        { 'stateId': 'PR', 'stateName': 'PUERTO RICO' },
        { 'stateId': 'RI', 'stateName': 'RHODE ISLAND' },
        { 'stateId': 'SC', 'stateName': 'SOUTH CAROLINA' },
        { 'stateId': 'SD', 'stateName': 'SOUTH DAKOTA' },
        { 'stateId': 'TN', 'stateName': 'TENNESSEE' },
        { 'stateId': 'TX', 'stateName': 'TEXAS' },
        { 'stateId': 'UT', 'stateName': 'UTAH' },
        { 'stateId': 'VT', 'stateName': 'VERMONT' },
        { 'stateId': 'VI', 'stateName': 'VIRGIN ISLANDS' },
        { 'stateId': 'VA', 'stateName': 'VIRGINIA' },
        { 'stateId': 'WA', 'stateName': 'WASHINGTON' },
        { 'stateId': 'WV', 'stateName': 'WEST VIRGINIA' },
        { 'stateId': 'WI', 'stateName': 'WISCONSIN' },
        { 'stateId': 'WY', 'stateName': 'WYOMING' },
      ], 'OfacBlocked': false,
    },
    { 'code': 'UY', 'display': 'URUGUAY', 'states': [], 'OfacBlocked': false },
    { 'code': 'UP', 'display': 'U.S.A. TERR. POSSESSION', 'states': [], 'OfacBlocked': false },
    { 'code': 'UZ', 'display': 'UZBEKISTAN', 'states': [], 'OfacBlocked': false },
    { 'code': 'VU', 'display': 'VANUATU', 'states': [], 'OfacBlocked': false },
    { 'code': 'VE', 'display': 'VENEZUELA', 'states': [], 'OfacBlocked': false },
    { 'code': 'VN', 'display': 'VIET NAM', 'states': [], 'OfacBlocked': false },
    { 'code': 'VI', 'display': 'VIRGIN ISLAND', 'states': [], 'OfacBlocked': false },
    { 'code': 'WF', 'display': 'WALLIS & FUTUNA ISLANDS', 'states': [], 'OfacBlocked': false },
    { 'code': 'WS', 'display': 'WESTERN SAMOA', 'states': [], 'OfacBlocked': false },
    { 'code': 'YE', 'display': 'YEMEN', 'states': [], 'OfacBlocked': false },
    { 'code': 'YU', 'display': 'YUGOSLAVIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'ZM', 'display': 'ZAMBIA', 'states': [], 'OfacBlocked': false },
    { 'code': 'ZW', 'display': 'ZIMBABWE', 'states': [], 'OfacBlocked': false },
  ];

  public static readonly AGENCY_GROUP_NO_IDENTIFIED = 'E';
  public static readonly AGENCY_GROUP_ASSOCIATED_THIS_TAX = 'Y';
  public static readonly AGENCY_GROUP_PENDING = 'P';
  public static readonly AGENCY_GROUP_NO_ASSOCIATED_OTHER_TAX = 'N';

  public static readonly SETTINGS_DATE_FORMAT_DEFAULT_GECO = 'DD/MM/YYYY';
  public static readonly SETTINGS_DATE_FORMAT_EXAMPLE = '2019-09-16';

  public static readonly VCC_ALLOWED_COUNTRY_LIST = [
    { 'country': 'US' },
    { 'country': 'CA' },
    { 'country': 'NZ' },
    { 'country': 'AU' },
  ];

  public static readonly USER_GUIDE_NAME = 'Agency_Profile_Portal_User_Guide.pdf';

}
