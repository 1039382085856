import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserInfoResolver } from '@core/resolvers';
import { AuthGuard } from '../../auth/services/auth.guard';
import { ProfileHistoryAuditResolver } from '../../core/resolvers/profile-history-audit.resolver';
import { ProfileHistoryComponent } from './profile-history.component';

const routes: Routes = [
  {
    path: 'profile-history',
    component: ProfileHistoryComponent,
    canActivate: [AuthGuard],
    resolve: {
      userInfo: UserInfoResolver,
      profileHistoryAudit: ProfileHistoryAuditResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProfileHistoryRoutingModule { }
