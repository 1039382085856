import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { AccessPwdComponent } from './components/access-pwd/access-pwd.component';
import { NewAccessComponent } from './components/access-pwd/new-access/new-access.component';
import { PasswordForgotComponent } from './components/access-pwd/password-forgot/password-forgot.component';
import { PasswordResetComponent } from './components/access-pwd/password-reset/password-reset.component';
import { AccessRequestComponent } from './components/access-request/access-request.component';
import { LoginSsoComponent } from './components/login-sso/login-sso.component';
import { ConnectCardComponent } from './components/login/connect-card/connect-card.component';
import { LoginFormComponent } from './components/login/login-form/login-form.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './services/auth.guard';
import { MaintAppModule } from './components/maint/maint-app.module';
import { RedirectComponent } from './components/redirect/redirect.component';
import { ConfirmRegistrationComponent } from './components/confirm-registration/confirm-registration.component';

@NgModule({
  imports: [
    SharedModule,
    AuthRoutingModule,
    MaintAppModule,
  ],
  declarations: [
    LoginComponent,
    LoginFormComponent,
    ConnectCardComponent,
    AccessPwdComponent,
    PasswordForgotComponent,
    NewAccessComponent,
    AccessRequestComponent,
    PasswordResetComponent,
    LoginSsoComponent,
    RedirectComponent,
    ConfirmRegistrationComponent,
  ],
  exports: [
    LoginComponent,
    LoginFormComponent,
    ConnectCardComponent,
    SharedModule,
  ],
  providers: [
    AuthGuard,
  ],
})
export class AuthModule { }
