import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpNoCacheInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let request: HttpRequest<any>;

    if (req.method === 'GET') {
      request = req.clone({
        headers: this.addNoCacheHeaders(req.headers),
      });
    } else {
      request = req.clone();
    }
    return next.handle(request);
  }

  private addNoCacheHeaders(headers: HttpHeaders): HttpHeaders {
    headers = headers.append('Cache-Control', 'no-cache');
    headers = headers.append('Pragma', 'no-cache');
    return headers;
  }

}
