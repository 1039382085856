import { Component, OnInit } from '@angular/core';
import { Constants } from '@app/app.constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ta-maint-root',
  templateUrl: './maint-app.component.html',
})
export class MaintAppComponent implements OnInit {

  customerSupportUrl: string = Constants.URL_CUSTOMER_SUPPORT;

  constructor(
    private translate: TranslateService
  ) {
    this.translate.setDefaultLang('en');
  }

  ngOnInit() { }

}
