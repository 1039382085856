<div class="card-header">
  <div class="row">
    <div class="col-lg-7">
      <h2 class="card-title">{{title}}</h2>
    </div>
    <div class="col-lg-5 text-lg-right">
      <button class="btn btn-sm btn-secondary text-nowrap" (click)="openModal()" data-toggle="modal" [disabled]="disableUpdateButton"  [title]="updateButtonTooltipText">
        <i class="far fa-pencil"></i>
        {{'common.updateBtn' | translate}}
      </button>
    </div>
  </div>
</div>
<div class="card-body">
  <ng-container *ngIf="!isAddressEmpty; else emptyCard" class="form-text">
    <ta-address-block [address]="address"></ta-address-block>
  </ng-container>
  <ng-template #emptyCard>
    <ta-empty-card [card]="type" [newInfo]="newInfo"></ta-empty-card>
  </ng-template>
</div>