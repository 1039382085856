import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/auth/services/auth.service';
import { TaprofileUtilsService } from '@core/services';
import * as jwt_decode from 'jwt-decode';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'ta-confirm-registration',
  template: '',
})
export class ConfirmRegistrationComponent implements OnInit, OnDestroy {

  subscription: Subscription;

  eMailToConfirm = '';

  constructor(
    private taProfileUtilService: TaprofileUtilsService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) { }

  ngOnInit() {

    this.authService.setRegistrationPage(true);

    this.subscription = new Subscription();

    this.subscription.add(
      this.route.queryParams
        .pipe(filter(params => params.jwt))
        .subscribe(params => {

          try {
            const authorization = params['jwt'];
            const decoded = jwt_decode(authorization);
            this.eMailToConfirm = decoded.basic_info.email;
            this.validateRegistration(authorization);
          } catch {
            this.router.navigate(['/pwd-messages'], { queryParams: { pwdMessageOrigin: 'EmailConfirmation' + 'Error01' } });
          }
        }));

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  validateRegistration(authorization: string) {
    this.subscription.add(
      this.taProfileUtilService.validateRegistration(authorization).subscribe(response => {
        let responseMessage: string;
        responseMessage = 'EmailConfirmation';
        switch (response) {
          case 'CONFIRMED':
            responseMessage = responseMessage + 'Confirmed';
            break;
          case 'REJECTED':
            responseMessage = responseMessage + 'Rejected';
            break;
          case 'ALREADY_CONFIRMED':
            responseMessage = responseMessage + 'AlreadyConfirmed';
            break;
          case 'ERROR01':
            responseMessage = responseMessage + 'Error01';
            break;
          case 'ERROR02':
            responseMessage = responseMessage + 'Error02';
            break;
          default:
            responseMessage = responseMessage + 'Error02';
            break;
        }
        this.router.navigate(['/pwd-messages'], { queryParams: { pwdMessageOrigin: responseMessage, username: this.eMailToConfirm } });
      }, error =>  { this.router.navigate(['/pwd-messages'], { queryParams: { pwdMessageOrigin: 'EmailConfirmation' + 'Error01' } });  }));
  }
}
