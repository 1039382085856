<ods-alert-inline *ngIf="showMessage" type="info">
  {{'maintenance.alert' | translate}}
</ods-alert-inline>

<div class="marquee">
  <i class="far fa-file-exclamation marquee-icon"></i>
  <h1 class="marquee-heading">{{'maintenance.header' | translate}}</h1>
  <h2 class="marquee-message">{{'maintenance.message' | translate}}</h2>
  <a [href]="customerSupportUrl" class="btn btn-primary mb-5"
    target="_blank">{{'contact.support.button' | translate}}</a>
</div>
