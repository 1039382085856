<div class="card">
  <form [formGroup]="pwdForgotForm" novalidate>
    <div class="card-header">
      <h2 class="card-title" [textContent]="'forgotPwd.title' | translate"></h2>
    </div>
    <div class="card-body">
      <div class="form-group">
        <label class="h4 font-weight-bold" [textContent]="'forgotPwd.header' | translate"></label>
        <p class="form-text" [textContent]="'forgotPwd.headerIfExistsEmail' | translate"></p>
        <p class="form-text"
          [innerHTML]="'forgotPwd.headerIfDontHaveAccount' | translate| replace: {url: '#/auth/access-request'}"></p>
      </div>

      <div class="form-group" [class.form-group-invalid]="isInvalid(email)">
        <label class="h4 font-weight-bold" [textContent]="'login.userId' | translate"></label>
        <input type="text" class="form-control" [class.is-invalid]="isInvalid(email)" formControlName="email"
          placeholder="{{'login.emailPlaceHolder' | translate}}" required taUpperCase>
        <div class="invalid-feedback">
          <span>{{'login.invalidEmail' | translate}}</span>
        </div>
        <p class="form-text"
          [innerHTML]="'forgotPwd.forgotUsername' | translate| replace: {eMailContactCustomerService: onyxCustomerSupportEmail}">
        </p>
      </div>

      <div class="mt-4 mb-4">
        <re-captcha (resolved)="resolved($event)"></re-captcha>
      </div>

      <button class="btn btn-block btn-primary" (click)="resetPwdSendEmail()"
        [disabled]="pwdForgotForm.invalid || !isValidCaptcha"
        [textContent]="'forgotPwd.resetPswButton' | translate"></button>
    </div>
  </form>
</div>