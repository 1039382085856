import { PlatformLocation } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from '@app/app.constants';
import { AuthService } from '@app/auth/services/auth.service';
import { MessagesUtilsService, SpinnerUtilsService, TaprofileUtilsService, TitleService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';


@Component({
  selector: 'ta-password-reset',
  templateUrl: './password-reset.component.html',
})
export class PasswordResetComponent implements OnInit, OnDestroy {


  isLoggedIn$: Observable<boolean>;
  isPwdExpired$: Observable<boolean>;
  expireMonths$: Observable<number>;
  subscription: Subscription;

  language = '';
  appKey = '';
  emailToken = '';
  username = '';
  id = '';
  expirationMonths = '';


  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private taProfileUtilService: TaprofileUtilsService,
    private spinnerService: SpinnerUtilsService,
    private titleService: TitleService,
    private translateService: TranslateService,
    private location: PlatformLocation,
    private messageService: MessagesUtilsService
  ) {
    location.onPopState(() => {
      localStorage.removeItem(Constants.TOKEN_KEY);
    });
  }

  ngOnInit() {


    this.subscription = new Subscription();
    this.isLoggedIn$ = this.authService.isLoggedIn;
    this.isPwdExpired$ = this.authService.isPwdExpired;
    this.expireMonths$ = this.authService.expireMonths;

    this.subscription.add(
      this.isPwdExpired$.subscribe(value => {
        if (value) {
          const errorCode = 'ERROR_PWD_EXPIRED';
          this.expireMonths$.subscribe(months => {
            this.expirationMonths = months.toString();
          });

          this.messageService.addMessage({
            type: 'warning',
            icon: '',
            code: errorCode,
            errorObject: null,
            extraHtml: this.expirationMonths,
          });
        }
      }));

    this.subscription.add(
      this.route.queryParams.subscribe(params => {
        if (params.pLanguage_in) {
          if (Constants.LANGUAGE_OPTIONS.find(lang => lang.gecoCode === params.pLanguage_in)) {
            this.language = Constants.LANGUAGE_OPTIONS.find(lang => lang.gecoCode === params.pLanguage_in).code;
          } else {
            this.language = 'en';
          }
          localStorage.setItem(Constants.LANG_KEY, this.language);
          this.translateService.use(this.language);
        }

        this.spinnerService.setMessage('spinner.default');
        this.titleService.setPageTitle('pwdReset.title');
        this.subscription.add(
          this.authService.isLoggedIn.pipe(take(1)).subscribe(value => {
            if (!value) {
              this.validateToken();
            }
            this.authService.setLoggedIn(false);
          })
        );
      }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  validateToken() {
    this.subscription.add(
      this.route.queryParams.subscribe(params => {
        if (params.pAppKey_in) {
          this.appKey = params.pAppKey_in;
        }
        if (params.pToken_in) {
          this.emailToken = params.pToken_in;
        }
        this.taProfileUtilService.validateRecoveryPasswordToken(this.appKey, this.emailToken).subscribe(response => {
          if (response.successful && response.output) {
            if (response.output['username']) {
              this.username = response.output['username'];
            }
            if (response.output['id']) {
              this.id = response.output['id'];
            }
          } else {
            if (response.output['username']) {
              this.username = response.output['username'];
            }

            let messageError: string;
            switch (response.error['message']) {
              case Constants.ERROR_TOKENPWD_EXPIRED:
                messageError = 'PwdMsgExpiredToken';
                break;
              default:
                messageError = 'PwdMsgInvalidToken';
                break;
            }
            this.router.navigate(['/pwd-messages'], { queryParams: { pwdMessageOrigin: messageError, username: this.username } });
          }
        });
      }));
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([uri]);
    });
  }

}
