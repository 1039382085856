import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '@app/app.constants';
import { AgencyGroupInfo } from '@core/models/agency-group-info.model';
import { AgencyGroup } from '@core/models/agency-group.model';
import { MessagesUtilsService } from '@core/services/messages-utils.service';
import { SpinnerUtilsService } from '@core/services/spinner-utils.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GecoErrorMessage } from '@core/models/geco/geco-error-message.model';

const requestOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})

export class AgencyGroupUtilsService {

  private agencyGroupList$: BehaviorSubject<AgencyGroup[]> = new BehaviorSubject<AgencyGroup[]>([]);

  constructor(
    private httpClient: HttpClient,
    private messageService: MessagesUtilsService,
    private spinnerService: SpinnerUtilsService
  ) { }

  loadProfileHistoryAudit(): Observable<AgencyGroupInfo> {
    return this.getAgencyGroupList();
  }

  getAgencyGroupList(): Observable<AgencyGroupInfo> {
    this.spinnerService.setMessage('spinner.loadingAgencies');
    return this.httpClient.get<AgencyGroupInfo>(Constants.URL_AGENCY_GROUP_RETRIEVE, requestOptions).pipe(
      tap((response: AgencyGroupInfo) => {
        this.agencyGroupList$.next(response.list);
        return response.list;
      }));
  }

  get agencyGroupList(): Observable<AgencyGroup[]> {
    return this.agencyGroupList$.asObservable();
  }

  addAgencyList(taCodeList: string) {
    const newAgencyList = {
      agencies: taCodeList,
    };

    this.spinnerService.setMessage('spinner.savingAgencies');
    return this.httpClient.post<AgencyGroupInfo>(Constants.URL_AGENCY_GROUP_ADD, newAgencyList, requestOptions).pipe(
      tap((response: AgencyGroupInfo) => {
        this.agencyGroupList$.next(response.list);

        if (response.errorList) {
          const errorTaCodeList: GecoErrorMessage[] = response.errorList as GecoErrorMessage[];
          const errorCode = 'ERROR_AGENCYGROUP_0001';

          let errorMessageTaCodeList = '<ul class="mb-0">';
          errorTaCodeList.forEach(element => {
            errorMessageTaCodeList = errorMessageTaCodeList + '<li>' + element.message + '</li>';
          });
          errorMessageTaCodeList = errorMessageTaCodeList + '</ul>';

          this.messageService.addMessage({
            type: 'error',
            icon: '',
            code: errorCode,
            errorObject: response,
            extraHtml: null,
            messageHtml: errorMessageTaCodeList,
          });
        }
        return response.list;
      })
    );
  }

  deleteAgency(taCode: string) {
    const deleteAgency = {
      agency: taCode,
    };

    this.spinnerService.setMessage('spinner.savingAgencies');
    return this.httpClient.post<AgencyGroupInfo>(Constants.URL_AGENCY_GROUP_DELETE, deleteAgency, requestOptions).pipe(
      tap((response: AgencyGroupInfo) => {
        this.agencyGroupList$.next(response.list);
        return response.list;
      }));
  }
}
