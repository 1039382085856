import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Constants } from '@app/app.constants';
import { GecoErrorMessageLogin, GecoResponse, UserInfo } from '@core/models';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { AuthService } from '../../../services/auth.service';


@Component({
  selector: 'ta-login-form',
  templateUrl: './login-form.component.html',
})
export class LoginFormComponent implements OnInit {

  loginForm: FormGroup;
  loginError: string;
  currentYear: number;

  public userInfo: UserInfo;

  // urls
  legalNoticeUrl: string = Constants.URL_LEGAL_NOTICE;
  bankOfSpainUrl: string = Constants.URL_BANK_OF_SPAIN;
  onyxPrivacyPolicyUrl: string = Constants.URL_ONYX_PRIVACY_POLICY;
  clientDefenseUrl: string = Constants.URL_CLIENT_DEFENSE;

  // language
  languageOptions = Constants.LANGUAGE_OPTIONS;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
    this.initForm();
  }

  onLanguageChange() {
    this.translate.use(this.language.value);
    localStorage.setItem(Constants.LANG_KEY, this.language.value);
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }

    this.authService.login(this.email.value, this.password.value)
      .pipe(take(1)).subscribe((response: GecoResponse) => {
        if (response.successful) {
          this.authService.isPwdExpired.subscribe(res => {
            if (res) {
              this.router.navigate(['auth/pwd-reset']);
            } else {
              this.router.navigate(['/']);
            }
          }
          );
        } else {
          if (response.error && (response.error as GecoErrorMessageLogin).message) {
            this.showError(response.error);
          }
        }
      });
  }

  private showError(error: any) {
    const errorMsg = Constants[error.message];
    if (error.message === 'STD_LOGIN_COUNTDOWN_ACCESS') {
      this.loginError = this.translate.instant(errorMsg, { attempts: error.attempts });
    } else if (error.message === 'STD_LOGIN_COUNTDOWN_ENDED') {
      this.loginError = this.translate.instant(errorMsg, { url: '#/auth/recover' });
    } else {
      this.loginError = this.translate.instant(errorMsg);
    }
  }

  /**
   * Checks if the field has been modified and is invalid
   *
   * @param formControl the field to be validated
   * @returns true if the field invalid
   */
  isInvalid(formControl: AbstractControl): boolean {
    return formControl.invalid && !formControl.untouched;
  }

  private initForm() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      language: [localStorage.getItem(Constants.LANG_KEY)],
    });
  }

  get email() { return this.loginForm.get('email'); }
  get password() { return this.loginForm.get('password'); }
  get language() { return this.loginForm.get('language'); }

}
