import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/services/auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Constants } from '../../app.constants';

@Injectable({
  providedIn: 'root',
})
export class RefreshTokenInterceptor implements HttpInterceptor {

  isUserLoggedIn: boolean;

  constructor(
    private authService: AuthService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(map(event => {
      if (event instanceof HttpResponse && this.shouldBeIntercepted(event)) {
        const updatedToken = event.headers.get('x-amzn-remapped-authorization');
        localStorage.setItem(Constants.TOKEN_KEY, updatedToken);
      }

      return event;
    }));
  }

  private shouldBeIntercepted(event: HttpResponse<any>) {
    this.authService.isLoggedIn.subscribe(value => {
      this.isUserLoggedIn = value;
    });

    return event.url.indexOf(environment.apiHost) !== -1;
  }

}
