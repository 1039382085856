import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from '@app/auth/services/auth.service';
import { Router } from '@angular/router';
import { TaprofileUtilsService, CommonDataService } from '@core/services';
import { UserInfo } from '@core/models';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ta-expiration-modal',
  templateUrl: './expiration-modal.component.html',
})

export class ExpirationModalComponent implements OnInit, OnDestroy {

  @Input() countDown: number;

  title: string;
  content: string;
  timerFormatCountDown: string;

  userInfo: UserInfo;

  subscription: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private router: Router,
    public bsModalRef: BsModalRef,
    private profileService: TaprofileUtilsService,
    private translate: TranslateService,
    private commonDataService: CommonDataService
  ) {

  }

  ngOnInit() {

    this.title = this.translate.instant('expirationTokenModal.title');
    this.timerFormatCountDown = this.timerFormat(this.countDown);

    this.subscription.add(
      this.profileService.userInfo.subscribe(userInfo => {
        this.userInfo = userInfo;
      })
    );

    this.decrement();

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.closeModal();
  }

  decrement() {
    setInterval(() => {
      if (this.countDown > 0) {
        --this.countDown;
      }

      this.timerFormatCountDown = this.timerFormat(this.countDown);

    }, 1000);
  }

  closeModal() {
    if (this.bsModalRef) {
      this.bsModalRef.hide();
      this.bsModalRef = null;
      this.commonDataService.expModal.next(false);
    }
  }

  public endSession(): void {
    this.authService.logout();
    this.closeModal();
    this.router.navigate(['auth/login']);
  }

  public refreshAuthentication() {
    this.subscription.add(
      this.profileService.getUserInfo().subscribe(info => {
        this.userInfo = info;
        this.closeModal();
      }

      ));

  }

  private timerFormat(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(value - minutes * 60)).slice(-2);
  }
}
