<div class="input-group input-group-datepicker" [ngClass]="{ 'is-invalid': !isValid }">
  <input bsDatepicker
      type="text"
      class="form-control"
      placeholder="{{placeholderText}}"
      [(ngModel)]="selectedDate"
      [minDate]="minimumDate"
      [maxDate]="maximumDate"
      [bsConfig]="configuration"
      [disabled]="disabled">
  <div class="input-group-append">
    <button class="btn" type="button" (click)="onDateButtonClicked()" [disabled]="disabled">
      <i class="far fa-calendar-alt"></i>
    </button>
  </div>
</div>