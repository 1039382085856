import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '@app/app.constants';
import { Profile, UserInfo } from '@core/models';
import { TaprofileUtilsService } from '@core/services/taprofile-utils.service';
import { TranslateService } from '@ngx-translate/core';
import * as $ from 'jquery';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { flatMap, take, mergeMap } from 'rxjs/operators';
import { AuthService } from '../../../auth/services/auth.service';


@Component({
  selector: 'ta-profile-header',
  templateUrl: './profile-header.component.html',
})
export class ProfileHeaderComponent implements OnInit, OnDestroy, AfterViewInit {

  private subscription: Subscription = new Subscription();

  @ViewChild('profileSearchInput') public profileSearchInputElement: ElementRef;
  @ViewChildren(PopoverDirective) popovers: QueryList<PopoverDirective>;

  popTitle: string;
  popMessage: string;
  popIsOpen: boolean;

  showVCCPopover: boolean;

  public userProfiles: Profile[];
  public filteredProfiles: Profile[];
  public userInfo: UserInfo;

  public canChangeProfile: boolean;
  hasProfileMatches: boolean;

  public selectedProfile: Profile;
  public isAgencyGroup: Observable<boolean>;
  public isUserLoggedIn: Observable<boolean>;

  public isRegistrationPage: Observable<boolean>;

  modalRef: BsModalRef;

  public activePage = 1;
  private pageSize = 5;
  public filterProfilesFrom: number;
  public filterProfilesTo: number;
  public showPagination: boolean;

  public isLastPage: boolean;
  public isFirstPage: boolean;



  private _profileQuery: string;

  public get profileQuery(): string {
    return this._profileQuery;
  }

  public set profileQuery(value: string) {
    this._profileQuery = value;
    this.filterProfiles();
  }

  constructor(
    private taProfileUtilsService: TaprofileUtilsService,
    private authService: AuthService,
    private modalService: BsModalService,
    private router: Router,
    private translate: TranslateService
  ) { }

  ngOnInit() {

    this.isUserLoggedIn = this.authService.isLoggedIn;
    this.isRegistrationPage = this.authService.isRegistration;

    this.isAgencyGroup = this.taProfileUtilsService.selectedProfileIsAgencyGroup;

    this.subscription.add(
      this.taProfileUtilsService.selectedProfile.subscribe(profile => {
        this.selectedProfile = profile;
      })
    );

    this.subscription.add(
      this.taProfileUtilsService.getFirstLogin().subscribe(response => {
        if (response === true) {
          this.setPopoverSettings('');
          this.setPopoverIsOpen();
        }
      })
    );

    this.subscription.add(
      this.taProfileUtilsService.getShowVCCPopover().subscribe(responseShowVCC => {
        this.showVCCPopover = responseShowVCC;
        if (this.showVCCPopover === true) {
          this.taProfileUtilsService.getVccPending().subscribe(responseGetVCC => {
            if (responseGetVCC === true) {
              this.setPopoverSettings('VCC');
              this.setPopoverIsOpen();
            }
          });
        }
      })
    );

    this.subscription.add(
      this.taProfileUtilsService.userInfo.pipe(
        mergeMap(userInfo => {
          this.userInfo = userInfo;
          return this.taProfileUtilsService.getProfiles();
        }))
        .subscribe(profiles => {
          this.userProfiles = profiles;
          if (this.userInfo) {
            this.taProfileUtilsService.setSelectedProfile(profiles, this.userInfo.currentProfileId);
          }
          this.filterProfiles();
          this.canChangeProfile = this.userProfiles.length > 1;
        })
    );

  }

  ngAfterViewInit(): void {
    this.setPopoverSettings('');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  public filterProfiles(): void {
    if (this.profileQuery) {
      const caseInsensitiveQuery = this.profileQuery.toLowerCase();

      this.filteredProfiles = this.userProfiles.filter((profile: Profile) => {
        return profile.id.toLowerCase().indexOf(caseInsensitiveQuery) > -1
          || profile.type.toLowerCase().indexOf(caseInsensitiveQuery) > -1
          || profile.code.toLowerCase().indexOf(caseInsensitiveQuery) > -1
          || profile.alias.toLowerCase().indexOf(caseInsensitiveQuery) > -1
        ;
      });
    } else {
      this.filteredProfiles = this.userProfiles;
    }
    this.hasProfileMatches = this.filteredProfiles && this.filteredProfiles.length > 0;
    this.showPagination = this.filteredProfiles.length > this.pageSize;
    this.activePage = 1;
    this.onChangePage(0);
  }

  public onChangeProfileClicked(event: Event, profile: Profile): void {
    event.preventDefault();
    this.modalRef.hide();

    if (!this.authService.isUserAuthenticated()) {
      this.authService.logout();
      this.router.navigate(['auth/login']);
      return;
    }
    if (this.selectedProfile !== profile) {
      if (!profile.isProfile) {
        const redirectUrl = Constants.URL_CHANGE_PROFILE_GECO_PROFILE
          .replace('@token@', localStorage.getItem(Constants.TOKEN_KEY))
          .replace('@loginId@', String(this.userInfo.id))
          .replace('@session@', this.userInfo.sessionId)
          .replace('@newProfile@', profile.id);
        window.open(redirectUrl, '_blank');
      } else {
        this.changeProfile(profile);
      }

    }
  }

  private changeProfile(profile: Profile) {
    this.taProfileUtilsService.setCurrentProfile(profile.id, profile.code).subscribe(response => {
      if (response === 'OK') {
        this.taProfileUtilsService.setSelectedProfile(this.userProfiles, profile.id);
        this.taProfileUtilsService.getAgencyInfo().subscribe();
        this.router.navigate(['/']);
      }
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      { class: 'modal-lg' }
    );

    this.subscription.add(
      this.modalService.onShown.subscribe(() => {
        $('input[name="profileQuery"').focus();
      })
    );

    this.subscription.add(
      this.modalService.onHidden.subscribe(() => {
        this.profileQuery = '';
      })
    );
  }

  public onChangePage(increment: number): void {
    this.activePage = this.activePage + increment;

    this.isFirstPage = this.activePage === 1;
    this.isLastPage = this.activePage >= (this.filteredProfiles.length / this.pageSize);

    this.filterProfilesFrom = (this.activePage - 1) * this.pageSize;
    this.filterProfilesTo = this.activePage * this.pageSize;

    if (this.filterProfilesTo > this.filteredProfiles.length) {
      this.filterProfilesTo = this.filteredProfiles.length;
    }
  }

  get showHeader$() {
    return combineLatest(this.isUserLoggedIn, this.isRegistrationPage, (a, b) => a && !b);
  }

  setPopoverIsOpen() {
    this.popIsOpen = true;
  }

  resetPopoverIsOpen() {
    if (this.showVCCPopover) {
      this.taProfileUtilsService.setShowVCCPopover(false);
    } else {
      this.popIsOpen = false;
      this.setPopoverSettings('');
    }
  }

  setPopoverSettings(popoverType: string): void {
    this.popovers.changes.pipe(take(1)).subscribe(() => {
      this.popovers.forEach((popover: PopoverDirective) => {
        if (popoverType === 'VCC') {
          this.popTitle = this.translate.instant('profileHeader.addVCCContactsPopover.title');
          this.popMessage = this.translate.instant('profileHeader.addVCCContactsPopover.message');
        } else {
          this.popTitle = this.translate.instant('profileHeader.changeProfilePopover.title');
          this.popMessage = this.translate.instant('profileHeader.changeProfilePopover.message');
        }
      });
    });

  }

}
