<div class="card">
  <form [formGroup]="securityForm">
    <div class="card-header">
      <h2 class="card-title" *ngIf="!isResetPassword">{{'security.display' | translate}}</h2>
      <h2 class="card-title" *ngIf="isResetPassword">{{'security.profilePassword' | translate}}</h2>
    </div>
    <div class="card-body">
      <div class="row" *ngIf="isResetPassword">
        <div class="col-lg-6">
          <div class="form-group row">
            <label class="col-lg-3 col-form-label">{{'security.username' | translate}}</label>
            <label class="col-lg-9 col-form-label">{{username}}</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group row">
            <label class="col-lg-3 col-form-label">{{'security.password' | translate}}</label>
            <div class="col-lg-9">
              <input type="password" class="form-control" placeholder="{{'security.password' | translate}}"
                formControlName="newPassword" name="newPassword" (keyup)="validatePassword()" />
              <small class="form-text text-muted">{{'security.passwordMuted' | translate}}</small>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label"></label>
            <div class="col-lg-9">
              <input type="password" class="form-control" placeholder="{{'security.confirmPasswordPH' | translate}}"
                formControlName="confirmPassword" name="confirmPassword" (keyup)="validateConfirmPassword()"
                onPaste="return false" />
              <small class="form-text text-muted">{{'security.confirmPasswordMuted' |translate}}</small>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <p>{{'security.legend' | translate}}</p>
          <ul class="fa-ul">
            <li [ngClass]="{ 'text-success': hasValidLength }">
              <span class="fa-li"><i class="far"
                  [ngClass]="{ 'fa-check-square': hasValidLength, 'fa-square': !hasValidLength }"></i></span>
              {{'security.validLength' | translate}}
            </li>
            <li [ngClass]="{ 'text-success': hasValidAmountLetters }">
              <span class="fa-li"><i class="far"
                  [ngClass]="{ 'fa-check-square': hasValidAmountLetters, 'fa-square': !hasValidAmountLetters }"></i></span>
              {{'security.validAmountLetters' | translate}}
            </li>
            <li [ngClass]="{ 'text-success': hasValidAmountCapitalLetters }">
              <span class="fa-li"><i class="far"
                  [ngClass]="{ 'fa-check-square': hasValidAmountCapitalLetters, 'fa-square': !hasValidAmountCapitalLetters }"></i></span>
              {{'security.validAmountCapitalLetters' | translate}}
            </li>
            <li [ngClass]="{ 'text-success': hasValidAmountLowerLetters }">
              <span class="fa-li"><i class="far"
                  [ngClass]="{ 'fa-check-square': hasValidAmountLowerLetters, 'fa-square': !hasValidAmountLowerLetters }"></i></span>
              {{'security.validAmountLowerLetters' | translate}}
            </li>
            <li [ngClass]="{ 'text-success': hasValidAmountNumbers }">
              <span class="fa-li"><i class="far"
                  [ngClass]="{ 'fa-check-square': hasValidAmountNumbers, 'fa-square': !hasValidAmountNumbers }"></i></span>
              {{'security.validAmountNumbers' | translate}}
            </li>
            <li [ngClass]="{ 'text-success': hasSpecialChar }">
              <span class="fa-li">
                <i class="far" [ngClass]="{ 'fa-check-square': hasSpecialChar, 'fa-square': !hasSpecialChar }"></i>
              </span>
              <p>{{'security.validSpecialChar' | translate}} <span
                  class="text-muted">{{'security.specialCharList' | translate}}</span></p>
            </li>
          </ul>
          <p>{{'security.lastThreePasswords' | translate}}</p>
        </div>
      </div>
    </div>
  </form>
  <div class="card-footer">
    <div class="form-inline float-right">
      <button *ngIf="!isResetPassword" type="button" class="btn btn-primary" [disabled]="!canSave"
        (click)="save()">{{'security.saveBtn' | translate}}</button>
      <button *ngIf="isResetPassword" type="button" class="btn btn-primary" [disabled]="!canSave"
        (click)="save()">{{'security.resetBtn' | translate}}</button>
    </div>
  </div>
</div>