import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Constants } from '@app/app.constants';
import { TaprofileUtilsService } from '@core/services';

@Injectable({
  providedIn: 'root',
})
export class UserInfoResolver implements Resolve<any> {

  constructor(
    private profileService: TaprofileUtilsService
  ) { }

  resolve() {
    if (localStorage.getItem(Constants.TOKEN_KEY)) {
      return this.profileService.loadUserInfo();
    }
  }

}
