import { PlatformLocation } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@app/auth/services/auth.service';
import { TitleService } from '@core/services';
import { Subscription } from 'rxjs';


@Component({
  selector: 'ta-access-pwd',
  templateUrl: './access-pwd.component.html',
})
export class AccessPwdComponent implements OnInit, OnDestroy {

  public isUserLoggedIn = false;
  public goingBack = false;

  subscription: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private titleService: TitleService,
    private location: PlatformLocation
  ) {
    location.onPopState(() => {
      this.goingBack = true;
      this.authService.setLoggedIn(this.isUserLoggedIn);
    });
  }

  ngOnInit() {

    this.titleService.setPageTitle('pwdReset.title');

    this.subscription.add(
      this.authService.isLoggedIn.subscribe(value => {
        if (value && !this.goingBack) {
          this.isUserLoggedIn = true;
          this.authService.setLoggedIn(false);
        }
      })
    );

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
