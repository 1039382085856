import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Address, Dropdown } from '@core/models';
import { CommonDataService, TaprofileUtilsService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Constants } from '../../../../app.constants';

export const loading = 'loading';
export const empty = '';
export const refine = 'refine';


@Component({
  selector: 'ta-address-form',
  templateUrl: './address-form.component.html',
})
export class AddressFormComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();

  @Input() labelColumnCssClasses = 'col-lg-3';
  @Input() fieldColumnCssClasses = 'col-lg-9';
  @Input() addressInput: Address;
  @Input() states$: Observable<Dropdown[]>;
  @Input() cities$: Observable<Dropdown[]>;
  @Input() pendingFieldsCard: string[];
  @Input() card: string;
  @Output() closeModalFromChild = new EventEmitter<string>();

  cities: Dropdown[] = [];
  countries$: Observable<Dropdown[]>;

  filteredCities: Dropdown[] = [];
  searchBtnDisabled: boolean;

  addressForm: FormGroup;
  searchForm: FormGroup;

  isValidLNM: boolean;
  isLegalCard: boolean;
  showInputName = false;

  constructor(
    private fb: FormBuilder,
    private commonDataService: CommonDataService,
    private translate: TranslateService,
    private profileService: TaprofileUtilsService
  ) {
    this.countries$ = this.commonDataService.countries$;
  }

  ngOnInit() {
    this.initForm();

    if (this.card === 'legal') {
      this.isLegalCard = true;
    }

    this.subscription.add(this.profileService.isValidLNM$.subscribe(isValidLNM => {
      this.isValidLNM = isValidLNM;
    }));
  }

  ngOnDestroy(): void {
    this.profileService.setIsValidLNM(true);
    this.subscription.unsubscribe();
    this.isLegalCard = false;
  }

  private initForm() {
    this.addressForm = this.fb.group({
      address: [this.setControlValue('address'), [
        Validators.required,
        Validators.maxLength(108),
        Validators.pattern(Constants.LATIN_PATTERN)]],
      countrySelect: [this.setControlObject('countryName', 'countryCode')],
      countryCode: [this.setControlValue('countryCode'), Validators.required],
      countryName: [this.setControlValue('countryName'), Validators.required],
      stateSelect: [this.setControlObject('stateName', 'stateCode')],
      stateCode: [this.setControlValue('stateCode'), Validators.required],
      stateName: [this.setControlValue('stateName'), Validators.required],
      citySelect: [this.setControlObject('cityName', 'cityCode'), Validators.required],
      cityCode: [this.setControlValue('cityCode'), Validators.required],
      cityName: [this.setControlValue('cityName'), Validators.required],
      zipCode: [this.setControlValue('zipCode'), [Validators.required, Validators.maxLength(10)]],
    });

    this.searchForm = this.fb.group({
      citySearch: [this.setControlValue('cityName')],
    });

    // Check name in Commercial Details not in Postal Address
    this.showInputName = this.hasControlValue('name') || (this.card && this.card !== 'postal');
    if (this.showInputName) {
      this.addressForm.addControl('name', new FormControl(this.setControlValue('name'),
        [Validators.required, Validators.maxLength(100), Validators.pattern(Constants.LATIN_PATTERN)]));
    }

    // Disable state dropdown when country dont have states
    if (!this.commonDataService.hasCountryStates(this.countryCode.value)) {
      this.disableState(empty);
      this.setStateData(Constants.noStates, Constants.NO_STATES_NAME);
    }

    this.loadCities(this.cityCode.value);
    this.disableNestedDropdowns();

    this.searchBtnDisabled = true;
    this.enableCitySearch(empty);

    // Check and disable pending fields after loading all data
    this.disablePendingFields();

    this.onCountryCodeChange(this.countryCode.value);
  }

  onCountryCodeChange(value: string) {
    this.profileService.setSelectedLegalCountryCode(value);
  }

  /**
   * Checks if the field has been modified and is invalid
   *
   * @param formControl the field to be validated
   * @returns true if the field invalid
   */
  isInvalid(formControl: AbstractControl): boolean {
    return formControl.invalid && formControl.touched;
  }

  /*
   * Send error message if the field has been modified and is invalid
   */
  isInvalidMessage(formControl: AbstractControl): string {
    if (formControl.invalid && formControl.touched) {
      if (formControl.errors.required) {
        return this.translate.instant('forms.required');
      }
      if (formControl.errors.maxlength) {
        return this.translate.instant('forms.maxLength');
      }
      if (formControl.errors.minlength) {
        return this.translate.instant('forms.minLength');
      }
      if (formControl.errors.pattern) {
        return this.translate.instant('forms.latinAlphabet');
      }
    }
  }

  private setControlObject(name: string, code: string) {
    if (this.hasControlValue(code) && this.hasControlValue(name)) {
      return new Dropdown(this.setControlValue(name), this.setControlValue(code));
    }
    return this.setControlValue(empty);
  }

  private setControlValue(controlName: string) {
    return this.hasControlValue(controlName) ? this.addressInput[controlName] : '';
  }

  private disableControl(control: AbstractControl, value: string) {
    control.patchValue(value);
    control.disable();
  }

  private enableControl(control: AbstractControl, value: string) {
    control.patchValue(value);
    control.enable();
  }

  private enableDropdown(control: AbstractControl, name: string, code: string) {
    control.patchValue(new Dropdown(name, code));
    control.enable();
  }

  hasControlValue(controlName: string) {
    return this.addressInput && this.addressInput[controlName];
  }

  disablePendingFields() {
    if (this.pendingFieldsCard) {
      this.pendingFieldsCard.forEach(pendingField => {
        this.disableControl(this.addressForm.get(pendingField), this.addressForm.get(pendingField).value);

        if (pendingField === 'citySelect') {
          this.disableCitySearch(empty);
          this.searchBtnDisabled = true;
        }
      });
    }
  }

  disableNestedDropdowns() {
    if (this.stateCode.value === '') { this.disableState(empty); }
  }

  compareFn(d1: Dropdown, d2: Dropdown): boolean {
    return d1 && d2 ? d1.code === d2.code && d1.name === d2.name : d1 === d2;
  }

  get name() { return this.addressForm.get('name'); }
  get address() { return this.addressForm.get('address'); }
  get countrySelect() { return this.addressForm.get('countrySelect'); }
  get countryCode() { return this.addressForm.get('countryCode'); }
  get countryName() { return this.addressForm.get('countryName'); }
  get stateSelect() { return this.addressForm.get('stateSelect'); }
  get stateCode() { return this.addressForm.get('stateCode'); }
  get stateName() { return this.addressForm.get('stateName'); }
  get citySelect() { return this.addressForm.get('citySelect'); }
  get cityCode() { return this.addressForm.get('cityCode'); }
  get cityName() { return this.addressForm.get('cityName'); }
  get citySearch() { return this.searchForm.get('citySearch'); }
  get zipCode() { return this.addressForm.get('zipCode'); }

  /** Country */
  onCountryChange() {
    this.disableCitySelect(loading);
    this.setCountryData();
    if (this.commonDataService.hasCountryStates(this.countryCode.value)) {
      this.disableState(loading);
      this.disableCitySearch(empty);
      this.getStatesByCountry(this.countryCode.value);
    } else {
      this.setStateData(Constants.noStates, Constants.NO_STATES_NAME);
      this.disableState(empty);
      this.enableCitySearch(empty);
      this.getCitiesByCountryAndState(this.countryCode.value, Constants.noStates);
    }
    this.searchBtnDisabled = true;

    this.onCountryCodeChange(this.countryCode.value);

  }

  setCountryData() {
    this.countryCode.patchValue(this.countrySelect.value.code);
    this.countryName.patchValue(this.countrySelect.value.name);
  }

  /** State */
  onStateChange() {
    this.setStateData(this.stateSelect.value.code, this.stateSelect.value.name);
    this.disableCitySelect(loading);
    this.enableCitySearch(empty);
    this.searchBtnDisabled = true;
    this.getCitiesByCountryAndState(this.countryCode.value, this.stateCode.value);
  }

  setStateData(code: string, name: string) {
    this.stateCode.patchValue(code);
    this.stateName.patchValue(name);
  }

  disableState(value: string) { this.disableControl(this.stateSelect, value); }
  enableState(value: string) { this.enableControl(this.stateSelect, value); }

  enableStateDropdown(name: string, code: string) { this.enableDropdown(this.stateSelect, name, code); }
  enableStateSelect(value: string) { this.enableControl(this.stateSelect, value); }

  getStatesByCountry(countryCode: string) {
    this.states$ = this.commonDataService.loadStatesByCountry(countryCode);
    this.states$.pipe(take(1)).subscribe(states => {
      let stateId = '';
      let stateName = '';
      this.enableState(empty);
      if (states.some(state => state.code === this.addressInput.stateCode)) {
        stateId = this.addressInput.stateCode;
        stateName = this.addressInput.stateName;
        this.setStateData(stateId, stateName);
        this.enableStateDropdown(stateName, stateId);
        this.getCitiesByCountryAndState(this.countryCode.value, stateId);
        this.enableStateDropdown(stateName, stateId);
      } else {
        this.enableStateSelect(empty);
        this.setStateData(empty, empty);
      }
    });
  }

  /** City */
  onCityNameChange() {
    if (this.citySearch) {
      this.searchBtnDisabled = this.citySearch.value.length < 2;
    }
  }

  onCityChange() {
    this.setCityData();
  }

  setCityData() {
    this.cityCode.patchValue(this.citySelect.value.code);
    this.cityName.patchValue(this.citySelect.value.name);
  }

  getCitiesByCountryAndState(countryCode: string, stateId: string) {
    this.cities$ = this.commonDataService.loadCitiesByCountryAndState(countryCode, stateId);
    if (countryCode === this.addressInput.countryCode && stateId === this.addressInput.stateCode) {
      this.enableCitySearch(empty);
      this.loadCities(this.addressInput.cityCode);
    } else {
      this.loadCities();
      this.setCityData();
    }
  }

  loadCities(cityCode?: string) {
    if (this.cities$) {
      this.cities$.pipe(take(1)).subscribe(cities => {
        this.cities = cities;
        this.filterCities(cityCode);
      }, error => {
        this.disableCitySelect(empty);
        this.disableCitySearch(empty);
      });
    } else {
      this.disableCitySelect(empty);
    }
  }

  filterCities(cityCode?: string) {
    this.disableCitySelect(loading);
    let tempFilteredCities;
    if (cityCode) {
      tempFilteredCities = this.cities.filter(city => city.code.indexOf(cityCode) !== -1);
    } else {
      tempFilteredCities = this.cities.filter(city => city.name.indexOf(this.citySearch.value) !== -1);
    }

    if (tempFilteredCities.length < 500) {
      this.filteredCities = tempFilteredCities;
      this.enableCitySelect(empty);
      this.searchCity();
    } else {
      this.filteredCities = [];
      this.cities = tempFilteredCities;
      setTimeout(() => { this.disableCitySelect(refine); }, 500);
    }

    this.onCityNameChange();

  }

  searchCity() {
    if (this.filteredCities.some(city => city.code === this.addressInput.cityCode && city.name === this.addressInput.cityName)) {
      this.enableCityDropdown(this.addressInput.cityName, this.addressInput.cityCode);
      // TODO: check citySelect in pendingFields
    } else {
      if (this.filteredCities.length === 1) {
        this.enableCityDropdown(this.filteredCities[0].name, this.filteredCities[0].code);
        this.setCityData();
        this.cityCode.markAsDirty();
      } else {
        this.enableCitySelect(empty);
      }
    }
  }

  disableCitySelect(value: string) { this.disableControl(this.citySelect, value); }
  enableCitySelect(value: string) { this.enableControl(this.citySelect, value); }
  enableCityDropdown(name: string, code: string) { this.enableDropdown(this.citySelect, name, code); }

  disableCitySearch(value: string) { this.disableControl(this.citySearch, value); }
  enableCitySearch(value: string) { this.enableControl(this.citySearch, value); }

  close() {
    this.closeModalFromChild.emit();
  }

  isPendingReview(field: string) {
    if (this.pendingFieldsCard) {
      return this.pendingFieldsCard.indexOf(field) !== -1;
    }
  }

}
