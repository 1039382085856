<div *ngIf="hasMessages" class="alert-toast-container position-fixed" style="z-index: 10000;">
  <div *ngFor="let message of (messages | async)">
    <div class="alert-toast alert-toast-{{message.type}} alert-dismissible"
      role="alert">
      <i class="alert-icon far {{message.icon}}"></i>

      <div *ngIf="message.code && isUserLoggedIn" aria-live="polite" role="alertdialog" class="alert-toast-message">
        <h4 class="alert-toast-heading">
        </h4>
        {{'errorMessageList.TAPP_' + message.code + '.title' | translate}}
          <p [innerHtml]="'errorMessageList.TAPP_' + message.code + '.message' | translate | replace: {extraHtml:  message.extraHtml}"></p>
        <p *ngIf="message.messageHtml" [innerHtml]="message.messageHtml"></p>

      </div>

      <div *ngIf="message.code && !isUserLoggedIn" aria-live="polite" role="alertdialog" class="alert-toast-message">
        <h4 class="alert-toast-heading">
          {{ 'errorMessageList.TAPP_' + message.code + '.title' | translate}}
        </h4>
        <p [innerHtml]="'errorMessageList.TAPP_' + message.code + '.message' | translate | replace: {extraHtml:  message.extraHtml}"></p>
      </div>

      <button class="close" aria-label="Close" type="button" (click)="onMessageCloseClicked(message)">
        <span aria-hidden="true">&times;</span>
        <span class="sr-only">Close</span>
      </button>
    </div>
  </div>
</div>