import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[taValidateNoNull][formControlName],[taValidateNoNull][formControl],[taValidateNoNull][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => OneNoNullDirective), multi: true },
  ],
})

export class OneNoNullDirective implements Validator {
  constructor( @Attribute('taValidateNoNull') public taValidateNoNull: string) {}

  validate(OriCompar: AbstractControl): { [key: string]: any; } {
        // self value
    const valueField1 = OriCompar.value;
    const Field2 = OriCompar.root.get(this.taValidateNoNull);

        // both values are null
    if (  (valueField1 === null || valueField1 === '' || valueField1 === undefined )
              &&
              (Field2.value === null || Field2.value === '' || Field2 === undefined  ) ) {
      return {
        taValidateNoNull: false,
      };
    }

        // At least one value is not null
    if  (   (valueField1 !== null   && valueField1 !== ''   && valueField1 !== undefined  )
              ||
                (Field2.value !== null  && Field2.value !== ''  && Field2.value !== undefined )
    ) {

      if (Field2.errors) {
        delete Field2.errors['taValidateNoNull'];
        if (!Object.keys(Field2.errors).length) { Field2.setErrors(null); }
      }
    }
    return null;
  }
}
