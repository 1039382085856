import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { CommonDataService } from '@core/services';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CountryResolver implements Resolve<any> {

  constructor(
    private commonDataService: CommonDataService
  ) { }

  resolve() {
    this.commonDataService.loadCountries().pipe(take(1)).subscribe();
  }
}
