import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Constants } from '@app/app.constants';
import { UserInfo } from '@core/models';
import { TaprofileUtilsService } from '@core/services';
import { Subscription, Observable } from 'rxjs';
import { AuthService } from '@app/auth/services/auth.service';

@Component({
  selector: 'ta-user-security',
  templateUrl: './user-security.component.html',
})
export class UserSecurityComponent implements OnInit {

  @Input() emailToken: string;
  @Input() appKey: string;
  @Input() username: string;
  @Input() id: number;

  subscription: Subscription;

  userInfo: UserInfo;

  isResetPassword = false;

  securityForm: FormGroup;

  hasValidLength = false;
  hasValidAmountLetters = false;
  hasValidAmountCapitalLetters = false;
  hasValidAmountLowerLetters = false;
  hasValidAmountNumbers = false;
  hasSpecialChar = false;

  hasValidConfirmation = false;

  canSave = false;

  constructor(
    private fb: FormBuilder,
    private taProfileUtilsService: TaprofileUtilsService,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {

    this.subscription = new Subscription();

    this.securityForm = this.fb.group({
      newPassword: [''],
      confirmPassword: [''],
    });

    if (this.emailToken) {
      this.isResetPassword = true;
    } else {
      this.subscription.add(
        this.taProfileUtilsService.userInfo.subscribe(userInfo => {
          if (userInfo) {
            this.userInfo = userInfo;
            this.id = userInfo.id;
            this.username = userInfo.email;
          }
        })
      );
    }
  }

  validateLength(): boolean {
    const lengthRegex = new RegExp('^(?=.{8,})');
    return this.securityForm.dirty && lengthRegex.test(this.securityForm.get('newPassword').value);
  }

  validateFourLetters(): boolean {
    const searchString: string = this.securityForm.get('newPassword').value;
    return this.securityForm.dirty && searchString.replace(/[^a-zA-Z]/g, '').length >= 4;
  }

  validateUpperCase(): boolean {
    const upperCaseRegex = new RegExp('^(?=.*[A-Z])');
    return this.securityForm.dirty && upperCaseRegex.test(this.securityForm.get('newPassword').value);
  }

  validateLowerCase(): boolean {
    const lowerCaseRegex = new RegExp('^(?=.*[a-z])');
    return this.securityForm.dirty && lowerCaseRegex.test(this.securityForm.get('newPassword').value);
  }

  validateNumber(): boolean {
    const searchString: string = this.securityForm.get('newPassword').value;
    return this.securityForm.dirty && searchString.replace(/\D/g, '').length >= 3;
  }

  validateSpecialChars(): boolean {
    const specialCharRegex = new RegExp('^(?=.*[ !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~])');
    const searchString = this.securityForm.get('newPassword').value;

    return this.securityForm.dirty && (searchString.replace(/[^\\]/g, '').length >= 1 || specialCharRegex.test(this.securityForm.get('newPassword').value));
  }

  validatePassword() {
    this.hasValidLength = this.validateLength();
    this.hasValidAmountLetters = this.validateFourLetters();
    this.hasValidAmountCapitalLetters = this.validateUpperCase();
    this.hasValidAmountLowerLetters = this.validateLowerCase();
    this.hasValidAmountNumbers = this.validateNumber();
    this.hasSpecialChar = this.validateSpecialChars();
    this.validateConfirmPassword();
  }

  validateConfirmPassword() {
    this.hasValidConfirmation = this.securityForm.dirty && this.securityForm.get('confirmPassword').value === this.securityForm.get('newPassword').value;
    this.validateSave();
  }

  validateSave() {
    this.canSave = this.hasValidLength
      && this.hasValidAmountLetters
      && this.hasValidAmountCapitalLetters
      && this.hasValidAmountLowerLetters
      && this.hasValidAmountNumbers
      && this.hasSpecialChar
      && this.hasValidConfirmation;
  }

  save() {
    let appToken: any = '';
    let appKey: any = '';
    let emailToken: any = '';
    let id: any = '';
    const password = this.securityForm.get('newPassword').value;

    if (this.isResetPassword) {
      appKey = this.appKey;
      emailToken = this.emailToken;
      id = this.id;
    } else {
      appToken = localStorage.getItem(Constants.TOKEN_KEY);
      id = this.id;
    }
    this.taProfileUtilsService.savePassword(appToken, appKey, emailToken, id, password).subscribe(response => {
      if (response.successful) {
        if (!this.isResetPassword) {
          this.authService.setLoggedIn(true);

          if (this.authService.isPwdExpired) {
            this.authService.setPwdExpired(false);
          }
        }

        this.router.navigate(['/pwd-messages'], { queryParams: { pwdMessageOrigin: 'PwdMessageUpdated', username: this.username } });
      }
    });
  }
}
