import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AgencyInfo } from '@core/models';
import { MessagesUtilsService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { Contact } from '../../../../core/models/contact.model';
import { ContactUtilsService } from '../../../../core/services/contact-utils.service';
import { TaprofileUtilsService } from '../../../../core/services/taprofile-utils.service';

@Component({
  selector: 'ta-contact-modal',
  templateUrl: './contact-modal.component.html',
})
export class ContactModalComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription();
  action: string;
  taCode: string;
  pendingFields: string[];
  contact: Contact;
  hasPaymentCorrespondaceContact: boolean;
  hasPaymentCorrespondaceContactPending: boolean;
  hasVccPaymentRecipientContact: boolean;
  hasVccPaymentRecipientContactPending: boolean;
  isValidDomain: boolean;
  showVCCMessage: boolean;

  contactForm: FormGroup;

  public labelColumnCssClasses = 'col-lg-3';
  public fieldColumnCssClasses = 'col-lg-9';

  public currentContact: Contact;
  public currentAction: string;
  public actionHeader: string;

  public isVccAllowed = false;


  constructor(
    public bsModalRef: BsModalRef,
    private contactService: ContactUtilsService,
    private translate: TranslateService,
    private profileService: TaprofileUtilsService,
    private messageService: MessagesUtilsService
  ) { }

  ngOnInit() {
    this.initForm();
    this.isValidDomain = true;
    this.subscription.add(this.contactService.isValidDomain$.subscribe(isValidDomain => {
      this.isValidDomain = isValidDomain;
    }));

    this.subscription.add(
      this.profileService.getVccPending().subscribe(response => {
        this.showVCCMessage = response;
      })
    );

    this.subscription.add (
      this.profileService.getAgencyInfo().subscribe((data) => {
        this.isVccAllowed = this.profileService.isVccAllowed(data.newInfo.commercial.countryCode);
      })
    );

  }

  ngOnDestroy() {
    this.contactService.setIsValidDomain(true);
    this.subscription.unsubscribe();
  }

  private initForm() {
    this.contactForm = new FormGroup({
      name: new FormControl(this.setControlValue('name'), [Validators.required, Validators.maxLength(42)]),
      email: new FormControl(this.setControlValue('email'), [Validators.required, Validators.email, Validators.maxLength(70)]),
      phone: new FormControl(this.setControlValue('phoneNr'), Validators.required),
      fax: new FormControl(this.setControlValue('faxNr'), Validators.required),
      type: new FormGroup({
        typeCP: new FormControl(this.setControlValueBoolean('paymentCorrespondence')),
        typeCO: new FormControl(this.setControlValueBoolean('paymentCorrespondenceCopiedRecipient')),
        typeSM: new FormControl(this.setControlValueBoolean('salesManager')),
        typeIR: new FormControl(this.setControlValueBoolean('invoiceRecipient')),
        typeVC: new FormControl(this.setControlValueBoolean('vccPaymentRecipient')),
      }, this.requireCheckboxesToBeCheckedValidator()),
    });

    // By default Add contact
    this.currentAction = 'Add';
    this.currentContact = new Contact();
    this.actionHeader = this.translate.instant('taContactCard.addContact');

    if (this.contact) {
      // Update contact
      this.currentAction = 'Update';
      this.currentContact = this.contact;
      this.actionHeader = this.translate.instant('taContactCard.updateContact');

      // Exists Payment Correspondance in other record
      if (this.currentContact.paymentCorrespondence) {
        this.disableControl(this.typeCP, true);
      } else if (!this.currentContact.paymentCorrespondence && this.hasPaymentCorrespondaceContact) {
        this.disableControl(this.typeCP, false);
      }

      // // Exists VCC Payment Recipient in other record
      if (!this.currentContact.vccPaymentRecipient && this.hasVccPaymentRecipientContact) {
        this.disableControl(this.typeVC, false);
      }

    } else {
      // Add new user Payment Correspondace
      if (this.hasPaymentCorrespondaceContact || this.hasPaymentCorrespondaceContactPending) {
        this.disableControl(this.typeCP, false);
      } else {
        this.disableControl(this.typeCP, true);
      }
      // Add new user VCC Payment Recipient
      if (this.hasVccPaymentRecipientContact || this.hasVccPaymentRecipientContactPending) {
        this.disableControl(this.typeVC, false);
      }
    }

  }

  requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
    return function validate(formGroup: FormGroup) {
      let checked = 0;

      Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.controls[key];

        if (control.value === true) {
          checked++;
        }
      });

      if (checked < minRequired) {
        return {
          requireOneCheckboxToBeChecked: true,
        };
      }

      return null;
    };
  }

  get name() { return this.contactForm.get('name'); }
  get email() { return this.contactForm.get('email'); }
  get phone() { return this.contactForm.get('phone'); }
  get fax() { return this.contactForm.get('fax'); }
  get type() { return this.contactForm.get('type'); }
  get typeCP() { return this.type.get('typeCP'); }
  get typeCO() { return this.type.get('typeCO'); }
  get typeSM() { return this.type.get('typeSM'); }
  get typeIR() { return this.type.get('typeIR'); }
  get typeVC() { return this.type.get('typeVC'); }

  /**
   * Checks if the field has been modified and is invalid
   *
   * @param formControl the field to be validated
   * @returns true if the field invalid
   */
  isInvalid(formControl: AbstractControl) {
    return formControl.invalid && formControl.touched;
  }

  /*
   * Send error message if the field has been modified and is invalid
   */
  isInvalidMessage(formControl: AbstractControl): string {
    let message: string;

    if (this.isInvalid(formControl)) {
      if (formControl.errors.required) {
        switch (formControl) {
          case this.name:
            message = this.translate.instant('taContactCard.requiredFieldName');
            break;
          case this.email:
            message = this.translate.instant('taContactCard.requiredFieldEmail');
            break;
          case this.phone:
            message = this.translate.instant('taContactCard.requiredFieldPhone');
            break;
          case this.fax:
            message = this.translate.instant('taContactCard.requiredFieldFax');
            break;
        }
      } else if (formControl.errors.maxlength) {
        return this.translate.instant('forms.maxLength');
      } else if (formControl.errors.email) {
        message = this.translate.instant('login.invalidEmail');
      } else {
        message = this.translate.instant('taContactCard.requiredFieldType');
      }
    }
    return message;
  }

  private disableControl(control: AbstractControl, value: any) {
    control.patchValue(value);
    control.disable();
  }

  hasControlValue(controlName: string) {
    return this.contact && this.contact[controlName];
  }

  private setControlValue(controlName: string) {
    return this.hasControlValue(controlName) ? this.contact[controlName] : '';
  }

  private setControlValueBoolean(controlName: string) {
    return this.hasControlValue(controlName) ? this.contact[controlName] : false;
  }

  isFormGroup(formControl: AbstractControl): boolean {
    const formGroup: FormGroup = <FormGroup>formControl;
    if (formGroup.controls) {
      return true;
    } else {
      return false;
    }
  }

  public saveContact() {

    const saveContact: Contact = {
      id: null,
      contactKey: null,
      name: this.name.value,
      email: this.email.value,
      phoneNr: this.phone.value,
      faxNr: this.fax.value,
      paymentCorrespondence: this.typeCP.value,
      paymentCorrespondenceCopiedRecipient: this.typeCO.value,
      invoiceRecipient: this.typeIR.value,
      salesManager: this.typeSM.value,
      vccPaymentRecipient: this.typeVC.value,
      hasPendingChanges: true,
    };

    if (this.currentAction === 'Add') {
      this.contactService.addContactService(this.taCode, saveContact).subscribe(response => {
        if (response) {
          const agencyInfo: AgencyInfo = <AgencyInfo>response;
          this.profileService.reloadAgencyInfo(agencyInfo);
          this.closeModal();
          if (saveContact.vccPaymentRecipient) {
            this.profileService.setShowVCCPopover(true);
          }
        }
      });
    } else {
      saveContact.id = this.contact.id;
      saveContact.contactKey = this.contact.contactKey;

      this.contactService.updateContactService(this.taCode, saveContact).subscribe((response: AgencyInfo) => {
        if (response) {
          const agencyInfo: AgencyInfo = response;
          this.profileService.reloadAgencyInfo(agencyInfo);
          this.closeModal();
          if (saveContact.vccPaymentRecipient) {
            this.profileService.setShowVCCPopover(true);
          }
        }
      });
    }
  }

  public deleteContact() {
    this.contactService.deleteContactService(this.taCode, this.contact.contactKey).subscribe(response => {
      const agencyInfo: AgencyInfo = <AgencyInfo>response;
      this.profileService.reloadAgencyInfo(agencyInfo);
    });

    this.closeModal();
  }
  closeModal() {
    if (!this.bsModalRef) {
      return;
    }
    this.bsModalRef.hide();
    this.bsModalRef = null;
  }

}
