import { Pipe, PipeTransform } from '@angular/core';


@Pipe({name: 'replace'})
export class ReplacePipe implements PipeTransform {
  transform(value: string, args?: any[]): string {

        // For each argument

    for (const key in args) {
      if (args[key]) {
        value = value.replace('{{' + key + '}}', args[key]);
      }
    }

    return value;
  }
}
