<div class="container-layout">
  <ng-container *ngIf="isUserLoggedIn">
    <ods-left-navbar>
      <!-- Add navigation list items here -->
      <ng-container ods-left-navbar-body-list-items>
        <ods-left-navbar-list-item label="{{'tabs.profile' | translate}}" routerLink="/profile" iconClass="far fa-user nav-item-indicator">
        </ods-left-navbar-list-item>
        <ods-left-navbar-list-item label="{{'tabs.userGuide' | translate}}" routerLink="/" iconClass="far fa-question-circle" (click)="getUserGuide($event)">
        </ods-left-navbar-list-item>
      </ng-container>
    </ods-left-navbar>
  </ng-container>

  <div [class.container-layout-right]="isUserLoggedIn">

    <ods-top-navbar *ngIf="!isUserLoggedIn" componentTitle="{{'topnavbar.title' |translate}}"></ods-top-navbar>
    <ods-top-navbar *ngIf="isUserLoggedIn" componentTitle="{{'topnavbar.title' |translate}}" [userName]="username">
      <!-- Add user menu items here -->
      <ng-container ods-top-navbar-dropdown-items>
        <h6 class="dropdown-header">{{'documents.onyxDocuments' | translate}}</h6>
        <a class="dropdown-item" href="{{clientDefenseUrl}}" target="_blank">{{'documents.clientDefense' |
          translate}}</a>
        <a class="dropdown-item" href="{{paymentEntityBankOfSpainUrl}}"
          target="_blank">{{'documents.paymentEntityBankOfSpain' | translate}}</a>
        <a class="dropdown-item" href="{{legalNoticeUrl}}" target="_blank">{{'documents.legalNotice' | translate}}</a>
        <a class="dropdown-item" href="{{privacyPolicyUrl}}" target="_blank">{{'documents.privacyPolicy' |
          translate}}</a>
        <a class="dropdown-item" href="{{termsOfBusinessUrl}}" target="_blank">{{'documents.termsOfBusiness' |
          translate}}</a>
        <div class="dropdown-divider"></div>
        <button class="dropdown-item" (click)="signOut()">{{'topnavbar.logOut' |translate}}</button>
      </ng-container>
    </ods-top-navbar>

    <ta-profile-header *ngIf="isUserLoggedIn"></ta-profile-header>

    <ods-view-content>
      <!-- Page content is dynamically displayed using the routing module -->
      <router-outlet></router-outlet>
    </ods-view-content>

  </div>

  <ta-page-messages></ta-page-messages>
  <ta-page-spinner></ta-page-spinner>
</div>