import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class SpinnerUtilsService {

  private isActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isActive = this.isActive$.asObservable();

  private message$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  message = this.message$.asObservable();

  private stayOnTop$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  stayOnTop = this.stayOnTop$.asObservable();

  constructor() { }

  show() {
    if (this.message$.value !== null) {
      this.isActive$.next(true);
    }
  }

  hide() {
    if (!this.stayOnTop$.value) {
      this.isActive$.next(false);
      this.message$.next(null);
    }
  }

  setMessage(value: string) {
    this.message$.next(value);
  }

  setStayOnTop() {
    this.stayOnTop$.next(true);
  }
}
