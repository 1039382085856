import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from '@core/core.module';
import { NgxDesignSystemComponentsModule } from '@onyx/ngx-design-system-components';
import { ProfileHeaderComponent } from '@shared/layout/profile-header/profile-header.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AgencyGroupModule, ProfileHistoryModule, ProfileModule, SettingsModule } from './modules';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    ProfileHeaderComponent,
  ],
  imports: [
    BrowserModule,
    CoreModule,
    SharedModule,
    // app modules
    AuthModule,
    ProfileModule,
    ProfileHistoryModule,
    AgencyGroupModule,
    SettingsModule,
    NgxDesignSystemComponentsModule,
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
