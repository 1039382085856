import { Component, Input, OnInit } from '@angular/core';
import { Address, Agency, Dropdown } from '@core/models';
import { CommonDataService, TaprofileUtilsService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'app/app.constants';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AddressModalComponent } from '../address';
import { LegalDetailsModalComponent } from '../legal-details/legal-details-modal/legal-details-modal.component';
import { ContactModalComponent } from '../contact/contact-modal/contact-modal.component';
import { ContactCardComponent } from '@shared/components/contact/contact-card/contact-card.component';

@Component({
  selector: 'ta-empty-card',
  templateUrl: './empty-card.component.html',
})
export class EmptyCardComponent implements OnInit {

  linkText: Observable<string>;


  @Input() card: string;
  @Input() newInfo: Agency;
  @Input() taCode: string;
  @Input() pendingFields: string[];
  @Input() hasPaymentCorrespondaceContactPending: boolean;
  @Input() hasVccPaymentRecipientContactPending: boolean;

  /** address */
  private _address: Address;

  get address(): Address {
    return this._address;
  }

  @Input()
  set address(address: Address) {
    this._address = address;
  }

  bsModalRef: BsModalRef;

  modalTitle: string;
  isCommercial = false;
  states$: Observable<Dropdown[]>;
  cities$: Observable<Dropdown[]>;
  pendingFieldsCard: string[] = [];

  modalComponent: any;

  isLegalDetailsEmpty: boolean;
  legalType: string;
  agencyInfo: Agency;


  constructor(
    private taProfileUtilsService: TaprofileUtilsService,
    private modalService: BsModalService,
    private commonDataService: CommonDataService,
    private translate: TranslateService
  ) { }

  ngOnInit() {

    this.loadOptions();

    switch (this.card) {
      case 'legal':
        this.linkText = this.translate.get('emptyCard.legalLinkTxt');
        this.modalTitle = this.translate.instant('taLegalDetailsCard.title');
        this.modalComponent = LegalDetailsModalComponent;
        break;
      case 'commercial':
        this.linkText = this.translate.get('emptyCard.commercialLinkTxt');
        this.modalTitle = this.translate.instant('taAddressCardCommercial.title');
        this.isCommercial = true;
        this.modalComponent = AddressModalComponent;
        break;
      case 'postal':
        this.linkText = this.translate.get('emptyCard.postalLinkTxt');
        this.modalTitle = this.translate.instant('taAddressCardPostal.title');
        this.modalComponent = AddressModalComponent;
        break;
      case 'contacts':
        this.linkText = this.translate.get('emptyCard.contactsLinkTxt');
        this.modalTitle = this.translate.instant('taContactCard.title');
        this.modalComponent = ContactModalComponent;
        break;
      default:
        break;
    }
  }

  loadOptions() {
    this.states$ = null;
    this.cities$ = null;
    if (this._address && this._address.countryCode) {
      this.states$ = this.commonDataService.loadStatesByCountry(this._address.countryCode);
      this.states$.pipe(take(1)).subscribe();
      let stateId = this._address.stateCode;
      if (!this.commonDataService.hasCountryStates(this._address.countryCode)) {
        stateId = Constants.noStates;
      }
      this.cities$ = this.commonDataService.loadCitiesByCountryAndState(this._address.countryCode, stateId);
      this.cities$.pipe(take(1)).subscribe();
    }
  }

  openModal() {

    const modalOptions: any = {
      class: 'modal-lg',
      initialState: {
        newInfo: this.newInfo,
        address: {},
        title: this.modalTitle,
        isCommercial: this.isCommercial,
        states$: this.states$,
        cities$: this.cities$,
        pendingFieldsCard: this.pendingFieldsCard,
        card: this.card,
        // Options to ContactModalComponent
        action: 'contactAddUpdate',
        taCode: this.taCode,
        pendingFields: this.pendingFields,
        hasPaymentCorrespondaceContact: false,
        hasPaymentCorrespondaceContactPending: this.hasPaymentCorrespondaceContactPending,
        hasVccPaymentRecipientContact: false,
        hasVccPaymentRecipientContactPending: this.hasVccPaymentRecipientContactPending,
      },
    };

    this.bsModalRef = this.modalService.show(this.modalComponent, modalOptions);

  }

}
