import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Constants } from '@app/app.constants';
import { Agency } from '@core/models';
import { TaprofileUtilsService } from '@core/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ta-us-tax-form',
  templateUrl: './us-tax-form.component.html',
  providers: [DatePipe],
})
export class UsTaxFormComponent implements OnInit, OnDestroy {

  labelColumnCssClasses = 'col-lg-3';
  fieldColumnCssClasses = 'col-lg-9';
  @Input() newInfo: Agency;
  @Input() pendingFieldsCard: string[];
  @Output() selectedDateChanged: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() closeModalFromChild = new EventEmitter<string>();

  taxForm: FormGroup;
  dateFormatUser: string;
  pendingChange = false;
  cont = 0;

  // status W9 options
  statusW9Options = Constants.STATUSW9;
  ssnEinOptions = Constants.SSNEIN;

  subscription: Subscription;
  countryCodeSelected: string;
  isValidVat: boolean;

  constructor(
    private fb: FormBuilder,
    private profileService: TaprofileUtilsService
  ) { }

  ngOnInit() {

    this.initForm();

    this.subscription = new Subscription();

    this.subscription.add(
      this.profileService.userInfo.subscribe(userInfo => {
        this.dateFormatUser = userInfo ? userInfo.config.dateformat : 'DD/MM/YYYY';
      }));

    this.subscription.add(this.profileService.getSelectedLegalCountryCode().subscribe(countryCode => {
      this.countryCodeSelected = countryCode;

      this.setSsnOrEinValue();
    }));

    this.disablePendingFields();

    this.subscription.add(this.profileService.isValidVat$.subscribe(isValidVat => {
      this.isValidVat = isValidVat;
    }));
  }

  ngOnDestroy() {
    this.profileService.setIsValidVat(true);
    this.subscription.unsubscribe();
  }

  private initForm() {
    this.taxForm = this.fb.group({
      ssnOrEinSelect: ['', Validators.required],
      ssnOrEin: ['', [Validators.required]],
      statusSelect: ['', Validators.required],
      exemptBWSelect: ['2', Validators.required],
      startDate: ['', Validators.required],

    });
  }

  get ssnOrEinSelect() { return this.taxForm.get('ssnOrEinSelect'); }
  get ssnOrEin() { return this.taxForm.get('ssnOrEin'); }
  get statusSelect() { return this.taxForm.get('statusSelect'); }
  get exemptBWSelect() { return this.taxForm.get('exemptBWSelect'); }
  get startDate() { return this.taxForm.get('startDate'); }

  setSsnOrEin(value: string) { this.ssnOrEin.patchValue(value); }
  setSsnOrEinSelect(value: string) { this.ssnOrEinSelect.patchValue(value); }
  setStatusSelect(value: string) { this.statusSelect.patchValue(value); }
  setexemptBWSelect(value: string) { this.exemptBWSelect.patchValue(value); }
  setStartDate(value: string) { this.startDate.patchValue(new Date(value)); }

  setSsnOrEinValue() {
    this.setStartDate(new Date().getFullYear().toString());

    if (this.newInfo && this.newInfo.legal && this.newInfo.legal.countryCode === this.countryCodeSelected) {

      this.setSsnOrEinSelect(this.newInfo.legal.primaryTaxLabel);
      this.setSsnOrEin(this.newInfo.legal.primaryTax);
      this.setStatusSelect(this.newInfo.legal.businessType);
      this.setexemptBWSelect(this.newInfo.legal.exemptBw);
      if (this.newInfo.legal.primaryTaxFrom) {
        this.setStartDate(this.newInfo.legal.primaryTaxFrom);
      }

    }
  }

  disablePendingFields() {
    if (this.pendingFieldsCard) {
      this.pendingFieldsCard.forEach(pendingField => {
        this.disableControl(this.taxForm.get(pendingField), this.taxForm.get(pendingField).value);
      });
    }
  }

  private disableControl(control: AbstractControl, value: string) {
    control.patchValue(value);
    control.disable();
  }


  isPendingReview(field: string) {
    if (this.pendingFieldsCard) {
      return this.pendingFieldsCard.indexOf(field) !== -1;
    }
  }

  emitChange(selectedDate: Date) {

    this.cont = this.cont + 1;
    if (this.cont > 1) {
      this.taxForm.markAsDirty();
      this.selectedDateChanged.emit(selectedDate);
    }
  }

  close() {
    this.closeModalFromChild.emit();
  }

  getEnableStartDate(newInfo: Agency): boolean {
    if (!newInfo || !newInfo.legal || !newInfo.legal.primaryTax) {
      return true;
    } else {
      return this.ssnOrEin && (
        (newInfo.legal.primaryTax && newInfo.legal.primaryTax !== this.ssnOrEin.value) ||
        (newInfo.legal.primaryTaxLabel && newInfo.legal.primaryTaxLabel !== this.ssnOrEinSelect.value));
    }
  }

}
