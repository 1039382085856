import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Agency, TaxHist } from '@core/models';
import { LabelTax } from '@core/models/Label-tax.model';
import { CommonDataService, TaprofileUtilsService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { AddressFormComponent } from '@shared/components/address';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'ta-default-tax-form',
  templateUrl: './default-tax-form.component.html',
  providers: [AddressFormComponent ],
})
export class DefaultTaxFormComponent implements OnInit, OnDestroy, AfterViewChecked {

  labelColumnCssClasses = 'col-lg-3';
  fieldColumnCssClasses = 'col-lg-9';
  @Input() newInfo: Agency;
  @Input() labelsTax$: Observable<LabelTax[]>;
  @Input() pendingFieldsCard: string[];
  @Output() closeModalFromChild = new EventEmitter<string>();

  isTax2Registered = false;
  isTax1ValueRegistered = false;
  isTax2ValueRegistered = false;
  isCountryWithTax2AlwaysShow = false;
  isTax2LinkShow = false;
  isTax2BlockHide = true;

  tinHist: TaxHist[];

  taxForm: FormGroup;

  subscription: Subscription;
  countryCodeSelected: string;

  isValidVat: boolean;
  isValidTax: boolean;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private commonService: CommonDataService,
    private profileService: TaprofileUtilsService,
    private cdRef: ChangeDetectorRef

  ) { }

  ngOnInit() {

    this.initForm();

    this.subscription = new Subscription();

    this.subscription.add(this.profileService.getSelectedLegalCountryCode().subscribe(countryCode => {
      this.isTax2Registered = false;
      this.isTax1ValueRegistered = false;
      this.isTax2ValueRegistered = false;
      this.isCountryWithTax2AlwaysShow = false;
      this.isTax2LinkShow = false;
      this.isTax2BlockHide = true;
      this.isValidVat = true;
      this.isValidTax = true;

      this.countryCodeSelected = countryCode;

      this.isCountryWithTax2AlwaysShow = this.commonService.hasCountryWithTax2AlwaysShow(this.countryCodeSelected);

      this.existsTax2Label();

      this.resetTax1Value();
      this.setTax1Label();
      this.setTax1Value();

      this.resetTax2Value();
      this.setTax2Label();
      this.setTax2Value();

      this.setShowTax2Block();

    }));

    this.disablePendingFields();

    this.subscription.add(this.profileService.isValidVat$.subscribe(isValidVat => {
      this.isValidVat = isValidVat;
    }));

    this.subscription.add(this.profileService.isValidTax$.subscribe(isValidTax => {
      this.isValidTax = isValidTax;
    }));

  }

  ngOnDestroy() {
    this.profileService.setIsValidVat(true);
    this.profileService.setIsValidTax(true);
    this.subscription.unsubscribe();
  }

  private initForm() {
    this.taxForm = this.fb.group({
      tax1Prefix: [{ value: '', disabled: true }],
      tax1: [''],
      tax2: [''],
      tax1Label: [''],
      tax2Label: [''],

    });
  }


  get tax1Prefix() { return this.taxForm.get('tax1Prefix'); }
  get tax1() { return this.taxForm.get('tax1'); }
  get tax1Label() { return this.taxForm.get('tax1Label'); }
  get tax2() { return this.taxForm.get('tax2'); }
  get tax2Label() { return this.taxForm.get('tax2Label'); }


  /**
   * Checks if the field is invalid
   *
   * @param formControl the field to be validated
   * @returns true if the field invalid
   */
  isInvalid(formControl: AbstractControl): boolean {
    if (formControl.errors) {
      return formControl.invalid;
    }
    return false;
  }
  /*
  * Send error message if the field is invalid
  */
  isInvalidMessage(formControl: AbstractControl, alert: string): string {
    if (formControl.invalid) {
      return this.translate.instant('forms.requiredTaxOrVat');
    }
  }

  /** TAX1 */
  setTax1Value() {
    if (this.newInfo && this.newInfo.legal) {
      this.tax1Prefix.patchValue(this.countryCodeSelected);
      const vat1: string = this.newInfo.legal.primaryTax;
      if (vat1 && this.newInfo.legal.countryCode === this.countryCodeSelected) {
        this.tax1.patchValue(vat1);
        if (this.tax1.value) {
          this.isTax1ValueRegistered = true;
        }
      } else {
        this.tax1.patchValue(null);
        this.isTax1ValueRegistered = false;
      }
    }
  }

  resetTax1Value() {
    this.tax1Prefix.patchValue(null);
    this.tax1.patchValue(null);
    this.tax1Label.patchValue(null);
  }

  setTax1Label() {
    this.tax1Label.patchValue(null);

    this.commonService.labelTax.subscribe(labelsTax => {
      if (labelsTax[this.countryCodeSelected]) {
        this.tax1Label.patchValue(labelsTax[this.countryCodeSelected][0]);
      }
    });
  }

  /** TAX2 */
  setTax2Value() {
    if (this.newInfo && this.newInfo.legal && this.isTax2Registered) {
      const vat2: string = this.newInfo.legal.secondaryTax;
      if (vat2 && this.newInfo.legal.countryCode === this.countryCodeSelected) {
        this.tax2.patchValue(vat2);
        if (this.tax2.value) {
          this.isTax2ValueRegistered = true;
        }

      } else {
        this.tax2.patchValue(null);
        this.isTax2ValueRegistered = false;
      }
    }
  }

  resetTax2Value() {
    this.tax2.patchValue(null);
    this.tax2Label.patchValue(null);
  }

  setTax2Label() {
    this.tax2Label.patchValue(null);

    this.commonService.labelTax.subscribe(labelsTax => {
      if (this.isTax2Registered) {
        this.tax2Label.patchValue(labelsTax[this.countryCodeSelected][1]);
      }
    });
  }

  existsTax2Label() {
    this.commonService.labelTax.subscribe(labelsTax => {
      if (labelsTax[this.countryCodeSelected] && labelsTax[this.countryCodeSelected][1]) {
        this.isTax2Registered = true;
      } else {
        this.isTax2Registered = false;
      }
    });
  }


  disablePendingFields() {

    if (this.pendingFieldsCard) {
      this.pendingFieldsCard.forEach(pendingField => {
        if (this.taxForm.get(pendingField)) {
          this.disableControl(this.taxForm.get(pendingField), this.taxForm.get(pendingField).value);
        }
      });
    }
  }

  private disableControl(control: AbstractControl, value: string) {
    control.patchValue(value);
    control.disable();
  }

  isPendingReview(field: string) {
    if (this.pendingFieldsCard) {
      return this.pendingFieldsCard.indexOf(field) !== -1;
    }
  }

  close() {
    this.closeModalFromChild.emit();
  }

  setShowTax2Block() {

    if (this.isTax2Registered && (this.isCountryWithTax2AlwaysShow || this.isTax2ValueRegistered)) {
      this.isTax2BlockHide = false;
    }

    if (this.isTax2Registered
      && !this.isTax1ValueRegistered
      && !this.isTax2ValueRegistered
      && !this.isCountryWithTax2AlwaysShow) {
      this.isTax2LinkShow = true;
    }
  }

  showTax2Block() {
    if (this.isTax2BlockHide) {
      this.isTax2BlockHide = false;
    }
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

}
