import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProfileHistory } from '@core/models/profile-history.model';
import { UserInfo } from '@core/models/user-info.model';
import { TaprofileUtilsService } from '@core/services/taprofile-utils.service';
import { TitleService } from '@core/services/title.service';
import { TranslateService } from '@ngx-translate/core';
import { UserDateHourPipe, UserDatePipe } from '@shared/pipes';
import { Subscription } from 'rxjs';
import { flatMap, mergeMap } from 'rxjs/operators';
import { Constants } from '@app/app.constants';

@Component({
  selector: 'ta-profile-history',
  templateUrl: './profile-history.component.html',
  styles: [],
})

export class ProfileHistoryComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();

  public hasMatches = false;
  public isEmpty = true;
  public userInfo: UserInfo;

  public activePage = 1;
  private pageSize = 5;
  public totalChanges = 0;
  public changeListFrom: number;
  public changeListTo: number;
  public showPagination: boolean;

  public isLastPage: boolean;
  public isFirstPage: boolean;

  public profileHistoryRows: ProfileHistory[];

  private _profileHistoryQuery: string;

  public get profileHistoryQuery(): string {
    return this._profileHistoryQuery;
  }

  public set profileHistoryQuery(value: string) {
    this._profileHistoryQuery = value;

    this.filterProfiles();
  }

  public filteredProfilesHistory: ProfileHistory[];

  constructor(
    private taProfileUtilsService: TaprofileUtilsService,
    private userDatePipe: UserDatePipe,
    private userDateHourPipe: UserDateHourPipe,
    private translate: TranslateService,
    private titleService: TitleService
  ) { }

  ngOnInit() {

    const statusW9map = Constants.STATUSW9;
    const exempBWmap = Constants.EXEMPBW;

    this.titleService.setPageTitle('tabs.profileHistory');

    // Subscribe to AgencyInfo and call audit service
    this.subscription.add(
      this.taProfileUtilsService.agencyInfo.pipe(
        mergeMap(() => {
          return this.taProfileUtilsService.profileHistoryAudit;
        }))
        .subscribe(profileHistoryAudit => {
          this.profileHistoryRows = profileHistoryAudit;
          this.filteredProfilesHistory = profileHistoryAudit;
          this.filteredProfilesHistory.forEach(audit => {

            const dummy: any = null;
            if (audit.fieldLabel === Constants.AUDIT_LABEL_EXEMPTBW) {
              audit.newValue = this.translateW9(exempBWmap, audit.newValue);
              audit.oldValue = this.translateW9(exempBWmap, audit.oldValue);
            }

            if (audit.fieldLabel === Constants.AUDIT_LABEL_TAX_BUSINESSTYPE) {
              audit.newValue = this.translateW9(statusW9map, audit.newValue);
              audit.oldValue = this.translateW9(statusW9map, audit.oldValue);
            }

          });
          this.changeList();

          this.filteredProfilesHistory = this.splitLabelParams(this.filteredProfilesHistory);
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private translateW9(map: any, value: any): string {
    let dummy: any = null;
    if (map) {
      dummy = map.find(item => item.code === value);
    }
    return dummy ? dummy.display.valueOf() : value;

  }
  public changeList(): void {
    if (this.profileHistoryRows) {
      this.hasMatches = this.profileHistoryRows.length > 0;
      this.isEmpty = this.profileHistoryRows.length === 0;
      this.totalChanges = this.profileHistoryRows.length;
    }
    this.showPagination = this.totalChanges > this.pageSize;
    this.activePage = 1;
    this.onChangePage(0);
  }

  public onChangePage(increment: number): void {
    this.activePage = this.activePage + increment;

    this.isFirstPage = this.activePage === 1;
    this.isLastPage = this.activePage >= (this.totalChanges / this.pageSize);

    this.changeListFrom = (this.activePage - 1) * this.pageSize;
    this.changeListTo = this.activePage * this.pageSize;

    if (this.changeListTo > this.totalChanges) {
      this.changeListTo = this.totalChanges;
    }

  }

  public isPending(status: string) {
    return status === 'P';
  }

  public isRejected(status: string) {
    return status === 'R';
  }

  public filterProfiles(): void {
    if (this.profileHistoryQuery) {

      const caseInsensitiveQuery = this.profileHistoryQuery.toLowerCase();

      this.filteredProfilesHistory = this.profileHistoryRows.filter((profileHistory: ProfileHistory) => {
        return profileHistory.createdBy.toLowerCase().indexOf(caseInsensitiveQuery) > -1
          || this.userDateHourPipe.transform(profileHistory.createdOn).indexOf(caseInsensitiveQuery) > -1 // created_on | pipeDate
          || profileHistory.fieldLabel.toLowerCase().indexOf(caseInsensitiveQuery) > -1
          || ((profileHistory.newValue !== null) &&
            (profileHistory.newValue !== undefined) ? profileHistory.newValue.toLowerCase().indexOf(caseInsensitiveQuery) > -1 : null)
          || ((profileHistory.oldValue !== null) &&
            (profileHistory.oldValue !== undefined) ? profileHistory.oldValue.toLowerCase().indexOf(caseInsensitiveQuery) > -1 : null)
          || this.statusDescription(profileHistory).toLowerCase().indexOf(caseInsensitiveQuery) > -1 // status desciption
          || this.userDatePipe.transform(profileHistory.statusDate).indexOf(caseInsensitiveQuery) > -1 // status_date | pipeDate
        ;
      });
    } else {
      this.filteredProfilesHistory = this.profileHistoryRows;
    }

    this.hasMatches = this.filteredProfilesHistory && this.filteredProfilesHistory.length > 0;
    this.showPagination = this.filteredProfilesHistory.length > this.pageSize;
    this.activePage = 1;
    this.onChangePage(0);
  }

  splitLabelParams(filteredProfilesHistory: ProfileHistory[]): ProfileHistory[] {
    filteredProfilesHistory.forEach(profileHistory => {
      const labelparam: string[] = profileHistory.fieldLabel.split('|');
      profileHistory.fieldLabel = labelparam[0];
      if (labelparam.length > 1) {
        profileHistory.fieldLabelParam = labelparam[1];
      } else {
        profileHistory.fieldLabelParam = null;
      }
    });

    return filteredProfilesHistory;
  }

  public statusDescription(profileHistoryRow: ProfileHistory) {
    if (this.isPending(profileHistoryRow.status)) {
      return this.translate.instant('profileHistory.statusDspPending');
    } else if (this.isRejected(profileHistoryRow.status)) {
      return this.translate.instant('profileHistory.statusDspRejected')
        + ' ' + this.userDatePipe.transform(profileHistoryRow.statusDate);
    } else {
      return this.translate.instant('profileHistory.statusDspApproved')
        + ' ' + this.userDatePipe.transform(profileHistoryRow.statusDate);
    }
  }


}
