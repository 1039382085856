import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgencyInfoResolver, CountryResolver, UserInfoResolver } from '@core/resolvers';
import { AuthGuard } from '../../auth/services/auth.guard';
import { ProfileComponent } from './profile.component';

const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    resolve: {
      userInfo: UserInfoResolver,
      agencyInfo: AgencyInfoResolver,
      countries: CountryResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProfileRoutingModule { }
