import { ReplacePipe } from './replace.pipe';
import { UserDatePipe } from './user-date.pipe';
import { UserDateHourPipe } from './user-date-hour.pipe';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    ReplacePipe,
    UserDatePipe,
    UserDateHourPipe,
  ],
  providers: [
    ReplacePipe,
    UserDatePipe,
    UserDateHourPipe,
  ],
  exports: [
    ReplacePipe,
    UserDatePipe,
    UserDateHourPipe,
  ],
})
export class PipesModule { }
