import { Component, OnInit } from '@angular/core';
import { Constants } from '../../../../app.constants';

@Component({
  selector: 'ta-connect-card',
  templateUrl: './connect-card.component.html',
})
export class ConnectCardComponent implements OnInit {

  customerSupportUrl: string = Constants.URL_CUSTOMER_SUPPORT;
  contactSalesURl: string = Constants.URL_CONTACT_SALES;

  constructor() { }

  ngOnInit() {
  }

}
