import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BsDatepickerConfig, BsDatepickerDirective } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'ta-date-picker',
  templateUrl: './date-picker.component.html',
})
export class DatePickerComponent implements OnInit {

  @ViewChild(BsDatepickerDirective, { static: true }) datePicker: BsDatepickerDirective;

  @Input() placeholderText: string;
  @Input() minimumDate: Date;
  @Input() maximumDate: Date;
  @Input() isValid = true;
  @Input() disabled = false;
  @Input() dateInputFormat: string;
  @Output() selectedDateChanged: EventEmitter<Date> = new EventEmitter<Date>();

  /* selectedDate */
  private _selectedDate: Date;

  @Input()
  get selectedDate(): Date {
    return this._selectedDate;
  }

  set selectedDate(value: Date) {
    this._selectedDate = value;
    this.selectedDateChanged.emit(this._selectedDate);
  }

  configuration: Partial<BsDatepickerConfig> = {
    showWeekNumbers: false,

    // Source CSS classes are defined in
    // src/styles/_datepickers.scss
    containerClass: 'theme-ods',
    customTodayClass: 'today',
  };

  constructor() { }

  ngOnInit() {
    this.placeholderText = this.dateInputFormat ? this.dateInputFormat : 'mm/dd/yyyy';
    this.configuration.dateInputFormat = this.placeholderText;
  }

  onDateButtonClicked(): void {
    this.datePicker.show();
  }

}
