<input *ngIf="!isEmpty" #profileHistorySearchInput type="text" class="form-control mb-5"
  placeholder="{{'profileHistory.filter' | translate}}" [(ngModel)]="profileHistoryQuery"
  name="profileHistoryQuery" taUpperCase />

<div *ngIf="!hasMatches && !isEmpty">
  <div class="marquee">
    <i class="far fa-search marquee-icon"></i>
    <h1 class="marquee-heading">{{'profileHistory.notFoundTitle' | translate}}</h1>
    <h2 class="marquee-message" [innerHTML]="'profileHistory.notFoundText' | translate"></h2>
  </div>
</div>

<div *ngIf="isEmpty">
  <div class="marquee">
    <i class="far fa-history marquee-icon"></i>
    <h1 class="marquee-heading">{{'profileHistory.notRecordsTitle' | translate}}</h1>
    <h2 class="marquee-message"
      [innerHTML]="'profileHistory.notRecordsText' | translate | replace: {url: '#/profile'}"></h2>
  </div>
</div>

<div *ngIf="hasMatches && !isEmpty" class="card card-table">
  <div class="card-header">
    <h2 class="card-title">{{'profileHistory.title' | translate}}</h2>
  </div>
  <div class="card-body">
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>{{'profileHistory.UpdatedField' | translate}}</th>
            <th>{{'profileHistory.UpdatedValue' | translate}}<span
                class="text-caption">{{'profileHistory.PreviousValue' | translate}}</span></th>
            <th>{{'profileHistory.UpdatedBy' | translate}}<span
                class="text-caption">{{'profileHistory.UpdatedOn' | translate}}</span></th>
            <th>{{'profileHistory.Status' | translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let profileHistoryRow of filteredProfilesHistory | slice: changeListFrom : changeListTo">
            <td>{{'profileHistory.' + profileHistoryRow.fieldLabel | translate | replace: {param: profileHistoryRow.fieldLabelParam} }}</td>
            <td>{{profileHistoryRow.newValue || "&nbsp;"}}<span
                class="text-caption">{{profileHistoryRow.oldValue || "&nbsp;"}}</span></td>
            <td><a href="mailto:{{profileHistoryRow.createdBy}}">{{profileHistoryRow.createdBy}}</a><span
                class="text-caption">{{profileHistoryRow.createdOn | userDateHour }}</span></td>
            <td>
              <span [ngClass]="{
                  'text-warning': isPending(profileHistoryRow.status),
                  'text-danger': isRejected(profileHistoryRow.status),
                  'font-weight-bold': isPending(profileHistoryRow.status) || isRejected(profileHistoryRow.status)
                }">
                {{ statusDescription(profileHistoryRow) }}
              </span>
              <span *ngIf="isPending(profileHistoryRow.status)" class="text-caption"
                [innerHTML]="'profileHistory.isPending' | translate | replace: {url: '#/profile'}"></span>
              <span *ngIf="isRejected(profileHistoryRow.status)" class="text-caption"
                [innerHTML]="'profileHistory.isRejected' | translate"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <ng-container *ngIf="showPagination">
    <div class="card-footer">
      <div class="form-inline float-right">
        <span class="d-none d-md-inline mr-3"
          [innerHTML]="'common.pagination' | translate | replace: {from: changeListFrom+1, to: changeListTo, total: totalChanges }"></span>
        <div aria-label="Data Row Page" class="btn-group" role="group">
          <button aria-label="Previous" class="btn btn-secondary" type="button" (click)="onChangePage(-1)"
            [disabled]="isFirstPage">
            <i class="fas fa-caret-left"></i>
            <span class="d-none d-md-inline ml-2">{{'common.previousBtn' | translate}}</span>
          </button>
          <button aria-label="Next" class="btn btn-secondary" type="button" (click)="onChangePage(1)"
            [disabled]="isLastPage">
            <span class="d-none d-md-inline mr-2">{{'common.nextBtn' | translate}}</span>
            <i class="fas fa-caret-right"></i>
          </button>
        </div>
      </div>
    </div>
  </ng-container>

</div>