import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxDesignSystemComponentsModule } from '@onyx/ngx-design-system-components';
import { SharedModule } from '@shared/shared.module';
import { MaintanceAppComponent } from '../maintenance/maintance.component';


import { MaintAppComponent } from './component/maint-app.component';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../../assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    MaintAppComponent,
    MaintanceAppComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule,
    NgxDesignSystemComponentsModule,
    TranslateModule,
    RouterModule.forRoot([]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
    }),
  ],
  providers: [],
  bootstrap: [MaintAppComponent],
})
export class MaintAppModule { }
