<div class="modal-header">
  <h5 class="modal-title" id="commercialDetailsModalLabel">{{title }}</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ta-address-form [addressInput]="address" [states$]="states$" [cities$]="cities$"
    [pendingFieldsCard]="pendingFieldsCard" [card]="card" (closeModalFromChild)="closeModal()"></ta-address-form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary"
    (click)="closeModal()">{{'common.discardBtn' | translate}}</button>
  <button type="button" class="btn btn-primary" (click)="save()"
    [disabled]="!addressForm.addressForm.valid || !addressForm.addressForm.dirty">{{'common.saveBtn' | translate}}</button>
</div>