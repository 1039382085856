import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { Constants } from '@app/app.constants';
import { Profile, SettingsProfile, UserInfo } from '@core/models';
import { SettingsInfo } from '@core/models/settings-info.model';
import { TaprofileUtilsService } from '@core/services';
import { Subscription } from 'rxjs';
import { flatMap, mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'ta-user-settings',
  templateUrl: './user-settings.component.html',
})
export class UserSettingsComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();

  dateFormatTip: string;

  userInfo: UserInfo;
  userProfiles: Profile[];

  settingsForm: FormGroup;

  language$: any[] = [];
  dateFormat$: any[] = [];

  currentProfile: Profile;

  formattedDate: String = '';

  constructor(
    private taProfileUtilsService: TaprofileUtilsService,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private router: Router,
    private datePipe: DatePipe,
    private translate: TranslateService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.subscription.add(this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    }));
  }

  ngOnInit() {
    this.language$ = Constants.LANGUAGE_OPTIONS;
    this.dateFormat$ = Constants.DATE_FORMAT;

    this.initForm();

    this.subscription.add(
      this.taProfileUtilsService.userInfo.pipe(
        mergeMap(userInfo => {
          this.userInfo = userInfo;
          if (userInfo && userInfo.config) {
            this.languageSelect.patchValue(userInfo.config.language);
            this.dateformatSelect.patchValue(userInfo.config.dateformat);
          } else {
            this.languageSelect.patchValue('en');
            this.dateformatSelect.patchValue('MM/DD/YYYY');
          }

          this.setDateFormatTip();

          return this.taProfileUtilsService.getProfiles();
        }))
        .subscribe(profiles => {
          this.userProfiles = profiles;
          if (this.userInfo) {
            this.setCurrentProfile(this.userInfo.currentProfileId);
          }
        })
    );

    this.subscription.add(
      this.taProfileUtilsService.selectedProfile.subscribe(currentProfile => {
        this.currentProfile = currentProfile;
        if (this.currentProfile) {
          this.setCurrentProfile(this.currentProfile.id);
        }
      }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private initForm() {
    this.settingsForm = this.fb.group({
      profileSelect: [''],
      newAlias: ['',
        [
          Validators.required,
          Validators.maxLength(80),
          Validators.pattern(Constants.LATIN_PATTERN),
        ],
      ],
      languageSelect: [''],
      dateformatSelect: [''],
    });
  }

  /**
   * Checks if the field has been modified and is invalid
   *
   * @param formControl the field to be validated
   * @returns true if the field invalid
   */
  isInvalid(formControl: AbstractControl): boolean {
    return formControl.invalid && formControl.touched;
  }

  /*
   * Send error message if the field has been modified and is invalid
   */
  isInvalidMessage(formControl: AbstractControl): string {
    if (formControl.invalid && formControl.touched) {
      if (formControl.errors.required) {
        return this.translate.instant('forms.required');
      }
      if (formControl.errors.maxlength) {
        return this.translate.instant('forms.maxLength');
      }
      if (formControl.errors.minlength) {
        return this.translate.instant('forms.minLength');
      }
      if (formControl.errors.pattern) {
        return this.translate.instant('forms.latinAlphabet');
      }
    }
  }

  get profileSelect() { return this.settingsForm.get('profileSelect'); }
  get newAlias() { return this.settingsForm.get('newAlias'); }
  get languageSelect() { return this.settingsForm.get('languageSelect'); }
  get dateformatSelect() { return this.settingsForm.get('dateformatSelect'); }

  onChangeProfile() {
    this.setCurrentProfile(this.profileSelect.value);
  }

  setCurrentProfile(currentProfileId: string) {
    if (this.userProfiles) {
      const newProfile = this.userProfiles.find(x => x.id === currentProfileId);
      this.profileSelect.patchValue(newProfile.id);
      this.newAlias.patchValue(newProfile.alias);
    }
  }

  saveSettings() {
    const settingsProfile: SettingsProfile[] = [];

    settingsProfile.push({ id: this.profileSelect.value, newAlias: this.newAlias.value });

    const settingsInfo: SettingsInfo = { userInfo: this.userInfo, userProfiles: settingsProfile };

    settingsInfo.userInfo = this.userInfo;
    settingsInfo.userInfo.config.language = Constants.LANGUAGE_OPTIONS.find(lang => lang.code === this.languageSelect.value).gecoCode;
    settingsInfo.userInfo.config.dateformat = this.dateformatSelect.value;

    this.taProfileUtilsService.saveSettings(settingsInfo).subscribe(response => {
      if (response.successful) {
        this.translateService.use(this.userInfo.config.language);
      }
    });
  }

  reload() {
    window.location.reload();
  }

  setDateFormatTip() {

    const formatGecoSelected = this.dateformatSelect.value ? this.dateformatSelect.value : Constants.SETTINGS_DATE_FORMAT_DEFAULT_GECO;

    const taProfileFormats = Constants.DATE_FORMAT;
    const formatTaProfileFiltered = taProfileFormats.find(item => item.geco === formatGecoSelected);

    if (formatTaProfileFiltered) {
      const formatTaProfile = formatTaProfileFiltered.taProfile.valueOf();
      this.formattedDate = this.datePipe.transform(Constants.SETTINGS_DATE_FORMAT_EXAMPLE, formatTaProfile);
    }
  }
}
