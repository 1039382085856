<form [formGroup]="taxForm">

  <div class="form-group row">
    <label class="{{labelColumnCssClasses}} col-form-label">{{'usTaxForm.tinType' | translate}}</label>
    <!-- Tin type -->
    <div class="{{fieldColumnCssClasses}}">
      <select class="form-control" formControlName="ssnOrEinSelect">
        <option value="">{{'common.select' | translate}}</option>
        <option *ngFor="let ssnEin of ssnEinOptions" [value]="ssnEin.code">
          {{ssnEin.display}}
        </option>
      </select>
      <ng-container *ngTemplateOutlet="pendingReviewHelpTex;context:{pendingField: 'ssnOrEinSelect'}"></ng-container>
    </div>
  </div>
  <div class="form-group row">
    <label class="{{labelColumnCssClasses}} col-form-label">{{'usTaxForm.tin' | translate}}</label>
    <!-- TIN -->
    <div class="{{fieldColumnCssClasses}}">
      <input class="form-control" placeholder="TIN" type="text" [class.is-invalid]="!isValidVat"
        formControlName="ssnOrEin">
      <div *ngIf="!isValidVat" class="invalid-feedback d-block">
        <span>{{'usTaxForm.invalidTin' | translate}}</span>
      </div>
      <ng-container *ngTemplateOutlet="pendingReviewHelpTex;context:{pendingField: 'ssnOrEin'}"></ng-container>
    </div>
  </div>
  <div class="form-group row">
    <label class="{{labelColumnCssClasses}} col-form-label">{{'usTaxForm.status' | translate}}</label>
    <!-- Status -->
    <div class="{{fieldColumnCssClasses}}">
      <select class="form-control" formControlName="statusSelect">
        <option value="">{{'common.select' | translate}}</option>
        <option *ngFor="let statusW9 of statusW9Options" [value]="statusW9.code">
          {{statusW9.display}}
        </option>
      </select>
      <ng-container *ngTemplateOutlet="pendingReviewHelpTex;context:{pendingField: 'statusSelect'}"></ng-container>
    </div>
  </div>
  <div class="form-group row">
    <label class="{{labelColumnCssClasses}} col-form-label">{{'usTaxForm.exemptBW' | translate}}</label>
    <!-- Exempt from Business Withholdings? -->
    <div class="{{fieldColumnCssClasses}}">
      <select class="form-control" formControlName="exemptBWSelect">
        <option value="1">{{'common.Yes' | translate}}</option>
        <option value="2">{{'common.No' | translate}}</option>
      </select>
      <ng-container *ngTemplateOutlet="pendingReviewHelpTex;context:{pendingField: 'exemptBWSelect'}"></ng-container>
    </div>
  </div>

  <div class="form-group row">
    <label class="{{labelColumnCssClasses}} col-form-label">{{'usTaxForm.startDate' | translate}}</label>
    <!-- Start Date -->
    <div class="{{fieldColumnCssClasses}}">
      <ta-date-picker [selectedDate]="startDate.value" [dateInputFormat]=dateFormatUser
        [placeholderText]=dateFormatUser [disabled]="!getEnableStartDate(newInfo) || isPendingReview('startDate')"
        (selectedDateChanged)="emitChange($event)">
      </ta-date-picker>
      <ng-container *ngTemplateOutlet="pendingReviewHelpTex;context:{pendingField: 'startDate'}"></ng-container>
    </div>
  </div>

</form>

<ng-template #pendingReviewHelpTex let-pendingField=pendingField>
  <small *ngIf="isPendingReview(pendingField)" class="form-text text-muted">
    {{'pendingChanges.profileTxt1' | translate}}
    <a href="#/profile-history" class="alert-link"
      (click)="close()">{{'pendingChanges.profileTxt' | translate}}</a>
    {{'pendingChanges.profileTxt2' | translate}}
  </small>
</ng-template>