<form [formGroup]="accessRequestForm">
  <h1 class="content-title">{{'accessRequest.accessRequestTitle' | translate}}</h1>
  <p
    [innerHTML]="'accessRequest.accessRequestHeader' | translate| replace: {url: '#/auth/login', eMailContactCustomerService: onyxCustomerSupportEmail}">
  </p>
  <p
    [innerHTML]="'accessRequest.userGuide' | translate | replace: {url: userGuideLink}">
  </p>
  <p>{{'accessRequest.accessRequestAllFieldsRequired' | translate}}</p>

  <div class="card">
    <div class="card-header">
      <h2 class="card-title">{{'accessRequest.profileTitle' | translate}}</h2>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group row">
            <label class="col-lg-3 col-form-label">{{'accessRequest.profileType' | translate}}</label>
            <div class="col-lg-9">
              <select class="form-control" formControlName="profileType" name="profileType"
                (change)="onProfileTypeChange()">
                <option value="agency" selected>{{'accessRequest.profileTypeSelectTravelAgency' | translate}}</option>
                <option value="group">{{'accessRequest.profileTypeSelectTravelAgencyGroup' | translate}}</option>
              </select>
            </div>
          </div>

          <div *ngIf="profileType.value === 'agency'" class="form-group row"
            [class.form-group-invalid]="isInvalid(agencyType) || isInvalid(agencyCode)">
            <label class="col-lg-3 col-form-label">{{'accessRequest.profileAgencyId' | translate}}</label>
            <div class="col-lg-9">
              <div class="row form-control-segments">
                <div class="col-4">
                  <select class="form-control" [class.is-invalid]="isInvalid(agencyType)" formControlName="agencyType"
                    name="agencyType" (change)="onAgencyTypeChange()">
                    <option value="" disabled>{{'accessRequest.accessRequestDropdownSelect' | translate}}</option>
                    <option *ngFor="let agencyTypeRow of agencyTypeList" [value]="agencyTypeRow.code">
                      {{agencyTypeRow.display}}
                    </option>
                  </select>
                  <div class="invalid-feedback" *ngIf="isInvalid(agencyType)">
                    <span>{{isInvalidMessage(agencyType) }}</span>
                  </div>
                </div>
                <div class="col-8">
                  <input type="text" taUpperCase class="form-control" placeholder="{{agencyTypeDisplay}}"
                    [class.is-invalid]="isInvalid(agencyCode)" formControlName="agencyCode" name="agencyCode">
                  <div class="invalid-feedback" *ngIf="isInvalid(agencyCode)">
                    <span>{{isInvalidMessage(agencyCode) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-form-label">
          {{'accessRequest.profileTypeMsg' | translate}}
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-header">
      <h2 class="card-title">{{'accessRequest.userDetailsTitle' | translate}}</h2>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-lg">
          <div class="card-section">
            <div class="card-section-header">
              <h3 class="card-section-title">{{'accessRequest.userDetailsCompany' | translate}}</h3>
            </div>
            <div class="card-section-body">
              <div class="form-group row" [class.form-group-invalid]="isInvalid(companyName)">
                <label class="col-lg-3 col-form-label">{{'accessRequest.userDetailsCompanyName' | translate}}</label>
                <div class="col-lg-9">
                  <input type="text" taUpperCase class="form-control"
                    placeholder="{{'accessRequest.userDetailsCompanyName' | translate}}"
                    [class.is-invalid]="isInvalid(companyName)" formControlName="companyName" name="companyName">
                  <div class="invalid-feedback">
                    <span>{{isInvalidMessage(companyName) }}</span>
                  </div>
                </div>
              </div>

              <div class="form-group row" [class.form-group-invalid]="isInvalid(companyURL)">
                <label class="col-lg-3 col-form-label">{{'accessRequest.userDetailsCompanyURL' | translate}}</label>
                <div class="col-lg-9">
                  <input type="text" taUpperCase class="form-control"
                    placeholder="{{'accessRequest.placeHolderCompanyURL' | translate}}"
                    [class.is-invalid]="isInvalid(companyURL)" formControlName="companyURL" name="companyURL">
                  <div class="invalid-feedback">
                    <span>{{isInvalidMessage(companyURL) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-section mb-lg-0">
            <div class="card-section-header">
              <h3 class="card-section-title">{{'accessRequest.userDetailsAddress' | translate}}</h3>
            </div>
            <div class="card-section-body">

              <!-- <app-international-address-form></app-international-address-form> -->

              <div class="form-group row" [class.form-group-invalid]="isInvalid(address)">
                <label class="col-lg-3 col-form-label">{{'accessRequest.userDetailsAddress' | translate}}</label>
                <div class="col-lg-9">
                  <input type="text" taUpperCase class="form-control"
                    placeholder="{{'accessRequest.userDetailsAddress' | translate}}"
                    [class.is-invalid]="isInvalid(address)" formControlName="address" name="address">
                  <div class="invalid-feedback">
                    <span>{{isInvalidMessage(address) }}</span>
                  </div>
                </div>
              </div>

              <div class="form-group row" [class.form-group-invalid]="isInvalid(country)">
                <label class="col-lg-3 col-form-label">{{'accessRequest.userDetailsCountry' | translate}}</label>
                <div class="col-lg-9">
                  <select class="form-control" [class.is-invalid]="isInvalid(country)" formControlName="country"
                    name="country" (change)="onCountryChange()">
                    <!-- <select class="form-control" [(ngModel)]="selectedCountry" name="selectedCountry"> -->
                    <option value="" disabled>{{'accessRequest.accessRequestDropdownSelect' | translate}}</option>
                    <option *ngFor="let countryRow of noOfacCountryStateList(countryStateList)" [value]="countryRow.code">
                      {{countryRow.display}}
                    </option>
                  </select>
                  <div class="invalid-feedback">
                    <span>{{isInvalidMessage(country) }}</span>
                  </div>
                </div>
              </div>

              <div class="form-group row" [class.form-group-invalid]="isInvalid(state)">
                <label class="col-lg-3 col-form-label">{{'accessRequest.userDetailsState' | translate}}</label>
                <div class="col-lg-9">
                  <select class="form-control" [class.is-invalid]="isInvalid(state)" formControlName="state"
                    name="state">
                    <option value="" disabled>{{'accessRequest.accessRequestDropdownSelect' | translate}}</option>
                    <option *ngFor="let stateRow of stateList" [value]="stateRow.stateId">
                      {{stateRow.stateName}}
                    </option>
                  </select>
                  <div class="invalid-feedback">
                    <span>{{isInvalidMessage(state) }}</span>
                  </div>
                </div>
              </div>

              <div class="form-group row" [class.form-group-invalid]="isInvalid(city)">
                <label class="col-lg-3 col-form-label">{{'accessRequest.userDetailsCity' | translate}}</label>
                <div class="col-lg-9">
                  <input type="text" taUpperCase class="form-control"
                    placeholder="{{'accessRequest.userDetailsCity' | translate}}" [class.is-invalid]="isInvalid(city)"
                    formControlName="city" name="city">
                  <div class="invalid-feedback">
                    <span>{{isInvalidMessage(city) }}</span>
                  </div>
                </div>
              </div>

              <div class="form-group row" [class.form-group-invalid]="isInvalid(postalCode)">
                <label class="col-lg-3 col-form-label">{{'accessRequest.userDetailsPostalCode' | translate}}</label>
                <div class="col-lg-9">
                  <input type="text" taUpperCase class="form-control"
                    placeholder="{{'accessRequest.userDetailsPostalCode' | translate}}"
                    [class.is-invalid]="isInvalid(postalCode)" formControlName="postalCode" name="postalCode">
                  <div class="invalid-feedback">
                    <span>{{isInvalidMessage(postalCode) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg">
          <div class="card-section">
            <div class="card-section-header">
              <h3 class="card-section-title">{{'accessRequest.userDetailsContactInfo' | translate}}</h3>
            </div>
            <div class="card-section-body">
              <div class="form-group row" [class.form-group-invalid]="isInvalid(contactName)">
                <label class="col-lg-3 col-form-label">{{'accessRequest.userDetailsContactName' | translate}}</label>
                <div class="col-lg-9">
                  <input type="text" taUpperCase class="form-control"
                    placeholder="{{'accessRequest.userDetailsContactName' | translate}}"
                    [class.is-invalid]="isInvalid(contactName)" formControlName="contactName" name="contactName">
                  <div class="invalid-feedback">
                    <span>{{isInvalidMessage(contactName) }}</span>
                  </div>
                </div>
              </div>

              <div class="form-group row" [class.form-group-invalid]="isInvalid(position)">
                <label class="col-lg-3 col-form-label">{{'accessRequest.userDetailsPosition' | translate}}</label>
                <div class="col-lg-9">
                  <input type="text" taUpperCase class="form-control"
                    placeholder="{{'accessRequest.userDetailsPosition' | translate}}"
                    [class.is-invalid]="isInvalid(position)" formControlName="position" name="position">
                  <div class="invalid-feedback">
                    <span>{{isInvalidMessage(position) }}</span>
                  </div>
                </div>
              </div>

              <div formGroupName="emailGroup" class="form-group row" [class.form-group-invalid]="isInvalid(emailGroup)">
                <label class="col-lg-3 col-form-label">{{'accessRequest.userDetailsEmail' | translate}}</label>
                <div class="col-lg-9">
                  <input type="text" taUpperCase class="form-control mb-1"
                    placeholder="{{'accessRequest.placeHolderEmail' | translate}}" [class.is-invalid]="isInvalid(email)"
                    formControlName="email" name="email">
                  <div *ngIf="isInvalid(email)" class="invalid-feedback">
                    <span>{{isInvalidMessage(email) }}</span>
                  </div>
                  <input type="text" taUpperCase class="form-control"
                    placeholder="{{'accessRequest.placeHolderConfirmEmail' | translate}}"
                    [class.is-invalid]="isInvalid(confirmEmail)" formControlName="confirmEmail" name="confirmEmail"
                    onPaste="return false">
                  <small
                    class="form-text text-muted">{{'accessRequest.userDetailsEmailConfirmText' | translate}}</small>
                  <div class="invalid-feedback" *ngIf="isInvalid(confirmEmail)">
                    <span>{{isInvalidMessage(confirmEmail) }}</span>
                  </div>
                  <div *ngIf="isInvalid(emailGroup)" class="invalid-feedback">
                    <span>{{isInvalidMessage(emailGroup) }}</span>
                  </div>
                </div>
              </div>

              <div class="form-group row" [class.form-group-invalid]="isInvalid(phone)">
                <label class="col-lg-3 col-form-label">{{'accessRequest.userDetailsPhone' | translate}}</label>
                <div class="col-lg-9">
                  <input type="text" taUpperCase class="form-control"
                    placeholder="{{'accessRequest.userDetailsPhone' | translate}}" [class.is-invalid]="isInvalid(phone)"
                    formControlName="phone" name="phone">
                  <div class="invalid-feedback">
                    <span>{{isInvalidMessage(phone) }}</span>
                  </div>
                </div>
              </div>

              <div class="form-group row" [class.form-group-invalid]="isInvalid(fax)">
                <label class="col-lg-3 col-form-label">{{'accessRequest.userDetailsFax' | translate}}</label>
                <div class="col-lg-9">
                  <input type="text" taUpperCase class="form-control"
                    placeholder="{{'accessRequest.userDetailsFax' | translate}}" [class.is-invalid]="isInvalid(fax)"
                    formControlName="fax" name="fax">
                  <div class="invalid-feedback">
                    <span>{{isInvalidMessage(fax) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-header">
      <h2 class="card-title">{{'accessRequest.autorizationTitle' | translate}}</h2>
    </div>
    <div class="card-body">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" [class.is-invalid]="isInvalid(authorizeCheck)"
          formControlName="authorizeCheck" name="authorizeCheck" id="authorizeCheck">
        <label class="custom-control-label"
          for="authorizeCheck">{{'accessRequest.autorizationAuthorizeCheck' | translate}}</label>
      </div>

      <div class="mt-4 mb-4">
        <re-captcha (resolved)="resolved($event)"></re-captcha>
      </div>

      <p class="mb-0">
        {{'accessRequest.autorizationLopdLaw' | translate}}
      </p>
    </div>
  </div>

  <div class="text-right">
    <button class="btn btn-primary" [disabled]="!accessRequestForm.valid || !isValidCaptcha"
      (click)="sendRequestAccess()">{{'accessRequest.accessRequestSave' | translate}}</button>
  </div>
</form>
