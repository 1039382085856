<form [formGroup]="settingsForm">
  <div class="card">
    <div class="card-header">
      <h2 class="card-title">{{'settings.display' | translate}}</h2>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group row">
            <label class="col-lg-3 col-form-label">{{'settings.profileName' | translate}} </label>
            <div class="col-lg-9">
              <select class="form-control mb-2" formControlName="profileSelect" name="profileSelect"
                (change)="onChangeProfile()">
                <option *ngFor="let profile of userProfiles" [ngValue]="profile.id"> {{profile.alias}} |
                  {{profile.type}}
                  ({{profile.code}} ) </option>
              </select>
              <input type="text" class="form-control" formControlName="newAlias" name="newAlias"
                placeholder="{{'settings.profileNamePH' | translate}}" taUpperCase
                [class.is-invalid]="isInvalid(newAlias)" />
              <small class="form-text text-muted">
                {{'settings.profileNameTip' | translate}}
              </small>
              <div class="invalid-feedback">
                <span>{{isInvalidMessage(newAlias) }}</span>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-lg-3 col-form-label">{{'settings.language' | translate}}</label>
            <div class="col-lg-9">
              <select class="form-control" formControlName="languageSelect" name="languageSelect">
                <option *ngFor="let language of language$" [ngValue]="language.code">{{language.display}}</option>
              </select>
              <small class="form-text text-muted">
                {{'settings.languageTip' | translate}}
              </small>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-lg-3 col-form-label">{{'settings.dateFormat' | translate}}</label>
            <div class="col-lg-9">
              <select class="form-control" formControlName="dateformatSelect" name="dateformatSelect" (change)="setDateFormatTip()">
                <option *ngFor="let dateFormat of dateFormat$" [ngValue]="dateFormat.geco">{{dateFormat.geco}}</option>
              </select>
              <small class="form-text text-muted" [innerHTML]= "'settings.dateFormatTip' | translate | replace: {date: formattedDate}">
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="form-inline float-right">
        <button type="button"
                class="btn btn-primary"
                (click)=(saveSettings())
                [disabled]="!settingsForm.valid || !settingsForm.dirty">{{'common.saveBtn' | translate}}
        </button>
      </div>
    </div>
  </div>
</form>