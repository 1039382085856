import { AfterViewInit, Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from '@app/app.constants';
import * as $ from 'jquery';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'ta-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {

  onyxPortalUrl: string = Constants.URL_ONYX_PORTAL;
  onyxCommissionsEmail: string = Constants.MAIL_ONYX_COMMISSIONS;
  onyxCustomerSupportEmail: string = Constants.MAIL_ONYX_CUSTOMER_SUPPORT;
  isLoggedIn$: Observable<boolean>;
  subscription: Subscription;

  componentTitle = 'login.title';

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private _renderer2: Renderer2,
        @Inject(DOCUMENT) private _document: Document
  ) { }

  ngOnInit() {
    this.loadMarketoScripts();

    this.subscription = new Subscription();
    this.isLoggedIn$ = this.authService.isLoggedIn;

    this.subscription.add(
      this.isLoggedIn$.subscribe(isLoggin => {
        if (isLoggin) {
          this.router.navigate(['/']);
        }
      })
    );
  }

  ngAfterViewInit(): void {
    $('body').click();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  loadMarketoScripts() {
    const script = this._renderer2.createElement('script');
    script.type = 'text/javascript';
    script.text = `
        (function() {
          var didInit = false;
          function initMunchkin() {
            if(didInit === false) {
              didInit = true;
              Munchkin.init('088-GST-760');
            }
          }
          var s = document.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = '//munchkin.marketo.net/munchkin.js';
          s.onreadystatechange = function() {
            if (this.readyState == 'complete' || this.readyState == 'loaded') {
              initMunchkin();
            }
          };
          s.onload = initMunchkin;
          document.getElementsByTagName('head')[0].appendChild(s);
        })();
        `;

    this._renderer2.appendChild(this._document.body, script);
  }

}
