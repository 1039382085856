import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TitleService } from '@core/services';
import { Observable } from 'rxjs';
import { Constants } from '../../app.constants';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private jwtHelper: JwtHelperService,
    private titleService: TitleService
  ) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    let activate = true;

    const isLoggedIn$ = this.authService.isLoggedIn;
    const isTokenExpired = this.jwtHelper.isTokenExpired(localStorage.getItem(Constants.TOKEN_KEY));

    if (!isLoggedIn$ || isTokenExpired) {
      this.authService.logout();
      this.router.navigate(['auth/login']);
      activate = false;
      this.titleService.setPageTitle();
    }

    return activate;
  }

}
