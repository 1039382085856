import { PlatformLocation } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessRequestService } from '@app/auth/services/access-request.service';
import { AuthService } from '@app/auth/services/auth.service';
import { TitleService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { Constants } from '../../../app.constants';
import { GecoResponse } from '../../../core/models/geco/geco-response.model';

@Component({
  selector: 'ta-access-request',
  templateUrl: './access-request.component.html',
})
export class AccessRequestComponent implements OnInit, OnDestroy {
  isValidCaptcha = false;
  public captchaKey: string;

  onyxCustomerSupportEmail: string = Constants.MAIL_ONYX_CUSTOMER_SUPPORT;

  origen: string;

  public readonly pageTitle: string = 'New Access Request';

  public countryStateList = Constants.STATIC_COUNTRIES_AND_STATES;
  public stateList = [];

  public agencyTypeList = Constants.AGENCY_TYPE;
  public agencyTypeDisplay: string;

  public invalidEmail: boolean;

  public accessRequestForm: FormGroup;

  public isUserLoggedIn = false;
  public goingBack = false;

  private subscription: Subscription = new Subscription();

  userGuideLink: string;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private accessRequestService: AccessRequestService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private titleService: TitleService,
    private location: PlatformLocation
  ) {
    location.onPopState(() => {
      this.goingBack = true;
      this.authService.setLoggedIn(this.isUserLoggedIn);
    });
  }

  ngOnInit() {

    this.titleService.setPageTitle('accessRequest.accessRequestTitle');

    this.userGuideLink = `${environment.cdnUrl}${Constants.USER_GUIDE_NAME}`;

    this.subscription.add(
      this.authService.isLoggedIn.subscribe(value => {
        if (value && !this.goingBack) {
          this.isUserLoggedIn = true;
          this.authService.setLoggedIn(false);
        }
      })
    );

    this.subscription.add(
      this.route.queryParams.subscribe(params => {
        if (params.pTokenOrigin) {
          this.origen = params.pTokenOrigin;
        }
      })
    );

    this.intiForm();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private intiForm() {
    this.accessRequestForm = this.fb.group({
      profileType: [this.setControlValue('profileType'), Validators.required],
      agencyType: [this.setControlValue('agencyType'), Validators.required],
      agencyCode: [this.setControlValue('agencyCode'), Validators.required],
      companyName: [this.setControlValue('companyName'), Validators.required],
      companyURL: [this.setControlValue('companyURL'), Validators.required],
      country: [this.setControlValue('country'), Validators.required],
      address: [this.setControlValue('address'), Validators.required],
      city: [this.setControlValue('city'), Validators.required],
      state: [this.setControlValue('state'), Validators.required],
      postalCode: [this.setControlValue('postalCode'), Validators.required],
      contactName: [this.setControlValue('contactName'), Validators.required],
      position: [this.setControlValue('position'), Validators.required],
      emailGroup: this.fb.group({
        email: [this.setControlValue('email'), [Validators.required, Validators.email]],
        confirmEmail: [this.setControlValue('confirmEmail'), Validators.required],
      }, { validator: [this.emailMatchValidator] }),
      phone: [this.setControlValue('phone'), Validators.required],
      fax: [this.setControlValue('fax'), Validators.required],
      authorizeCheck: [this.setControlValue('authorizeCheck'), Validators.required],
    });

    // Set up defult options
    this.enableControl(this.profileType, 'agency');
    this.disableControl(this.agencyCode, '');
    this.disableControl(this.state, '');
    this.enableControl(this.authorizeCheck, false);
  }

  get profileType() { return this.accessRequestForm.get('profileType'); }
  get agencyType() { return this.accessRequestForm.get('agencyType'); }
  get agencyCode() { return this.accessRequestForm.get('agencyCode'); }
  get companyName() { return this.accessRequestForm.get('companyName'); }
  get companyURL() { return this.accessRequestForm.get('companyURL'); }
  get country() { return this.accessRequestForm.get('country'); }
  get address() { return this.accessRequestForm.get('address'); }
  get city() { return this.accessRequestForm.get('city'); }
  get state() { return this.accessRequestForm.get('state'); }
  get postalCode() { return this.accessRequestForm.get('postalCode'); }
  get contactName() { return this.accessRequestForm.get('contactName'); }
  get position() { return this.accessRequestForm.get('position'); }
  get emailGroup() { return this.accessRequestForm.get('emailGroup'); }
  get email() { return this.emailGroup.get('email'); }
  get confirmEmail() { return this.emailGroup.get('confirmEmail'); }
  get phone() { return this.accessRequestForm.get('phone'); }
  get fax() { return this.accessRequestForm.get('fax'); }
  get authorizeCheck() { return this.accessRequestForm.get('authorizeCheck'); }

  hasControlValue(controlName: string) {
    return this.accessRequestForm && this.accessRequestForm[controlName];
  }

  private setControlValue(controlName: string) {
    return this.hasControlValue(controlName) ? this.accessRequestForm[controlName] : '';
  }

  /**
 * Checks if the field has been modified and is invalid
 *
 * @param formControl the field to be validated
 * @returns true if the field invalid
 */
  isInvalid(formControl: AbstractControl): boolean {
    if (formControl.invalid && formControl.touched && formControl.errors) {
      return true;
    }
    return false;
  }

  disableControl(control: AbstractControl, value: string) {
    control.patchValue(value);
    control.disable();
  }

  enableControl(control: AbstractControl, value: any) {
    control.patchValue(value);
    control.enable();
  }

  resolved(captchaResponse: string) {
    if (captchaResponse) {
      this.isValidCaptcha = true;
      this.captchaKey = captchaResponse;
    } else {
      this.isValidCaptcha = false;
      this.captchaKey = null;
    }
  }

  onProfileTypeChange() {
    // Required only when profileType = Travel Angency
    if (this.profileType.value === 'group') {
      this.disableControl(this.agencyType, '');
      this.disableControl(this.agencyCode, '');
      this.accessRequestForm.controls['agencyType'].setValidators([]);
      this.accessRequestForm.controls['agencyCode'].setValidators([]);
    } else {
      this.accessRequestForm.controls['agencyType'].setValidators([Validators.required]);
      this.accessRequestForm.controls['agencyCode'].setValidators([Validators.required]);
      this.enableControl(this.agencyType, '');
      this.disableControl(this.agencyCode, '');
    }
  }

  onAgencyTypeChange() {
    if (this.agencyType.value === 'None') {
      this.disableControl(this.agencyCode, '');
      this.accessRequestForm.controls['agencyCode'].setValidators([]);
    } else {
      this.accessRequestForm.controls['agencyCode'].setValidators([Validators.required]);
      this.enableControl(this.agencyCode, '');
    }

    // Show agencyType.display in placeHolder
    this.agencyTypeDisplay = this.agencyTypeList.find(x => x.code === this.agencyType.value).display;
  }

  onCountryChange() {
    this.stateList = this.countryStateList.find(x => x.code === this.country.value).states;
    if (this.stateList.length === 0) {
      this.disableControl(this.state, '');
      this.accessRequestForm.controls['state'].setValidators([]);
    } else {
      this.accessRequestForm.controls['state'].setValidators([Validators.required]);
      this.enableControl(this.state, '');
    }
  }

  /*
   * Send error message if the field has been modified and is invalid
   */
  isInvalidMessage(formControl: AbstractControl): string {
    let message: string;
    if (this.isInvalid(formControl)) {
      if (formControl.errors.required) {
        message = this.translate.instant('accessRequest.errorRequiredField');
      } else if (formControl.errors.email) {
        message = this.translate.instant('accessRequest.errorInvalidEMail');
      } else if (formControl.errors.invalidEmailDomain) {
        message = this.translate.instant('errorMessageList.TAPP_ERROR_EMAIL_0001.message');
      } else if (!formControl.errors.taValidateEqual) {
        message = this.translate.instant('accessRequest.errorDifferentEmail');
      }
    }
    return message;
  }

  sendRequestAccess() {
    const language = Constants.LANGUAGE_OPTIONS.find(lang => lang.code === localStorage.getItem(Constants.LANG_KEY)) ?
      Constants.LANGUAGE_OPTIONS.find(lang => lang.code === localStorage.getItem(Constants.LANG_KEY)).gecoCode : 'EN';

    this.accessRequestService.sendRequestAccess(
      this.profileType.value,
      this.agencyType.value,
      this.agencyCode.value,
      this.companyName.value,
      this.companyURL.value,
      this.address.value,
      this.country.value,
      this.state.value,
      this.city.value,
      this.postalCode.value,
      this.contactName.value,
      this.position.value,
      this.email.value,
      this.phone.value,
      this.fax.value,
      this.authorizeCheck.value,
      language,
      this.captchaKey,
      this.origen
    )
      .subscribe((response: GecoResponse) => {
        if (response.successful) {
          this.router.navigate(['/pwd-messages'], { queryParams: { pwdMessageOrigin: 'NewRequestAccess', username: this.email.value } });
        } else {
          if (response.error.message === 'ERROR_EMAIL_0001') {
            this.email.setErrors({ 'invalidEmailDomain': true });
            this.confirmEmail.setValue('');
          }
          grecaptcha.reset();
          this.isValidCaptcha = false;
        }
      }
      );
  }

  emailMatchValidator(formGroup: FormGroup): any {
    if (formGroup) {
      if (formGroup.get('email').value &&
        formGroup.get('confirmEmail').value &&
        formGroup.get('email').value !== formGroup.get('confirmEmail').value) {
        return { taValidateEqual: false };
      }
    }
    return null;
  }

  // Return only countries without ofac sanction
  noOfacCountryStateList(countryList: any[]): any[] {
    return countryList.filter( country => !country.OfacBlocked);
  }

}
