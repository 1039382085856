import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Constants } from '../../app.constants';
import { GecoErrorMessage } from '../../core/models/geco/geco-error-message.model';
import { GecoResponse } from '../../core/models/geco/geco-response.model';
import { MessagesUtilsService } from '../../core/services/messages-utils.service';
import { SpinnerUtilsService } from '../../core/services/spinner-utils.service';

@Injectable({
  providedIn: 'root',
})
export class AccessRequestService {

  constructor(
    private httpClient: HttpClient,
    private spinnerService: SpinnerUtilsService,
    private messageService: MessagesUtilsService
  ) { }

  sendRequestAccess(
    profileType: string,
    agencyType: string,
    agencyCode: string,
    companyName: string,
    companyURL: string,
    address: string,
    country: string,
    state: string,
    city: string,
    postalCode: string,
    contactName: string,
    position: string,
    email: string,
    phone: string,
    fax: string,
    authorized: string,
    language: string,
    captchaKey: string,
    origen: string
  ): Observable<GecoResponse> {
    const formData = new FormData();

    formData.append('pProfileType_in', profileType);
    formData.append('pAgencyType_in', agencyType);
    formData.append('pAgencyCode_in', agencyCode);
    formData.append('pCompanyName_in', companyName);
    formData.append('pCompanyURL_in', companyURL);
    formData.append('pAddress_in', address);
    formData.append('pCountry_in', country);
    formData.append('pState_in', state);
    formData.append('pCity_in', city);
    formData.append('pPostalCode_in', postalCode);
    formData.append('pContactName_in', contactName);
    formData.append('pPosition_in', position);
    formData.append('pEmail_in', email);
    formData.append('pPhone_in', phone);
    formData.append('pFax_in', fax);
    formData.append('pAuthorized_in', authorized);
    formData.append('pLanguage_in', language);
    formData.append('pCaptchaKey_in', captchaKey);
    formData.append('pTokenOrigin_in', origen);

    this.spinnerService.setMessage('spinner.savingRequest');
    return this.httpClient.post(Constants.URL_SEND_REQUEST_ACCESS, formData).pipe(tap((response: GecoResponse) => {

      if (!response.successful && (response.error as GecoErrorMessage).message) {  // Type validation.
        const error: GecoErrorMessage = response.error as GecoErrorMessage;
        this.messageService.addMessage({
          type: 'error',
          icon: '',
          code: error.message,
          errorObject: response,
        });
      }
      return response;
    }));

  }

}
