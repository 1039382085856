<div class="form-group row" [formGroup]="sortingForm">
    <div class="col-lg-1 col-form-label-lg">
      <label for="transactions-sort-by">{{'sortingDropdown.sortBy' | translate }}</label>
    </div>
    <div class="col-lg-4 col-form-label-lg" >
      <select id="transactions-sort-by" class="form-control" formControlName="sortBy" (change)="onSortByChange()">
        <option [ngValue]="null" disabled>{{'common.select' | translate }}</option>
        <option *ngFor="let column of columns" [value]="column.property">{{column.name | translate}}</option>
      </select>
    </div>
    <div class="col-lg-6 col-form-label-lg">
      <div class="custom-control custom-radio custom-control-inline">
        <input type="radio" id="ascending-order-radio" formControlName="ascending" class="custom-control-input" [value]="true">
        <label class="custom-control-label" for="ascending-order-radio">{{'sortingDropdown.ascending' | translate }}</label>
      </div>
      <div class="custom-control custom-radio custom-control-inline">
        <input type="radio" id="descending-order-radio" formControlName="ascending" class="custom-control-input" [value]="false">
        <label class="custom-control-label" for="descending-order-radio">{{'sortingDropdown.descending' | translate }}</label>
      </div>
    </div>
</div>