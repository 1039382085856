<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-lg-7">
        <h2 class="card-title"> {{'taLegalDetailsCard.title' | translate}} </h2>
      </div>
      <div class="col-lg-5 text-lg-right">
        <button class="btn btn-sm btn-secondary text-nowrap" (click)="openModal()" data-toggle="modal" [disabled]="disableUpdateButton" [title]="updateButtonTooltipText">
          <i class="far fa-pencil"></i>
          {{'common.updateBtn' | translate}}
        </button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div *ngIf="!isLegalDetailsEmpty; else emptyCard" class="form-text">
      <div>
        <p><label>{{'taLegalDetailsCard.titleDetails' | translate}}</label></p>
        <ta-address-block [address]="legalDetails"></ta-address-block>
        <span class="text-caption">{{'taLegalDetailsCard.checksAlert' | translate}}</span>
      </div>
      <br>
      <div [ngSwitch]="legalType" *ngIf="legalType">
        <ta-tin-card *ngSwitchCase="'TIN'" [agency]="agencyInfo"></ta-tin-card>
        <ta-vat-default-card *ngSwitchDefault [agency]="agencyInfo"></ta-vat-default-card>
      </div>
    </div>
    <ng-template #emptyCard>
      <ta-empty-card [card]="'legal'" [newInfo]="newInfo"></ta-empty-card>
    </ng-template>
  </div>
  <div class="card-footer" *ngIf="lastUpdate">
    <span class="text-caption">
      {{'taLegalDetailsCard.footer' | translate}} {{ lastUpdate | userDate}}
    </span>
  </div>
</div>