import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagePwdMessagesComponent } from '@shared/components/page-pwd-messages/page-pwd-messages.component';
import { RedirectComponent } from './auth/components/redirect/redirect.component';

const routes: Routes = [
  { path: 'pwd-messages', component: PagePwdMessagesComponent },
  { path: 'agency-registration', component: RedirectComponent},
  { path: '**', redirectTo: '/profile', pathMatch: 'full' },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
