import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/auth/services/auth.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'ta-login-sso',
  template: '',
})
export class LoginSsoComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.queryParams
      .pipe(filter(params => params.ssoToken))
      .subscribe(params => {
        this.authService.verifySso(params.ssoToken).subscribe(response => {
          if (response.successful === true) {
            this.router.navigate(['/']);
          } else {
            this.router.navigate(['/pwd-messages'], { queryParams: { pwdMessageOrigin: 'AccessDenied' } });
          }
        });
      });
  }

}
