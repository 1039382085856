import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/services/auth.service';
import { Message } from '@core/models/message.model';
import { TitleService } from '@core/services';
import { MessagesUtilsService } from '@core/services/messages-utils.service';
import { Observable, throwError, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Constants } from '@app/app.constants';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor, OnDestroy {

  private httpWithoutInterceptor: HttpClient;
  private subscription: Subscription = new Subscription();

  constructor(
    private messageService: MessagesUtilsService,
    private authService: AuthService,
    private router: Router,
    private titleService: TitleService,
    private httpBackend: HttpBackend
  ) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        const message: Message = new Message();
        message.type = 'danger';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          message.code = 'ERROR-001';
          message.errorObject = error;
        } else {
          // server-side error
          if (error.error && error.error.messageError) {
            if (error.error.messageError === 'ERROR_ACCESS_0001') {
              this.signOut();
              this.redirectToMessagesPage();
            } else if (error.error.messageError === 'ERROR_MAINT_0001') {
              this.signOut();
              this.redirectToMaintPage();
            } else {
              message.code = error.error.messageError;
              message.errorObject = error.error;
              this.messageService.addMessage(message);
            }
          } else {
            this.subscription.add(
              this.isMaintenance().subscribe((response: boolean) => {
                if (response) {
                  this.signOut();
                  this.redirectToMaintPage();
                } else {
                  message.code = 'ERROR-002';
                  message.errorObject = error;
                }
              }
              ));
          }
          return throwError(message);
        }

      }));
  }

  signOut() {
    this.titleService.setPageTitle('');
    this.authService.logout();
  }

  redirectToMaintPage() {
    this.router.navigate(['auth/maintenance'], { queryParams: { message: 'Y' } }).then(() => {
      setTimeout(function () { window.location.replace('/'); }, 60000);
    });
  }

  redirectToMessagesPage() {
    this.router.navigate(['/pwd-messages'], { queryParams: { pwdMessageOrigin: 'AccessProfileDenied' } });
  }

  private isMaintenance(): Observable<boolean> {

    const headerDict = {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem('token'),
    };

    return this.httpWithoutInterceptor.get<boolean>(Constants.URL_MAINTENANCE, { headers: headerDict });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
