import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  selector: 'ta-redirect',
  template: 'redirecting...',
})
export class RedirectComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    window.location.href = environment.aprUrl + '#' + this.router.url;
  }

}
