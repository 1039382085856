<form [formGroup]="addressForm">
  <div *ngIf="showInputName" class="form-group row" [class.form-group-invalid]="isInvalid(name) || !isValidLNM">
    <label class="{{labelColumnCssClasses}} col-form-label">{{'forms.name' | translate}}
      <span *ngIf="isLegalCard" class="text-caption">{{'taLegalDetailsCard.nameAsShown' | translate}}</span>  
      <span *ngIf="!isLegalCard" class="text-caption">{{'taAddressCardCommercial.dba' | translate}}</span>  
    </label>
    
    <div class="{{fieldColumnCssClasses}}">
      <input type="text" class="form-control" [class.is-invalid]="isInvalid(name) || !isValidLNM" formControlName="name"
        name="name" placeholder="{{'forms.name' | translate}}" taUpperCase/>
      <div *ngIf="isInvalid(name)" class="invalid-feedback">
        <span>{{isInvalidMessage(name) }}</span>
      </div>
      <div *ngIf="!isValidLNM" class="invalid-feedback d-block">
        <span>{{'taLegalDetailsCard.invalidLegalName' | translate}}</span>
      </div>
      <ng-container *ngTemplateOutlet="pendingReviewHelpTex;context:{pendingField: 'name'}"></ng-container>
      <small *ngIf="isLegalCard && !isPendingReview('name')"
        class="text-caption">{{'taLegalDetailsCard.checksAlert' | translate}}</small>
    </div>
  </div>

  <div class="form-group row" [class.form-group-invalid]="isInvalid(address)">
    <label class="{{labelColumnCssClasses}} col-form-label">{{'forms.address' | translate}}</label>
    <div class="{{fieldColumnCssClasses}}">
      <input type="text" class="form-control" [class.is-invalid]="isInvalid(address)" formControlName="address"
        name="address" placeholder="{{'forms.address' | translate}}" taUpperCase />
      <div class="invalid-feedback">
        <span>{{isInvalidMessage(address) }}</span>
      </div>
      <ng-container *ngTemplateOutlet="pendingReviewHelpTex;context:{pendingField: 'address'}"></ng-container>
    </div>
  </div>

  <div class="form-group row" [class.form-group-invalid]="isInvalid(countryCode)">
    <label class="{{labelColumnCssClasses}} col-form-label">{{'forms.country' | translate}}</label>
    <div class="{{fieldColumnCssClasses}}">
      <select class="form-control" [class.is-invalid]="isInvalid(countryCode)" formControlName="countrySelect"
        name="countrySelect" (change)="onCountryChange()" [compareWith]="compareFn">
        <option value="" disabled>{{'forms.countrySelect' | translate}}</option>
        <option *ngFor="let country of (countries$ | async)" [ngValue]="country">{{country.name}}</option>
      </select>

      <div class="invalid-feedback">
        <span>{{isInvalidMessage(countryCode) }}</span>
      </div>
      <ng-container *ngTemplateOutlet="pendingReviewHelpTex;context:{pendingField: 'countrySelect'}"></ng-container>
    </div>
  </div>

  <div class="form-group row" [class.form-group-invalid]="isInvalid(stateCode)">
    <label class="{{labelColumnCssClasses}} col-form-label">{{'forms.state' | translate}}</label>
    <div class="{{fieldColumnCssClasses}}">
      <select class="form-control" [class.is-invalid]="isInvalid(stateCode)" formControlName="stateSelect"
        name="stateSelect" (change)="onStateChange()" [compareWith]="compareFn">
        <option value="" disabled>{{'forms.stateSelect' | translate}}</option>
        <option value="loading" hidden disabled>{{'forms.loading' | translate}}</option>
        <option *ngFor="let state of (states$ | async)" [ngValue]="state">{{state.name}}</option>
      </select>
      <div class="invalid-feedback">
        <span>{{isInvalidMessage(stateCode) }}</span>
      </div>
      <ng-container *ngTemplateOutlet="pendingReviewHelpTex;context:{pendingField: 'stateSelect'}"></ng-container>
    </div>
  </div>
  <div class="form-group row">
    <label class="{{labelColumnCssClasses}} col-form-label">{{'forms.city' | translate}}</label>
    <div class="{{fieldColumnCssClasses}}">
      <div class="row form-control-segments">
        <div class="col-7">
          <!-- searchForm -->
          <form [formGroup]="searchForm">
            <div class="row form-control-segments">
              <div class="col-8">
                <input type="text" class="form-control" [class.is-invalid]="isInvalid(cityName)"
                  formControlName="citySearch" name="citySearch"
                  placeholder="{{'forms.citySearch' | translate}}" (keyup)="onCityNameChange()" taUpperCase />
              </div>
              <div class="col-2">
                <button type="button" class="btn btn-primary" (click)="filterCities()"
                  [disabled]="searchBtnDisabled">{{'common.search' | translate}}</button>
              </div>
            </div>
          </form>
          <!-- searchForm -->
        </div>
        <div class="col-5">
          <select class="form-control" [class.is-invalid]="isInvalid(cityCode)" formControlName="citySelect"
            name="citySelect" (change)="onCityChange()" [compareWith]="compareFn">
            <option value="" disabled>{{'forms.citySelect' | translate}}</option>
            <option value="refine" hidden disabled>{{'forms.refineSearch' | translate}}</option>
            <option value="loading" hidden disabled>{{'forms.loading' | translate}}</option>
            <option *ngFor="let city of filteredCities" [ngValue]="city">{{city.name}}</option>
          </select>
        </div>
      </div>
      <div class="invalid-feedback">
        <span>{{isInvalidMessage(cityName) }}</span>
      </div>
      <ng-container *ngTemplateOutlet="pendingReviewHelpTex;context:{pendingField: 'citySelect'}"></ng-container>
    </div>
  </div>

  <div class="form-group row" [class.form-group-invalid]="isInvalid(zipCode)">
    <label class="{{labelColumnCssClasses}} col-form-label">{{'forms.zipCode' | translate}}</label>
    <div class="{{fieldColumnCssClasses}}">
      <input type="text" formControlName="zipCode" class="form-control" [class.is-invalid]="isInvalid(zipCode)"
        placeholder="{{'forms.zipCode' | translate}}" taUpperCase />
      <div class="invalid-feedback">
        <span>{{isInvalidMessage(zipCode) }}</span>
      </div>

      <ng-container *ngTemplateOutlet="pendingReviewHelpTex;context:{pendingField: 'zipCode'}"></ng-container>
    </div>
  </div>
</form>

<ng-template #pendingReviewHelpTex let-pendingField=pendingField>
  <small *ngIf="isPendingReview(pendingField)" class="form-text text-muted">
    {{'pendingChanges.profileTxt1' | translate  }}
    <a href="#/profile-history" class="alert-link"
      (click)="close()">{{'pendingChanges.profileTxt' | translate}}</a>
    {{'pendingChanges.profileTxt2' | translate  }}
  </small>
</ng-template>