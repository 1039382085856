<div class="modal-header">
  <h5 class="modal-title" id="legalDetailsModalLabel">{{'taLegalDetailsCard.title' | translate}}</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="legal-modal-body">
    <h3 class="card-selection-title">{{'taLegalDetailsCard.titleDetails' | translate}}</h3>
    <ta-address-form [addressInput]="address" [states$]="states$" [cities$]="cities$"
      [pendingFieldsCard]="pendingFieldsCard" [card]="card" (closeModalFromChild)="closeModal()"></ta-address-form>
  </div>
  <div class="tax-modal-body">
    <h3 class="card-selection-title">{{'taLegalDetailsCard.taxInfoDetails' | translate}}</h3>
    <div [ngSwitch]="countryCodeSelected" *ngIf="countryCodeSelected">
      <ta-ca-tax-form *ngSwitchCase="countriesWithTypeCanadianTax" [newInfo]="newInfo"
        [pendingFieldsCard]="pendingFieldsTaxCard" (closeModalFromChild)="closeModal()"></ta-ca-tax-form>
      <ta-us-tax-form *ngSwitchCase="countriesWithTypeTinTax" [newInfo]="newInfo"
        [pendingFieldsCard]="pendingFieldsTaxCard" (closeModalFromChild)="closeModal()"
        (selectedDateChanged)="isDateUsTaxChanged($event)"></ta-us-tax-form>
      <ta-default-tax-form *ngSwitchDefault [newInfo]="newInfo" [pendingFieldsCard]="pendingFieldsTaxCard"
        (closeModalFromChild)="closeModal()"></ta-default-tax-form>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary"
      (click)="closeModal()">{{'common.discardBtn' | translate}}</button>
    <button type="button" class="btn btn-primary" (click)="save()"
      [disabled]="!isValidForm()">{{'common.saveBtn' | translate}}</button>
  </div>
</div>