<ng-container *ngIf="showHeader$ | async">
  <div class="container-content-header">
    <ng-container>
      <h1 class="content-title pb-0 mb-2">{{selectedProfile?.alias}}</h1>
      <ng-template [ngIf]="canChangeProfile" [ngIfElse]="cannotChangeProfile">
        <div class="content-title-supplemental">
          <h2 class="d-inline-block">
            <a href="#profileModal" data-toggle="modal" (click)="openModal(profileModal)">{{selectedProfile?.type}} (
              {{selectedProfile?.code}} )</a>
          </h2>
          <h2 class="content-subtitle">
            <small>
              <a href="#profileModal" data-toggle="modal" (click)="openModal(profileModal)">
                <i class="far fa-exchange mr-2"></i>
                <span [popover]="popoverProfile" triggers="mouseenter:mouseleave" placement="right" container="body"
                  [isOpen]="popIsOpen">{{'profileHeader.changeProfile' | translate}}</span>
              </a>
            </small>
          </h2>
        </div>
      </ng-template>
      <ng-template #cannotChangeProfile>
        <h2 class="content-title-supplemental">{{selectedProfile?.type}} ({{selectedProfile?.code}})</h2>
      </ng-template>
    </ng-container>
    <hr class="content-divider">
    <ng-container>
      <div class="nav nav-pills content-tabs" role="tablist">
        <a aria-selected="true" class="nav-item nav-link" routerLink="/profile" routerLinkActive="active" role="tab">
          {{'tabs.profile' | translate}}
        </a>
        <a aria-selected="false" class="nav-item nav-link" routerLink="/profile-history" routerLinkActive="active"
          role="tab">
          {{'tabs.profileHistory' | translate}}
        </a>
        <a aria-selected="false" class="nav-item nav-link" routerLink="/agency-group" routerLinkActive="active"
          role="tab" *ngIf="isAgencyGroup | async">
          {{'tabs.agencyGroup' | translate}}
        </a>
        <a aria-selected="false" class="nav-item nav-link" routerLink="/settings" routerLinkActive="active" role="tab">
          {{'tabs.settings' | translate}}
        </a>
      </div>
    </ng-container>
  </div>
</ng-container>

<!-- Modal -->
<ng-template #profileModal>
  <div class="modal-header">
    <h5 class="modal-title" id="profileModalLabel">{{'profileHeader.changeProfile' | translate}}</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <input #profileSearchInput type="text" class="form-control mb-5"
      placeholder="{{'profileHeader.filter' | translate}}" [(ngModel)]="profileQuery" name="profileQuery"
      taUpperCase />
    <table *ngIf="hasProfileMatches; else noMatches" class="table table-hover mb-0">
      <thead>
        <tr>
          <th>{{'profileHeader.alias' | translate}}</th>
          <th>{{'profileHeader.profileType' | translate}}</th>
          <th class="text-nowrap">{{'profileHeader.profileId' | translate}}
          </th>
          <th class="cell-btn"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let profile of filteredProfiles | slice: filterProfilesFrom : filterProfilesTo ">
          <td>
            {{profile.alias}}
            <span class="text-caption" *ngIf="!profile.isProfile">
              <i class='far fa-external-link'></i><span
                [innerHtml]="'profileHeader.linkPayments' | translate"></span>
            </span>
          </td>
          <td>{{profile.type}}</td>
          <td>{{profile.code}}</td>
          <td class="cell-btn">
            <button *ngIf="profile.isProfile" class="btn" data-dismiss="modal"
              title="{{'profileHeader.changeProfile' | translate}}"
              (click)="onChangeProfileClicked($event, profile)">
              <i class="far fa-exchange" aria-hidden="true"></i>
              <span class="sr-only">{{'profileHeader.changeProfile' | translate}}</span>
            </button>
            <button *ngIf="!profile.isProfile" class="btn" data-dismiss="modal"
              title="{{'profileHeader.changePayments' | translate}}"
              (click)="onChangeProfileClicked($event, profile)">
              <i class="far fa-external-link" aria-hidden="true"></i>
              <span class="sr-only">{{'profileHeader.changePayments' | translate}}</span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <ng-template #noMatches>
      <div class="mb-5">
        <div class="marquee">
          <i class="far fa-user marquee-icon"></i>
          <h1 class="marquee-heading">{{'profileHeader.noMatches' | translate}}</h1>
          <h2 class="marquee-message">{{'profileHeader.clearFilters' | translate}}</h2>
        </div>
      </div>
    </ng-template>
  </div>
  <ng-container *ngIf="showPagination">
    <div class="modal-footer">
      <span class="d-none d-md-inline mr-3"
        [innerHTML]="'common.pagination' | translate | replace: {from: filterProfilesFrom+1, to: filterProfilesTo, total: filteredProfiles.length }"></span>
      <div class="btn-group" role="group" aria-label="Data Row Page">
        <button type="button" class="btn btn-secondary" aria-label="Previous" (click)="onChangePage(-1)"
          [disabled]="isFirstPage">
          <i class="fas fa-caret-left"></i>
          <span class="d-none d-md-inline ml-2">{{'common.previousBtn' | translate}}</span>
        </button>
        <button type="button" class="btn btn-secondary" aria-label="Next" (click)="onChangePage(1)"
          [disabled]="isLastPage">
          <span class="d-none d-md-inline mr-2">{{'common.nextBtn' | translate}}</span>
          <i class="fas fa-caret-right"></i>
        </button>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #popoverProfile let-flow="flow">
  <h3 class="mb-0">
    {{popTitle | translate}}
  </h3>
  <hr class="my-2">
  <p class="mb-0">
    {{popMessage | translate}}
  </p>
</ng-template>