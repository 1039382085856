import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SortingService {

  private agencyGroupParamsSubject: BehaviorSubject<{ sortBy: string; ascending: boolean; }> =
    new BehaviorSubject<{ sortBy: string; ascending: boolean; }>(null);
  agencyGroupParams$: Observable<{ sortBy: string; ascending: boolean; }> = this.agencyGroupParamsSubject.asObservable();

  constructor() { }

  setAgencyGroupParams(params: { sortBy: string; ascending: boolean; }): void {
    this.agencyGroupParamsSubject.next(params);
  }

}
