import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { AgencyGroupUtilsService } from '@core/services/agency-group-utils.service';

@Injectable({
  providedIn: 'root',
})
export class AgencyGroupResolver implements Resolve<any> {

  constructor(
    private agencyGroupService: AgencyGroupUtilsService
  ) { }

  resolve() {
    return this.agencyGroupService.loadProfileHistoryAudit();
  }

}
