<!-- Modal add/update contact -->
<ng-container *ngIf="action === 'contactAddUpdate'">
  <form [formGroup]="contactForm">
    <div class="modal-header">
      <h5 class="modal-title">{{actionHeader | translate}}</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div class="form-group row" [class.form-group-invalid]="isInvalid(name)">
        <label class="{{labelColumnCssClasses}} col-form-label">{{'taContactCard.name' | translate}}</label>
        <div class="{{fieldColumnCssClasses}}">
          <input type="text" class="form-control" [class.is-invalid]="isInvalid(name)"
            placeholder="{{'taContactCard.name' | translate}}" formControlName="name" name="name" taUpperCase>
          <div class="invalid-feedback">
            <span>{{isInvalidMessage(name) }}</span>
          </div>
        </div>
      </div>
      <div class="form-group row" [class.form-group-invalid]="isInvalid(email)">
        <label class="{{labelColumnCssClasses}} col-form-label">{{'taContactCard.email' | translate}}</label>
        <div class="{{fieldColumnCssClasses}}">
          <input type="text" class="form-control" [class.is-invalid]="isInvalid(email)"
            placeholder="{{'taContactCard.placeHolderEmail' | translate}}" formControlName="email" name="email"
            taUpperCase>
          <div class="invalid-feedback">
            <span>{{isInvalidMessage(email) }}</span>
          </div>
          <div *ngIf="!isValidDomain" class="invalid-feedback d-block">
            <span>{{'taContactCard.invalidDomain' | translate}}</span>
          </div>
        </div>
      </div>
      <div class="form-group row" [class.form-group-invalid]="isInvalid(phone)">
        <label class="{{labelColumnCssClasses}} col-form-label">{{'taContactCard.phone' | translate}}</label>
        <div class="{{fieldColumnCssClasses}}">
          <input type="text" class="form-control" [class.is-invalid]="isInvalid(phone)"
            placeholder="{{'taContactCard.placeHolderPhone' | translate}}" formControlName="phone" name="phone"
            taUpperCase>
          <div class="invalid-feedback">
            <span>{{isInvalidMessage(phone) }}</span>
          </div>
        </div>
      </div>
      <div class="form-group row" [class.form-group-invalid]="isInvalid(fax)">
        <label class="{{labelColumnCssClasses}} col-form-label">{{'taContactCard.fax' | translate}}</label>
        <div class="{{fieldColumnCssClasses}}">
          <input type="text" class="form-control" [class.is-invalid]="isInvalid(fax)"
            placeholder="{{'taContactCard.placeHolderFax' | translate}}" formControlName="fax" name="fax"
            taUpperCase>
          <div class="invalid-feedback">
            <span>{{isInvalidMessage(fax) }}</span>
          </div>
        </div>
      </div>
      <div class="form-group row" formGroupName="type" [class.form-group-invalid]="isInvalid(type)">
        <label class="{{labelColumnCssClasses}} col-form-label">{{'taContactCard.type' | translate}}</label>
        <div class="{{fieldColumnCssClasses}}">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="checkboxContactCP" formControlName="typeCP"
              name="typeCP">
            <label class="custom-control-label"
              for="checkboxContactCP">{{'contactType.paymentCorrespondence' | translate}}</label>
          </div>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="checkboxContactCO" formControlName="typeCO"
              name="typeCO">
            <label class="custom-control-label"
              for="checkboxContactCO">{{'contactType.paymentCorrespondenceCopiedRecipient' | translate}}</label>
          </div>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="checkboxContactSM" formControlName="typeSM"
              name="typeSM">
            <label class="custom-control-label"
              for="checkboxContactSM">{{'contactType.salesManager' | translate}}</label>
          </div>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="checkboxContactIR" formControlName="typeIR"
              name="typeIR">
            <label class="custom-control-label"
              for="checkboxContactIR">{{'contactType.invoiceRecipient' | translate}}</label>
          </div>
          <div *ngIf="isVccAllowed" class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="checkboxContactVC" formControlName="typeVC"
              name="typeVC">
            <label class="custom-control-label"
              for="checkboxContactVC">{{'contactType.vccPaymentRecipient' | translate}}</label>
            <small class="form-text text-info" *ngIf="showVCCMessage">
              <i class="far fa-pennant"></i>
              <span [innerHTML]="'taContactCard.vccMessage' | translate  "></span>
            </small>
          </div>
          <div class="invalid-feedback" *ngIf="isInvalid(type)">
            <span>{{isInvalidMessage(type) }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal"
        (click)="closeModal()">{{'common.discardBtn' | translate}}</button>
      <button type="button" class="btn btn-primary" (click)="saveContact()"
        [disabled]="!contactForm.valid || !contactForm.dirty">{{'common.saveBtn' | translate}}</button>

    </div>
  </form>
</ng-container>

<!-- Modal delete contact -->
<ng-container *ngIf="action === 'contactDelete'">
  <div class="modal-header">
    <h5 class="modal-title">{{'taContactCard.deleteContactTitle' | translate}}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body"
    [innerHTML]="'taContactCard.deleteContactText' | translate | replace: { contactName: currentContact.name, contactEmailHref: currentContact.email, contactEmail: currentContact.email }">
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal"
      (click)="closeModal()">{{'common.cancelBtn' | translate}}</button>
    <button type="button" class="btn btn-danger"
      (click)="deleteContact()">{{'common.deleteBtn' | translate}}</button>
  </div>
</ng-container>