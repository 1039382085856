import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { TaprofileUtilsService } from '@core/services';

@Injectable({
  providedIn: 'root',
})
export class AgencyInfoResolver implements Resolve<any> {

  constructor(
    private profileService: TaprofileUtilsService
  ) { }

  resolve() {
    return this.profileService.loadAgencyInfo();
  }

}
