<ods-alert-inline *ngIf="hasPendingLegalDetails" type="info"
  title="{{'agencyGroup.hasPendingLegalDetailsTitle' | translate}}">
  <p>
    {{'agencyGroup.hasPendingLegalDetailsText1' | translate}}
    <a routerLink="/profile" class="alert-link">{{'agencyGroup.hasPendingLegalDetailsText2' | translate}}</a>
    {{'agencyGroup.hasPendingLegalDetailsText3' | translate}}
  </p>
  <p>
    {{'agencyGroup.hasPendingLegalDetailsText4' | translate}}
    <a routerLink="/profile-history"
      class="alert-link">{{'agencyGroup.hasPendingLegalDetailsText5' | translate}}</a>
    {{'agencyGroup.hasPendingLegalDetailsText6' | translate}}
  </p>
</ods-alert-inline>

<div *ngIf="!isEmpty && !isMissingLegalDetails" class="card card-table" [ngClass]="{ 'card-table': !isEmpty }">
  <div class="card-header">
    <ta-sorting-dropdown
        [columns]="columns"
        [list]="agencies"
        [lastParams]="lastParams$ | async"
        (listSorted)="onSortedList($event)"
        (lastParamsEmit)="saveLastParams($event)"
        >
    </ta-sorting-dropdown>
  </div>
  
  <div class="card-header">
    <div class="row">
      <div class="col-lg-7">
        <h2 class="card-title">{{'agencyGroup.agencies' | translate}}</h2>
        <h4 class="card-subtitle">{{'agencyGroup.profileTaxID' | translate}} {{profileTaxId}}</h4>
      </div>
      <div class="col-lg-5 text-lg-right">
        <button *ngIf="!hasPendingLegalDetails" class="btn btn-sm btn-secondary text-nowrap mr-3" data-toggle="modal"
          data-target="#addModal" (click)="onAddAgency()">
          <i class="far fa-plus"></i>
          {{'common.addBtn' | translate}}
        </button>
        <button class="btn btn-sm btn-secondary text-nowrap" data-toggle="modal"
          (click)="onExportAsExcel()">
          <i class="far fa-file-spreadsheet"></i>
          {{'common.exportBtn' | translate}}
        </button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>
              {{'agencyGroup.agencyName' | translate}}
              <span class="text-caption">{{'agencyGroup.agencyID' | translate}}</span>
            </th>
            <th>
              {{'agencyGroup.status' | translate}}
            </th>
            <th class="cell-btn"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let agency of sortedList| slice: agencyListFrom : agencyListTo ">
            <td>
              <span *ngIf="agency.name">{{agency.name}}</span>
              <span *ngIf="!agency.name"
                class="text-placeholder">{{'agencyGroup.unidentified' | translate}}</span>
              <span class="text-caption">{{agency.id}}</span>
            </td>

            <td *ngIf="agency.status=== agencyNoIdentified">
              <span
                class='text-warning font-weight-bold'>{{'agencyGroup.statusDisplayNoIdentified' | translate}}</span>
              <span class='text-caption'>
                {{'agencyGroup.statusMessageNoIdenfiedText1' | translate}} {{agency.name}}
                {{'agencyGroup.statusMessageNoIdenfiedText2' | translate}}
                <a href='/#/auth/access-request' rel="noopener noreferrer" target="_blank">{{'agencyGroup.statusMessageNoIdenfiedText3' | translate}}</a>
                {{'agencyGroup.statusMessageNoIdenfiedText4' | translate}} <a href='#deleteModal'
                  data-toggle='modal'
                  (click)="onDeleteAgency(agency)">{{'agencyGroup.statusMessageNoIdenfiedText5' | translate}}</a>
                {{'agencyGroup.statusMessageNoIdenfiedText6' | translate}}
              </span>
            </td>
            <td *ngIf="agency.status===agencyAssociatedThisTax">
              <span>{{'agencyGroup.statusDisplayAssociatedThisTax' | translate}}</span>
            </td>
            <td *ngIf="agency.status===agencyPending">
              <span class='text-placeholder'>{{'agencyGroup.statusDisplayPending' | translate}}</span>
            </td>
            <td *ngIf="agency.status===agencyAssociatedOtherTax">
              <span>{{'agencyGroup.statusDisplayAssociatedOtherTaxText1' | translate}}
                <strong>{{'agencyGroup.statusDisplayAssociatedOtherTaxText2' | translate}}</strong>
                {{'agencyGroup.statusDisplayAssociatedOtherTaxText3' | translate}}</span>
              <span class='text-caption'>{{'agencyGroup.statusMessageAssociatedOtherTax' | translate}}
                {{agency.taxCode}}</span>
            </td>

            <td class="cell-btn">
              <button *ngIf="!hasPendingLegalDetails" class="btn" data-toggle="modal" data-target="#deleteModal"
                title="Delete" (click)="onDeleteAgency(agency)">
                <i class="far fa-trash-alt" aria-hidden="true"></i>
                <span class="sr-only">{{'common.deleteBtn' | translate}}</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <ng-container *ngIf="showPagination">
    <div class="card-footer">
      <div class="form-inline float-right">
        <span class="d-none d-md-inline mr-3"
          [innerHTML]="'common.pagination' | translate | replace: {from: agencyListFrom+1, to: agencyListTo, total: totalAgencies }"></span>
        <div aria-label="Data Row Page" class="btn-group" role="group">
          <button aria-label="Previous" class="btn btn-secondary" type="button" (click)="onChangePage(-1)"
            [disabled]="isFirstPage">
            <i class="fas fa-caret-left"></i>
            <span class="d-none d-md-inline ml-2">{{'common.previousBtn' | translate}}</span>
          </button>
          <button aria-label="Next" class="btn btn-secondary" type="button" (click)="onChangePage(1)"
            [disabled]="isLastPage">
            <span class="d-none d-md-inline mr-2">{{'common.nextBtn' | translate}}</span>
            <i class="fas fa-caret-right"></i>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div *ngIf="isEmpty && !( isMissingLegalDetails || hasPendingLegalDetails )">
  <div class="marquee">
    <i class="far fa-user marquee-icon"></i>
    <h1 class="marquee-heading">{{'agencyGroup.isEmptyTitle' | translate}}</h1>
    <h2 class="marquee-message">
      <a href="#addModal" data-toggle="modal">{{'agencyGroup.isEmptyText1' | translate}}</a>
      {{'agencyGroup.isEmptyText2' | translate}}
    </h2>
  </div>
</div>

<div *ngIf="isMissingLegalDetails && !hasPendingLegalDetails">
  <div class="marquee">
    <i class="far fa-user marquee-icon"></i>
    <h1 class="marquee-heading">{{'agencyGroup.isMissingLegalDetailsTitle' | translate}}</h1>
    <h2 class="marquee-message">{{'agencyGroup.isMissingLegalDetailsText1' | translate}} <a
        routerLink="/profile">{{'agencyGroup.isMissingLegalDetailsText2' | translate}}</a>
      {{'agencyGroup.isMissingLegalDetailsText3' | translate}}</h2>
  </div>
</div>

<form [formGroup]="agencyListForm">
  <!-- Add modal -->
  <div class="modal" id="addModal" tabindex="-1" role="dialog" aria-labelledby="addModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addModalLabel">{{'agencyGroup.addModalTitle' | translate}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>
            {{'agencyGroup.addModalText' | translate}}
          </p>

          <div class="form-group row" [class.form-group-invalid]="isInvalid(agencyIdList)">
            <label>{{'agencyGroup.AddModalAgencyIDs' | translate}}</label>
            <textarea class="form-control" rows="5"
              placeholder="{{'agencyGroup.placeHolderAgencyList' | translate}}" class="form-control"
              [class.is-invalid]="isInvalid(agencyIdList)" formControlName="agencyIdList"></textarea>
            <div class="invalid-feedback">
              <span>{{isInvalidMessage(agencyIdList) }}</span>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary"
            data-dismiss="modal">{{'common.discardBtn' | translate}}</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="addAgencyToGroup()"
            [disabled]="!agencyListForm.valid || !agencyListForm.dirty">{{'common.addBtn' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</form>

<!-- Delete modal -->
<div class="modal" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteModalLabel">{{'agencyGroup.deleteModalTitle' | translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          {{'agencyGroup.deleteModalText1' | translate}}
          <strong>{{currentAgency.name}}</strong>
          ({{currentAgency.id}})
          {{'agencyGroup.deleteModalText2' | translate}}
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary"
          data-dismiss="modal">{{'common.cancelBtn' | translate}}</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal"
          (click)="deleteAgencyFromGroup()">{{'common.deleteBtn' | translate}}</button>
      </div>
    </div>
  </div>
</div>