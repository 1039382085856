import { Component, OnDestroy, OnInit } from '@angular/core';
import { Address, Agency, AgencyInfo, Contact, Country, Legal, Profile, TaxHist } from '@core/models';
import { CommonDataService, TaprofileUtilsService } from '@core/services';
import { TitleService } from '@core/services/title.service';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'ta-profile',
  templateUrl: './profile.component.html',
})

export class ProfileComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();

  oldInfo: Agency;
  newInfo: Agency;
  hasPendingChanges: boolean;
  hasPaymentCorrespondece: boolean;
  pendingFields: string[];

  postal: Address;
  commercial: Address;
  legal: Legal;
  taxHist: TaxHist[];
  contacts: Contact[] = [];

  selectedProfile: Profile;
  profileChangesPending = false;

  countries: Observable<Country[]>;

  constructor(
    private taProfileUtilsService: TaprofileUtilsService,
    private commonDataService: CommonDataService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.titleService.setPageTitle('tabs.profile');

    this.subscription.add(
      this.taProfileUtilsService.selectedProfile.subscribe(selectedProfile => {
        if (selectedProfile) {
          this.selectedProfile = selectedProfile;
        }
      })
    );

    this.subscription.add(
      this.taProfileUtilsService.agencyInfo.subscribe(agencyInfo => {
        this.setAgencyInfo(agencyInfo);
      }));

    this.commonDataService.loadTaxLabels().pipe(take(1)).subscribe();


  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setAgencyInfo(agencyInfo: AgencyInfo) {
    this.resetDetails();
    if (agencyInfo) {
      this.profileChangesPending = agencyInfo.hasPendingChanges;
      this.oldInfo = agencyInfo.oldInfo;
      this.newInfo = agencyInfo.newInfo;
      this.postal = agencyInfo.newInfo.postal;
      this.commercial = agencyInfo.newInfo.commercial;
      this.legal = agencyInfo.newInfo.legal;
      this.contacts = agencyInfo.newInfo.contacts;
      this.pendingFields = agencyInfo.pendingFields;
      this.hasPaymentCorrespondece = !(agencyInfo.canAddPaymentCorrespondence);
    }
  }

  resetDetails() {
    this.profileChangesPending = false;
    this.taxHist = [];
    this.contacts = [];

    this.postal = null;
    this.commercial = null;
    this.legal = null;
    this.contacts = null;

    this.pendingFields = [];
  }

}
