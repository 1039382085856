import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Constants } from '@app/app.constants';
import { Address, Agency, AgencyInfo, Dropdown } from '@core/models';
import { TaprofileUtilsService } from '@core/services';
import { AddressFormComponent } from '@shared/components/address';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { CaTaxFormComponent } from '../ca-tax-form/ca-tax-form.component';
import { DefaultTaxFormComponent } from '../default-tax-form/default-tax-form.component';
import { UsTaxFormComponent } from '../us-tax-form/us-tax-form.component';

@Component({
  selector: 'ta-legal-details-modal',
  templateUrl: './legal-details-modal.component.html',
  providers: [DatePipe ],
})
export class LegalDetailsModalComponent implements OnInit, OnDestroy {

  @ViewChild(AddressFormComponent) addressForm: AddressFormComponent;
  @ViewChild(CaTaxFormComponent) caTaxForm: CaTaxFormComponent;
  @ViewChild(DefaultTaxFormComponent) defaultTaxForm: DefaultTaxFormComponent;
  @ViewChild(UsTaxFormComponent) usTaxForm: UsTaxFormComponent;

  newInfo: Agency;
  address: Address;
  title: string;
  isCommercial: boolean;
  states$: Observable<Dropdown[]>;
  cities$: Observable<Dropdown[]>;
  pendingFieldsCard: string[];
  pendingFieldsTaxCard: string[];

  subscription: Subscription;
  countryCodeSelected: string;
  selectedDateFrom: Date;

  countriesWithTypeCanadianTax = Constants.countriesWithTypeCanadianTax;
  countriesWithTypeTinTax = Constants.countriesWithTypeTinTax;

  bValidVat = false;
  bValidLegalName = false;

  card: string;

  constructor(
    public bsModalRef: BsModalRef,
    private profileService: TaprofileUtilsService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {

    this.subscription = new Subscription();

    this.subscription.add(this.profileService.getSelectedLegalCountryCode().subscribe(countryCode => {
      this.countryCodeSelected = countryCode;
    }));

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  save() {
    let vat: any = '';
    let tax: any = '';
    let legalName: any = '';

    if (this.addressForm.addressForm.getRawValue().name) {
      legalName = this.addressForm.addressForm.getRawValue().name;
    }

    if (this.defaultTaxForm && this.defaultTaxForm.taxForm) {
      if (this.defaultTaxForm.taxForm.getRawValue().tax1) {
        vat = this.defaultTaxForm.taxForm.getRawValue().tax1;
      }
      if (this.defaultTaxForm.taxForm.getRawValue().tax2) {
        tax = this.defaultTaxForm.taxForm.getRawValue().tax2;
      }
    } else if (this.usTaxForm && this.usTaxForm.taxForm && this.usTaxForm.taxForm.getRawValue().ssnOrEin) {
      vat = this.usTaxForm.taxForm.getRawValue().ssnOrEin;
    } else if (this.caTaxForm && this.caTaxForm.taxForm) {
      if (this.caTaxForm.gstSelect.value === 'Y') {
        vat = this.caTaxForm.taxForm.getRawValue().gst;
      }
      if (this.caTaxForm.qstSelect.value === 'Y') {
        tax = this.caTaxForm.qst;
      }
    }

    this.profileService.validateLegalData(this.countryCodeSelected, vat, tax, legalName, this.newInfo.commercial.name).
      subscribe(result => {
        if (result.successful) {
          this.saveNewInfo();
        }
      });

  }

  private saveNewInfo() {

    const newInfo = Object.assign({}, this.newInfo);
    this.setNewInfo(newInfo);

    this.closeModal();
    this.profileService.saveNewInfo(newInfo)
      .pipe(take(1), catchError(error => {
        const agencyInfo: AgencyInfo = error.errorObject;
        this.profileService.reloadAgencyInfo(agencyInfo);
        return EMPTY;
      })).subscribe(response => {
        const agencyInfo: AgencyInfo = <AgencyInfo>response;
        this.profileService.reloadAgencyInfo(agencyInfo);
      });
  }

  private setNewInfo(newInfo: Agency) {
    this.setAddressNewInfo(newInfo);
    if (this.caTaxForm) {
      /** Canadian Tax */
      this.setCaTaxFormNewInfo(newInfo);
    } else if (this.defaultTaxForm) {
      /** Default Tax */
      this.setDefaultTaxFormNewInfo(newInfo);
    } else if (this.usTaxForm) {
      /** USA Tax */
      this.setUsTaxFormNewInfo(newInfo);
    }
  }

  isValidLegalAddressForm(): boolean {
    return this.addressForm ? (this.addressForm.addressForm.valid || this.addressForm.addressForm.disabled) : false;
  }

  isValidCaTaxForm(): boolean {
    return this.caTaxForm ? (this.caTaxForm.taxForm.valid || this.caTaxForm.taxForm.disabled) : false;
  }

  isValidDefaultTaxForm(): boolean {
    return this.defaultTaxForm ? (this.defaultTaxForm.taxForm.valid || this.defaultTaxForm.taxForm.disabled) : false;
  }

  isValidUsTaxForm(): boolean {
    return this.usTaxForm ? (this.usTaxForm.taxForm.valid || this.usTaxForm.taxForm.disabled) : false;
  }

  hasChanges(): boolean {
    return (this.addressForm ? this.addressForm.addressForm.dirty : false) ||
      (this.caTaxForm ? this.caTaxForm.taxForm.dirty : false) ||
      (this.defaultTaxForm ? this.defaultTaxForm.taxForm.dirty : false) ||
      (this.usTaxForm ? this.usTaxForm.taxForm.dirty : false);
  }

  isDateUsTaxChanged(selectedDate: Date) {
    this.selectedDateFrom = selectedDate;
  }

  isValidForm(): boolean {

    return this.hasChanges() && this.isValidLegalAddressForm() &&
      (this.isValidCaTaxForm() || this.isValidUsTaxForm() || this.isValidDefaultTaxForm());
  }

  closeModal() {
    if (!this.bsModalRef) {
      return;
    }

    this.bsModalRef.hide();
    this.bsModalRef = null;
  }


  setAddressNewInfo(newInfo: Agency) {

    if (this.addressForm.addressForm) {
      const address = this.addressForm.addressForm.getRawValue();
      this.legalAddressMapper(newInfo, address);
    }
  }

  setCaTaxFormNewInfo(newInfo: Agency) {
    const caTax = this.caTaxForm.taxForm.getRawValue();
    const now: string = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    let tax: any;
    let dateFrom: any;

    if (caTax.gstSelect) {
      if (caTax.gstSelect === 'Y') {
        dateFrom = newInfo.legal.primaryTax !== caTax.gst ? now : newInfo.legal.primaryTaxFrom;
        tax = caTax.gst;
      } else {
        dateFrom = newInfo.legal.primaryTax !== Constants.TAX_NOT_REGISTERED ? now : newInfo.legal.primaryTaxFrom;
        tax = Constants.TAX_NOT_REGISTERED;
      }
      this.primaryTaxMapper(newInfo, { tax1: tax, tax1Label: 'GST' }, 'CAD', dateFrom);
    }

    if (caTax.qstSelect) {
      if (caTax.qstSelect === 'Y') {
        const qst: string = caTax.qst1 + caTax.qstTq + caTax.qst2;
        dateFrom = newInfo.legal.secondaryTax !== qst ? now : newInfo.legal.secondaryTaxFrom;
        tax = qst;
      } else {
        dateFrom = newInfo.legal.secondaryTax !== Constants.TAX_NOT_REGISTERED ? now : newInfo.legal.secondaryTaxFrom;
        tax = Constants.TAX_NOT_REGISTERED;
      }
      this.secondaryTaxMapper(newInfo, { tax2: tax, tax2Label: 'QST' }, 'CAD', dateFrom);
    }

  }

  setDefaultTaxFormNewInfo(newInfo: Agency) {
    const defaultTax = this.defaultTaxForm.taxForm.getRawValue();
    const now: string = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    const type = 'VAT';

    if (newInfo.legal.primaryTax !== defaultTax.tax1) {
      this.primaryTaxMapper(newInfo, defaultTax, type, now);
    }

    if (newInfo.legal.secondaryTax !== defaultTax.tax2) {
      this.secondaryTaxMapper(newInfo, defaultTax, type, now);
    }
  }

  setUsTaxFormNewInfo(newInfo: Agency) {
    const usTax = this.usTaxForm.taxForm.getRawValue();
    const type = 'TIN';
    let startDateStringFormatted: string;

    if (this.selectedDateFrom) {
      startDateStringFormatted = this.datePipe.transform(this.selectedDateFrom, 'MM/dd/yyyy');
    } else {
      startDateStringFormatted = newInfo.legal.primaryTaxFrom;
    }

    this.primaryTaxMapper(newInfo, { tax1: usTax.ssnOrEin, tax1Label: usTax.ssnOrEinSelect }, type, startDateStringFormatted);
    newInfo.legal.businessType = usTax.statusSelect;
    newInfo.legal.exemptBw = usTax.exemptBWSelect;
  }

  private legalAddressMapper(newInfo: Agency, address: any) {
    newInfo.legal.name = address.name;
    newInfo.legal.address = address.address;
    newInfo.legal.countryCode = address.countryCode;
    newInfo.legal.countryName = address.countryName;
    newInfo.legal.stateCode = address.stateCode;
    newInfo.legal.stateName = address.stateName;
    newInfo.legal.cityCode = address.cityCode;
    newInfo.legal.cityName = address.cityName;
    newInfo.legal.zipCode = address.zipCode;
  }

  private primaryTaxMapper(newInfo: Agency, defaultTax: any, type: string, now: string) {
    if (defaultTax.tax1 && defaultTax.tax1 !== Constants.TAX_NOT_REGISTERED) {
      newInfo.legal.primaryTax = defaultTax.tax1.toString().replace(/[-.\\\/ ]/g, '');
    } else {
      newInfo.legal.primaryTax = defaultTax.tax1;
    }
    newInfo.legal.primaryTaxLabel = defaultTax.tax1Label;
    newInfo.legal.primaryTaxType = type;
    newInfo.legal.primaryTaxFrom = now;
  }

  private secondaryTaxMapper(newInfo: Agency, defaultTax: any, type: string, now: string) {
    if (defaultTax.tax2 && defaultTax.tax2.toString() !== Constants.TAX_NOT_REGISTERED) {
      newInfo.legal.secondaryTax = defaultTax.tax2.toString().replace(/[-.\\\/ ]/g, '');
    } else {
      newInfo.legal.secondaryTax = defaultTax.tax2;
    }
    newInfo.legal.secondaryTaxLabel = defaultTax.tax2Label;
    newInfo.legal.secondaryTaxType = type;
    newInfo.legal.secondaryTaxFrom = now;
  }


}

