import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Constants } from '@app/app.constants';
import { Agency } from '@core/models';
import { TaxHist } from '@core/models/tax-hist.model';
import { TaprofileUtilsService } from '@core/services/taprofile-utils.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'ta-tin-card',
  templateUrl: './tin-card.component.html',
})
export class TinCardComponent implements OnInit {
  @Input() agency: Agency;

  taxHist: TaxHist[];

  label: string;
  tin: string;
  businessType: string;
  exemptBw: string;
  dateFrom: string;

  public activePage = 1;
  private pageSize = 5;

  public tinHistFrom: number;
  public tinHistTo: number;
  public showPagination: boolean;

  public isLastPage: boolean;
  public isFirstPage: boolean;

  modalRef: BsModalRef;
  constructor(private modalService: BsModalService,
    private taProfileUtilsService: TaprofileUtilsService,
    private translate: TranslateService
  ) { }

  ngOnInit() {

    this.taProfileUtilsService.agencyInfo.subscribe(agencyInfo => {
      if (agencyInfo && agencyInfo.newInfo && agencyInfo.newInfo.legal) {
        if (agencyInfo.newInfo.legal.exemptBw) {
          if (agencyInfo.newInfo.legal.exemptBw === '1') {
            this.exemptBw = this.translate.instant('common.Yes');
          } else if (agencyInfo.newInfo.legal.exemptBw === '2') {
            this.exemptBw = this.translate.instant('common.No');
          }
        } else {
          this.exemptBw = null;
        }
        this.tin = agencyInfo.newInfo.legal.primaryTax;
        this.label = agencyInfo.newInfo.legal.primaryTaxLabel;

        const statusW9map = Constants.STATUSW9;
        this.businessType = agencyInfo.newInfo.legal.businessType ?
          statusW9map.find(item => item.code === agencyInfo.newInfo.legal.businessType).display.valueOf() : null;

        this.dateFrom = agencyInfo.newInfo.legal.primaryTaxFrom;

        this.taxHist = agencyInfo.newInfo.taxHist;
        if (this.taxHist) {
          this.showPagination = this.taxHist.length > this.pageSize;
          this.activePage = 1;
          this.onChangePage(0);
        }
      }
    });
  }

  public onChangePage(increment: number): void {

    this.activePage = this.activePage + increment;

    this.isFirstPage = this.activePage === 1;
    this.isLastPage = this.activePage >= (this.taxHist.length / this.pageSize);

    this.tinHistFrom = (this.activePage - 1) * this.pageSize;
    this.tinHistTo = this.activePage * this.pageSize;

    if (this.tinHistTo > this.taxHist.length) {
      this.tinHistTo = this.taxHist.length;
    }

  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

}
