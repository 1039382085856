<div class="marquee">

  <div *ngIf="isPwdMsgReset">
    <i class="far fa-unlock marquee-icon"></i>
    <h1 class="marquee-heading">{{'pwdMessages.pwdResetHeader' | translate}}</h1>
    <h2 class="marquee-message">
      <p>{{'pwdMessages.pwdResetMsg' | translate}}</p>
      <p [innerHTML]="'pwdMessages.pwdResetLink' | translate: {url: usermail, urlLink: usermail}"></p>
    </h2>
  </div>

  <div *ngIf="isPwdMsgUpdated">
    <i class="far fa-unlock marquee-icon"></i>
    <h1 class="marquee-heading">{{'pwdMessages.pwdUpdatedHeader' | translate}}</h1>
    <h2 class="marquee-message">
      <p [innerHTML]="'pwdMessages.pwdUpdatedLink' | translate: {url: usermail, urlLink: usermail}"></p>
    </h2>
  </div>

  <div *ngIf="isPwdMsgInvalidToken">
    <i class="far fa-stopwatch marquee-icon"></i>
    <h1 class="marquee-heading">{{'pwdMessages.pwdInvalidTokenHeader' | translate}}</h1>
    <h2 class="marquee-message">
      <p
        [innerHTML]="'pwdMessages.pwdInvalidTokenLink' | translate | replace: {urlResetPwd: '#/auth/pwd-forgot' , urlHome: '#/', urlHomeLink: urlProfilePortal }">
      </p>
    </h2>
  </div>

  <div *ngIf="isPwdMsgExpiredToken">
    <i class="far fa-stopwatch marquee-icon"></i>
    <h1 class="marquee-heading">{{'pwdMessages.pwdExpiredTokenHeader' | translate}}</h1>
    <h2 class="marquee-message">
      <p
        [innerHTML]="'pwdMessages.pwdExpiredTokenLink' | translate | replace: {url:  '#/auth/pwd-forgot?email='+usermail}">
      </p>
    </h2>
  </div>

  <div *ngIf="isNewRequestAccess">
    <i class="far fa-user marquee-icon"></i>
    <h1 class="marquee-heading">{{'pwdMessages.newAccessRequestHeader' | translate}}</h1>
    <h2 class="marquee-message">
      <p>{{'pwdMessages.newAccessRequestMsg' | translate}}</p>
      <p [innerHTML]="'pwdMessages.newAccessRequestMsg2' | translate | replace: {userEmail: usermail, userLink: usermail}"></p>
    </h2>
  </div>

  <div *ngIf="isProfileDenied" class="marquee marquee-danger">
    <i class="far fa-lock-alt marquee-icon"></i>
    <h1 class="marquee-heading">{{'pwdMessages.accessDeniedHeader' | translate}}</h1>
    <h2 class="marquee-message">
      <p>{{'pwdMessages.accessProfileDeniedMsg' | translate}}</p>
    </h2>
  </div>

  <div *ngIf="isAccessDenied" class="marquee marquee-danger">
    <i class="far fa-lock-alt marquee-icon"></i>
    <h1 class="marquee-heading">{{'pwdMessages.accessDeniedHeader' | translate}}</h1>
    <h2 class="marquee-message">
      <p>{{'pwdMessages.accessDeniedMsg' | translate}}</p>
    </h2>
  </div>

  <div *ngIf="isEmailConfirmed">
    <i class="far fa-check marquee-icon"></i>
    <h1 class="marquee-heading">{{'pwdMessages.emailRegistrationConfirmedHeader' | translate}}</h1>
    <h2 class="marquee-message">
      <p
        [innerHTML]="'pwdMessages.emailMsgConfirmed' | translate | replace: {eMailToConfirm: ''+usermail}">
      </p>
    </h2>
  </div>  

  <div *ngIf="isEmailRejected">
    <i class="far fa-stopwatch marquee-icon"></i>  
    <h1 class="marquee-heading">{{'pwdMessages.emailRegistrationRejectedHeader' | translate}}</h1>
    <h2 class="marquee-message">
      <p [innerHTML]="'pwdMessages.emailMsgRejected' | translate | replace: {eMailToConfirm: ''+usermail}"></p>
      <!-- <p [innerHTML]="'pwdMessages.emailMsgRejectedRedirectLogin' | translate | replace: {urlHome: profilePortalHome, urlHomeLink: urlProfilePortal }"></p> -->
      <p [innerHTML]="'pwdMessages.emailMsgRejectedRedirectLoginNoLink' | translate | replace: {urlHome: profilePortalHome, urlHomeLink: urlProfilePortal }"></p>
    </h2>
  </div>  

  <div *ngIf="isEmailError" class="marquee marquee-danger">
    <i class="far fa-exclamation-triangle marquee-icon"></i>
    <h1 class="marquee-heading">{{'pwdMessages.emailMsgErrorHeader' | translate}}</h1>
    <h2 class="marquee-message">
      <p [innerHTML]="'pwdMessages.emailMsgError' | translate | replace: {emailContactSupport: emailContactSupport}"></p>
    </h2>
  </div>  

  <div>
    <button *ngIf="isUserLoggedIn" class="btn btn-primary marquee-primary-action"
      (click)="goLogIn()">{{'pwdMessages.returnProfile' |translate}}</button>
    <button *ngIf="!isUserLoggedIn" class="btn btn-primary marquee-primary-action"
      (click)="goLogIn()">{{'pwdMessages.returnLogIn' |translate}}</button>
  </div>
</div>