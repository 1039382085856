import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'ta-sorting-dropdown',
  templateUrl: './sorting-dropdown.component.html',
})
export class SortingDropdownComponent implements OnInit, OnChanges, OnDestroy {

  private readonly destroy$ = new Subject();

  @Input() columns: { name: string; property: string; }[];
  @Input() list: any[];
  @Input() lastParams: { sortBy: string; ascending: boolean; };
  @Output() listSorted: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() lastParamsEmit: EventEmitter<{ sortBy: string; ascending: boolean; }> =
    new EventEmitter<{ sortBy: string; ascending: boolean; }>();

  sortingForm: FormGroup;

  originalList: any[];
  sortedList: any[];

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.initForm();

    this.ascending.valueChanges
      .pipe(takeUntil(this.destroy$), filter(() => !this.sortBy.value))
      .subscribe(value => {
        if (this.sortedList) {
          this.sortedList.reverse();
          this.listSorted.emit(this.sortedList);
        }
      });
  }

  private initForm() {
    this.sortingForm = this.fb.group({
      sortBy: [null],
      ascending: [true],
    });
  }

  private patchFormValues(params: { sortBy: string; ascending: boolean; }) {
    this.sortingForm.patchValue({
      sortBy: params.sortBy,
      ascending: params.ascending,
    });
  }

  ngOnChanges({ list, lastParams }: SimpleChanges): void {
    if (list && list.currentValue) {
      if (list.firstChange) {
        this.initForm();
      }
      this.originalList = this.list;
    }

    if ( this.sortingForm && lastParams && lastParams.currentValue) {
      this.patchFormValues(this.lastParams);
    }

    this.onSortByChange();
  }

  ngOnDestroy(): void {
    this.lastParamsEmit.emit({ sortBy: this.sortBy.value, ascending: this.ascending.value });
    this.destroy$.next();
    this.destroy$.complete();
  }

  get sortBy() { return this.sortingForm.get('sortBy'); }
  get ascending() { return this.sortingForm.get('ascending'); }

  onSortByChange() {
    if (this.originalList) {
      this.sortedList = this.originalList;
      const property = this.sortBy.value;

      if (property) {
        this.sortedList = [...this.list].sort((a, b) => {
          if (!a[property]) { return 1; }
          if (!b[property]) { return -1; }
          return typeof a[property] === 'number' ? a[property] - b[property] : a[property].trim().localeCompare(b[property].trim());
        });
        if (!this.ascending.value) {
          this.sortedList.reverse();
        }
      }

      this.listSorted.emit(this.sortedList);
    }
  }

}
