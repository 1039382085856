<div *ngIf="tin">
  <label>{{label}}</label>
  <p>{{tin}}
    <span class="text-caption">
      <a data-toggle="modal" href="#"
        (click)="openModal(tinModal)">{{'taLegalDetailsCard.tinHist' | translate}}</a>
    </span>
  </p>
</div>

<div *ngIf="exemptBw">
  <label>{{'taLegalDetailsCard.exempt' | translate}}</label>
  <p>{{exemptBw }}</p>
</div>

<div *ngIf="businessType">
  <label>{{'taLegalDetailsCard.status' | translate}}</label>
  <p>{{businessType}}</p>
  <span class="text-caption">{{dateFrom | userDate}}</span>
</div>

<!-- Modal -->

<ng-template #tinModal>
  <div class="modal-header">
    <h5 class="modal-title" id="tinModalLabel">{{'taLegalDetailsCard.tinHistHeader' | translate}}</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <table class="table mb-0">
      <thead>
        <tr>
          <th class="text-nowrap">{{'taLegalDetailsCard.tin' | translate}}
            <span class="text-caption">{{'taLegalDetailsCard.type' | translate}}</span>
          </th>
          <th>{{'taLegalDetailsCard.active' | translate}}</th>
        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let tinEl of taxHist | slice: tinHistFrom : tinHistTo ">
          <td>
            {{tinEl.vat}}
            <span class="text-caption">
              <span>{{tinEl.label}}</span>
            </span>
          </td>
          <td>{{tinEl.from_dt}} - {{tinEl.to_dt}}</td>
        </tr>

      </tbody>
    </table>
  </div>


  <ng-container *ngIf="showPagination">
    <div class="modal-footer">
      <span class="d-none d-md-inline mr-3"
        [innerHTML]="'common.pagination' | translate | replace: {from: tinHistFrom+1, to: tinHistTo, total: taxHist?.length }"></span>
      <div class="btn-group" role="group" aria-label="Data Row Page">
        <button type="button" class="btn btn-secondary" aria-label="Previous" (click)="onChangePage(-1)"
          [disabled]="isFirstPage">
          <i class="fas fa-caret-left"></i>
          <span class="d-none d-md-inline ml-2">{{'common.previousBtn' | translate}}</span>
        </button>
        <button type="button" class="btn btn-secondary" aria-label="Next" (click)="onChangePage(1)"
          [disabled]="isLastPage">
          <span class="d-none d-md-inline mr-2">{{'common.nextBtn' | translate}}</span>
          <i class="fas fa-caret-right"></i>
        </button>
      </div>
    </div>
  </ng-container>
</ng-template>