import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TitleService {

  constructor(
    private title: Title,
    private translateService: TranslateService
  ) { }

  public setPageTitle(pageLabel?: string, apr?: boolean): void {
    this.translateService.get('topnavbar.companyName').subscribe(() => {
      const companyTitle = this.translateService.instant('topnavbar.companyName');
      const applicationTitle = apr ?  this.translateService.instant('apr.tittlePage') : this.translateService.instant('topnavbar.title');
      let compoundPageTitle: string;

      if (pageLabel) {
        const pageTitle = this.translateService.instant(pageLabel);
        compoundPageTitle = `${pageTitle} | ${applicationTitle} | ${companyTitle}`;
      } else {
        compoundPageTitle = `${applicationTitle} | ${companyTitle}`;
      }

      this.title.setTitle(compoundPageTitle);
    });
  }

}
