import { Component, OnInit } from '@angular/core';
import { TitleService } from '@core/services/title.service';

@Component({
  selector: 'ta-settings',
  templateUrl: './settings.component.html',
})
export class SettingsComponent implements OnInit {

  constructor(
    private titleService: TitleService
  ) {}

  ngOnInit() {
    this.titleService.setPageTitle('tabs.settings');
  }
}
