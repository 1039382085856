<div class="card">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" novalidate>
    <div class="card-header">
      <h2 class="card-title" [innerHTML]="'login.headerLogin' | translate"></h2>
      <h4 class="card-subtitle">
        <a routerLink="/auth/access-request" [innerHTML]="'login.requestAccess' | translate"></a>
      </h4>      
    </div>
    <div class="card-body">

      <div class="form-group" [class.form-group-invalid]="isInvalid(email)">
        <label class="h4 font-weight-bold" [innerHTML]="'login.userId' | translate"></label>
        <input type="text" class="form-control" [class.is-invalid]="isInvalid(email)" formControlName="email" placeholder="{{'login.emailPlaceHolder' | translate}}" required taUpperCase>
        <div class="invalid-feedback">
          <span>{{'login.invalidEmail' | translate}}</span>
        </div>
      </div>

      <div class="form-group" [class.form-group-invalid]="isInvalid(password)">
        <label class="h4 font-weight-bold" [innerHTML]="'login.password' | translate"></label>
        <input type="password" class="form-control" [class.is-invalid]="isInvalid(password)" formControlName="password" placeholder="{{'login.passwordPlaceHolder' | translate}}"
          required>
        <div class="invalid-feedback">
          <span>{{'login.required' | translate}}</span>
        </div>
        <small class="form-text">
          <a routerLink="/auth/access-pwd" [innerHTML]="'login.forgotPassword' | translate"></a>
        </small>
      </div>

      <div class="form-group">
        <label class="h4 font-weight-bold" [innerHTML]="'login.languageSelect' | translate"></label>
        <select class="form-control" formControlName="language" (change)="onLanguageChange()">
          <option *ngFor="let language of languageOptions" [value]="language.code">
            {{language.display}}
          </option>
        </select>
      </div>

      <div class="form-group mb-5">
        <button class="btn btn-block btn-primary" type="submit" [disabled]="!loginForm.valid" [innerHTML]="'login.login' | translate"></button>
        <small class="form-text text-danger" *ngIf="loginError" [innerHTML]="loginError"></small>
      </div>

      <p class="text-caption mb-1">
        <a href="{{bankOfSpainUrl}}" target="_blank">{{'login.bankOfSpainEntity' | translate}}</a>
      </p>
      <ul class="text-caption list-inline list-inline-delimited">
        <li class="list-inline-item">
          <a href="{{legalNoticeUrl}}" target="_blank">{{'login.legalNotice' | translate}}</a>
        </li>
        <li class="list-inline-item">
          <a href="{{onyxPrivacyPolicyUrl}}" target="_blank">{{'login.onyxPrivacyPolicy' | translate}}</a>
        </li>
        <li class="list-inline-item">
          <a href="{{clientDefenseUrl}}" target="_blank">{{'login.clientDefense' | translate}}</a>
        </li>
      </ul>

      <small>Copyright &copy; {{currentYear}} Onyx CenterSource. All Rights Reserved.</small>
    </div>
  </form>
</div>