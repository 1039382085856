import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from '@app/app.constants';
import { AuthService } from '@app/auth/services/auth.service';
import { UserInfo } from '@core/models/user-info.model';
import { TaprofileUtilsService } from '@core/services/taprofile-utils.service';


@Component({
  selector: 'ta-page-recovery-pwd',
  templateUrl: './page-pwd-messages.component.html',
})
export class PagePwdMessagesComponent implements OnInit {

  username: string;
  usermail: string;
  userInfo: UserInfo;
  isUserLoggedIn: boolean;
  urlProfilePortal: string;
  paramVCC: string;
  profilePortalHome: string;
  emailContactSupport: string;

  isPwdMsgReset: boolean;
  isPwdMsgUpdated: boolean;
  isPwdMsgInvalidToken: boolean;
  isPwdMsgExpiredToken: boolean;
  isNewRequestAccess: boolean;
  isProfileDenied: boolean;
  isAccessDenied: boolean;
  isEmailConfirmed: boolean;
  isEmailRejected: boolean;
  isEmailError: boolean;

  constructor(
    private profileService: TaprofileUtilsService,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.urlProfilePortal = Constants.URL_PROFILE_PORTAL;
    this.emailContactSupport = Constants.MAIL_ONYX_CUSTOMER_SUPPORT;
    this.profilePortalHome = '#/';

    this.isPwdMsgReset = false;
    this.isPwdMsgUpdated = false;
    this.isPwdMsgInvalidToken = false;
    this.isPwdMsgExpiredToken = false;
    this.isNewRequestAccess = false;
    this.isProfileDenied = false;
    this.isAccessDenied = false;
    this.isEmailConfirmed = false;
    this.isEmailRejected = false;
    this.isEmailError = false;

    this.route.queryParams.subscribe(params => {

      this.usermail = params.username;

      switch (params.pwdMessageOrigin) {
        case 'PwdMessageReset': { this.isPwdMsgReset = true; break; }
        case 'PwdMessageUpdated': { this.isPwdMsgUpdated = true; break; }
        case 'PwdMsgInvalidToken': { this.isPwdMsgInvalidToken = true; break; }
        case 'PwdMsgExpiredToken': { this.isPwdMsgExpiredToken = true; break; }
        case 'NewRequestAccess': { this.isNewRequestAccess = true; break; }
        case 'AccessProfileDenied': { this.isProfileDenied = true; break; }
        case 'AccessDenied': { this.isAccessDenied = true; break; }
        case 'EmailConfirmationConfirmed': { this.isEmailConfirmed = true;  break; }
        case 'EmailConfirmationRejected': { this.isEmailRejected = true; break; }
        case 'EmailConfirmationAlreadyConfirmed': { this.isEmailConfirmed = true; break; }
        case 'EmailConfirmationError01': { this.isEmailError = true; break; }
        case 'EmailConfirmationError02': { this.isEmailError = true; break; }
      }

    });

    this.authService.isLoggedIn.subscribe(value => {
      this.isUserLoggedIn = value;
      if (value) {
        this.profileService.userInfo.subscribe(userInfo => {
          this.userInfo = userInfo;
          this.username = userInfo ? userInfo.email : '';
        });
      }
    });

  }

  goLogIn() {
    this.authService.setRegistrationPage(false);
    if (this.isUserLoggedIn) {
      this.redirectTo('/');
    } else {
      this.router.navigate(['auth/login']);
    }
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([uri]);
    });
  }
}

