<form [formGroup]="taxForm">

  <div class="form-group row">
    <label class="{{labelColumnCssClasses}} col-form-label">{{'legalCaTax.gstRegistered' | translate}}</label>
    <div class="{{fieldColumnCssClasses}}">
      <select class="form-control" formControlName="gstSelect" name="gstSelect" (change)="onGstRegisteredChange()">
        <option value="">{{'common.select' | translate}}</option>
        <option value="Y">{{'common.Yes' | translate}}</option>
        <option value="N">{{'common.No' | translate}}</option>
      </select>
      <div *ngIf="!isGstRegistered()">
        <ng-container *ngTemplateOutlet="pendingReviewHelpTex;context:{pendingField: 'gst'}"></ng-container>
      </div>
    </div>
  </div>

  <div class="form-group row" *ngIf="isGstRegistered()" [class.form-group-invalid]="!isValidGst">
    <label class="{{labelColumnCssClasses}} col-form-label">{{'legalCaTax.gstNumber' | translate}}</label>
    <div class="{{fieldColumnCssClasses}}">
      <input type="text" class="form-control" [class.is-invalid]="!isValidGst" formControlName="gst" name="gst"
        placeholder="{{'legalCaTax.gstPlaceholder' | translate}}" />
      <div *ngIf="!isValidGst" class="invalid-feedback d-block">
        <span>{{'legalCaTax.invalidGst' | translate}}</span>
      </div>
      <ng-container *ngTemplateOutlet="pendingReviewHelpTex;context:{pendingField: 'gst'}"></ng-container>
    </div>
  </div>

  <div class="form-group row">
    <label class="{{labelColumnCssClasses}} col-form-label">{{'legalCaTax.qstRegistered' | translate}}</label>
    <div class="{{fieldColumnCssClasses}}">
      <select class="form-control" formControlName="qstSelect" name="qstSelect" (change)="onQstRegisteredChange()">
        <option value="">{{'common.select' | translate}}</option>
        <option value="Y">{{'common.Yes' | translate}}</option>
        <option value="N">{{'common.No' | translate}}</option>
      </select>
      <div *ngIf="!isQstRegistered()">
        <ng-container *ngTemplateOutlet="pendingReviewHelpTex;context:{pendingField: 'gst'}"></ng-container>
      </div>
    </div>
  </div>

  <div class="form-group row" *ngIf="isQstRegistered()" [class.form-group-invalid]="!isValidQst">
    <label class="{{labelColumnCssClasses}} col-form-label">{{'legalCaTax.qstNumber' | translate}}</label>
    <div class="{{fieldColumnCssClasses}}">
      <div class="row form-control-segments">
        <div class="col-6">
          <input type="text" class="form-control" [class.is-invalid]="!isValidQst" formControlName="qst1" name="qst1"
            placeholder="{{'legalCaTax.qstPlaceholder1' | translate}}" />
          <div *ngIf="!isValidQst" class="invalid-feedback d-block">
            <span>{{'legalCaTax.invalidQst' | translate}}</span>
          </div>
        </div>
        <div class="col-2">
          <input type="text" class="form-control" [class.is-invalid]="!isValidQst" formControlName="qstTq"
            name="qstTq" />
        </div>
        <div class="col-4">
          <input type="text" class="form-control" [class.is-invalid]="!isValidQst" formControlName="qst2" name="qst2"
            placeholder="{{'legalCaTax.qstPlaceholder2' | translate}}" />
        </div>
      </div>
      <ng-container *ngTemplateOutlet="pendingReviewHelpTex;context:{pendingField: 'qst1'}"></ng-container>
    </div>
  </div>
</form>

<ng-template #pendingReviewHelpTex let-pendingField=pendingField>
  <small *ngIf="isPendingReview(pendingField)" class="form-text text-muted">
    {{'pendingChanges.profileTxt1' | translate}}
    <a href="#/profile-history" class="alert-link"
      (click)="close()">{{'pendingChanges.profileTxt' | translate}}</a>
    {{'pendingChanges.profileTxt2' | translate}}
  </small>
</ng-template>