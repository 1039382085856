import { Component, OnInit, ViewChild } from '@angular/core';
import { Address, Agency, AgencyInfo, Dropdown } from '@core/models';
import { TaprofileUtilsService } from '@core/services';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EMPTY, Observable } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { AddressFormComponent } from '../address-form/address-form.component';

@Component({
  selector: 'ta-address-modal',
  templateUrl: './address-modal.component.html',
})
export class AddressModalComponent implements OnInit {

  @ViewChild(AddressFormComponent, { static: true }) addressForm: AddressFormComponent;

  newInfo: Agency;
  address: Address;
  title: string;
  isCommercial: boolean;
  states$: Observable<Dropdown[]>;
  cities$: Observable<Dropdown[]>;
  pendingFieldsCard: string[];
  card: string;

  constructor(
    public bsModalRef: BsModalRef,
    private profileService: TaprofileUtilsService
  ) { }

  ngOnInit() {
  }

  save() {
    const newInfo = Object.assign({}, this.newInfo);
    const address = this.addressForm.addressForm.getRawValue();

    if (this.addressForm.addressForm.valid) {
      this.closeModal();
    }

    if (this.isCommercial) {
      newInfo.commercial = address;
    } else {
      newInfo.postal = address;
    }

    this.profileService.saveNewInfo(newInfo)
      .pipe(take(1),
        catchError(error => {
          const agencyInfo: AgencyInfo = error.errorObject;
          this.profileService.reloadAgencyInfo(agencyInfo);
          return EMPTY;
        })
      ).subscribe(response => {
        const agencyInfo: AgencyInfo = <AgencyInfo>response;
        this.profileService.reloadAgencyInfo(agencyInfo);
      });

  }

  closeModal() {
    if (!this.bsModalRef) {
      return;
    }

    this.bsModalRef.hide();
    this.bsModalRef = null;
  }

}
